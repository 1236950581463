import React, {
  Component,
} from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Dropdown,
  Button,
  Segment,
} from 'semantic-ui-react';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import isArray from 'lodash/isArray';
import isInteger from 'lodash/isInteger';
import moment from 'moment';
import 'react-dates/initialize';
import {
  DateRangePicker,
} from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import './style.scss';
import {
  prepareQuery,
  prepareInvoiceQuery,
} from '../../utils';
import PermissionWrapper, {
  roles,
} from '../../ui_parts/PermissionWrapper';

const propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  currencyList: PropTypes.instanceOf(Array).isRequired,
  payMethodsList: PropTypes.instanceOf(Array).isRequired,
  setReportFilters: PropTypes.func.isRequired,
  getAll: PropTypes.func.isRequired,
  filters: PropTypes.instanceOf(Object).isRequired,
  userList: PropTypes.instanceOf(Array).isRequired,
  projectsList: PropTypes.instanceOf(Array).isRequired,
  bankAccountList: PropTypes.instanceOf(Array).isRequired,
  customerList: PropTypes.instanceOf(Array).isRequired,
};

const defaultProps = {};

class FiltersPanel extends Component {
  constructor(props) {
    super(props);
    const filterDates = get(props, 'filters.dates', []);
    this.state = {
      startDate: filterDates[0] ? moment.unix(filterDates[0]) : null,
      endDate: filterDates[1] ? moment.unix(filterDates[1]) : null,
      focusedInput: null,
      badges: {
        customer: [],
        project: [],
        bankAcc: [],
        payMethods: [],
        user: [],
        currency: [],
      },
    };

    this.fetchData = debounce((fn, param) => fn(param), 500, {
      leading: true,
    });
  }

  setDayFilters = (filters) => {
    this.setState(filters, () => {
      if (filters.startDate && filters.endDate) {
        const startDate = filters.startDate.startOf('day').unix();
        const endDate = filters.endDate.endOf('day').unix();
        this.handleFiltersChange('dates', [startDate, endDate]);
      } else {
        this.handleFiltersChange('dates', false);
      }
    });
  };

  handleFiltersChange = (name, value, data) => {
    const {
      badges,
    } = this.state;
    const {
      filters,
      setReportFilters,
    } = this.props;
    const newFilters = {
      ...filters,
    };
    if ((isArray(value) && value.length) || isInteger(value)) {
      newFilters[name] = value;
    } else {
      delete newFilters[name];
    }
    setReportFilters(newFilters);
    this.fetchList(newFilters);
    const filteringFields = ['customer', 'project', 'bankAcc', 'payMethods', 'user', 'currency'];
    if (filteringFields.includes(name)) {
      const arrayOfBadges = data.options.filter(item => data.value.indexOf(item.value) !== -1);
      const cloneBadges = {
        ...badges,
      };
      cloneBadges[name] = arrayOfBadges;
      this.setState({
        badges: cloneBadges,
      });
    }
  };

  fetchList = (filters = {}) => {
    const {
      getAll,
    } = this.props;
    getAll(prepareInvoiceQuery(filters));
  };

  handleSearchChange = (fnName, value = '') => {
    const params = prepareQuery({
      search: value,
      limit: 999,
    });
    //eslint-disable-next-line react/destructuring-assignment
    this.fetchData(this.props[fnName], params);
  };

  render() {
    const {
      currencyList,
      payMethodsList,
      filters,
      isFetching,
      userList,
      bankAccountList,
      projectsList,
      customerList,
      isMobile,
    } = this.props;
    const {
      startDate,
      endDate,
      focusedInput,
      badges,
    } = this.state;
    const CalendarArrow = startDate && endDate ? (
      <Button
        onClick={() => this.setDayFilters({
          startDate: null,
          endDate: null,
        })}
        circular
        basic
        icon='eraser'
        color='orange'
      />
    ) : <div />;
    return (
      <Grid.Row className={`filters-panel${isMobile ? ' mobile' : ''}`}>
        <Segment className='filters-panel-wrapper'>
          <Grid.Column className='first-column' width={5}>
            <Grid.Row>
              <Dropdown
                fluid
                selection
                multiple
                options={customerList.map(o => ({
                  key: o.id,
                  value: o.id,
                  text: o.name,
                })).concat(badges['customer'])}
                value={filters.customer || []}
                placeholder='Customer'
                onChange={(e, data) => this.handleFiltersChange('customer', data.value, data)}
                disabled={isFetching}
                loading={isFetching}
                search
                closeOnChange
                onOpen={() => this.handleSearchChange('getAllCustomers')}
                onSearchChange={(e, data) => this.handleSearchChange('getAllCustomers', data.searchQuery)}
              />
            </Grid.Row>
            <Grid.Row>
              <Dropdown
                fluid
                selection
                multiple
                options={projectsList.map(o => ({
                  key: o.id,
                  value: o.id,
                  text: o.name,
                })).concat(badges['project'])}
                value={filters.project || []}
                placeholder='Project'
                onChange={(e, data) => this.handleFiltersChange('project', data.value, data)}
                disabled={isFetching}
                loading={isFetching}
                search
                closeOnChange
                onOpen={() => this.handleSearchChange('getAllProjects')}
                onSearchChange={(e, data) => this.handleSearchChange('getAllProjects', data.searchQuery)}
              />
            </Grid.Row>
          </Grid.Column>
          <Grid.Column className='second-column' width={6}>
            <Grid.Row className='date-picker-wrapper'>
              <DateRangePicker
                customArrowIcon={CalendarArrow}
                isOutsideRange={() => false}
                startDate={startDate} // momentPropTypes.momentObj or null,
                startDateId='your_unique_start_date_id' // PropTypes.string.isRequired,
                endDate={endDate} // momentPropTypes.momentObj or null,
                endDateId='your_unique_end_date_id' // PropTypes.string.isRequired,
                onDatesChange={({
                                  startDate, endDate,
                                }) => this.setDayFilters({
                  startDate,
                  endDate,
                })} // PropTypes.func.isRequired,
                focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={focusedInput => this.setState({
                  focusedInput,
                })} // PropTypes.func.isRequired,
              />
            </Grid.Row>
          </Grid.Column>
          <Grid.Column className='third-column' width={5}>
            <Grid.Row>
              <Dropdown
                fluid
                selection
                multiple
                options={bankAccountList.map(o => ({
                  key: o.id,
                  value: o.id,
                  text: o.account,
                })).concat(badges['bankAcc'])}
                value={filters.bankAcc || []}
                placeholder='Bank account'
                onChange={(e, data) => this.handleFiltersChange('bankAcc', data.value, data)}
                disabled={isFetching}
                loading={isFetching}
                search
                closeOnChange
                onOpen={() => this.handleSearchChange('getAllBankAcc')}
                onSearchChange={(e, data) => this.handleSearchChange('getAllBankAcc', data.searchQuery)}
              />
            </Grid.Row>
            <Grid.Row className='inline-filters'>
              <Dropdown
                fluid
                selection
                multiple
                search
                closeOnChange
                options={payMethodsList.map(o => ({
                  key: o.id,
                  value: o.id,
                  text: o.name,
                })).concat(badges['payMethods'])}
                value={filters.payMethods || []}
                placeholder='Payment method'
                onChange={(e, data) => this.handleFiltersChange('payMethods', data.value, data)}
                disabled={isFetching}
                loading={isFetching}
                onOpen={() => this.handleSearchChange('getAllPayMethods')}
                onSearchChange={(e, data) => this.handleSearchChange('getAllPayMethods', data.searchQuery)}
              />
            </Grid.Row>
          </Grid.Column>
          <Grid.Column className='fourth-column' width={5}>
            <PermissionWrapper
              availableRoles={[
                roles.ROOT,
                roles.DIRECTOR,
                roles.ACCOUNTANT,
              ]}
            >
              <Grid.Row>
                <Dropdown
                  fluid
                  selection
                  multiple
                  options={userList.map(o => ({
                    key: o.id,
                    value: o.id,
                    text: `${o.username} (${o.first_name} ${o.last_name})`,
                  })).concat(badges['user'])}
                  value={filters.user || []}
                  placeholder='User'
                  onChange={(e, data) => this.handleFiltersChange('user', data.value, data)}
                  disabled={isFetching}
                  loading={isFetching}
                  search
                  closeOnChange
                  onOpen={() => this.handleSearchChange('getAllUsers')}
                  onSearchChange={(e, data) => this.handleSearchChange('getAllUsers', data.searchQuery)}
                />
              </Grid.Row>
            </PermissionWrapper>
            <Grid.Row className='inline-filters'>
              <Dropdown
                fluid
                selection
                multiple
                search
                closeOnChange
                options={currencyList.map(o => ({
                  key: o.id,
                  value: o.id,
                  text: o.name,
                })).concat(badges['currency'])}
                value={filters.currency || []}
                placeholder='Currency'
                onChange={(e, data) => this.handleFiltersChange('currency', data.value, data)}
                disabled={isFetching}
                loading={isFetching}
                onOpen={() => this.handleSearchChange('getAllCurrency')}
                onSearchChange={(e, data) => this.handleSearchChange('getAllCurrency', data.searchQuery)}
              />
            </Grid.Row>
          </Grid.Column>
        </Segment>
      </Grid.Row>
    );
  }
}

FiltersPanel.propTypes = propTypes;
FiltersPanel.defaultProps = defaultProps;
export default FiltersPanel;
