import {
 connect, 
} from 'react-redux';
import Currency from './Currency';
import {
 key, 
} from '../module/constants';
import {
  getAllCurrency as getAll,
  createCurrency as create,
  editCurrency as edit,
  removeCurrency as remove,
} from '../module/actions';

const mapStateToProps = (state) => ({
  list: state[key].currency,
  count: state[key].currencyCount,
  isFetching: state[key].isFetching,
  isMobile: state.app.isMobile,
});

const mapDispatchToProps = {
  getAll,
  create,
  edit,
  remove,
};

export default connect(mapStateToProps, mapDispatchToProps)(Currency);
