import React, {
  Component,
} from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Grid,
} from 'semantic-ui-react';
import {
  Route,
  Switch,
} from 'react-router-dom';
import './style.scss';
import FiltersPanel from './filtersPanel';
import ListPanel from './listPanel';
import Bill from './bill';
import {
  initFilters,
} from './module/reducers';

const propTypes = {
  isMobile: PropTypes.bool.isRequired,
  setInvoiceFilters: PropTypes.func.isRequired,
};

class Main extends Component {
  componentWillUnmount() {
    const {
      setInvoiceFilters,
    } = this.props;
    setInvoiceFilters(initFilters);
  }

  prepareMainContainer = () => {
    const {
      isMobile,
    } = this.props;
    return (
      <Container className={`main${isMobile ? ' mobile' : ''}`}>
        <Grid divided='vertically' padded>
          <FiltersPanel />
          <ListPanel />
        </Grid>
      </Container>
    );
  };

  render() {
    return (
      <Switch>
        <Route
          exact
          path='/main'
          component={this.prepareMainContainer}
        />
        <Route
          path='/main/:id'
          component={Bill}
        />
      </Switch>
    );
  }
}

Main.propTypes = propTypes;
export default Main;
