import {
 connect, 
} from 'react-redux';
import PaymentMethod from './PaymentMethod';
import {
 key, 
} from '../module/constants';
import {
  getAllPayMethods as getAll,
  createPayMethod as create,
  editPayMethod as edit,
  removePayMethod as remove,
} from '../module/actions';

const mapStateToProps = (state) => ({
  list: state[key].payMethods,
  count: state[key].payMethodsCount,
  isFetching: state[key].isFetching,
  isMobile: state.app.isMobile,
});

const mapDispatchToProps = {
  getAll,
  create,
  edit,
  remove,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethod);
