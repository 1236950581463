import React, {
  Component,
} from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Grid,
} from 'semantic-ui-react';
import {
Route, Switch,
} from "react-router-dom";
import './style.scss';
import FiltersPanel from './filtersPanel';
import ListPanel from './listPanel';
import TotalPanel from './totalPanel';
import Bill from '../main/bill';
import {
  initFilters,
} from './module/reducers';

const propTypes = {
  isMobile: PropTypes.bool.isRequired,
  setReportFilters: PropTypes.func.isRequired,
};

class Reports extends Component {
  componentWillUnmount() {
    const {
      setReportFilters,
    } = this.props;
    setReportFilters(initFilters);
  }
  render() {
    const {
      isMobile,
    } = this.props;
    const ReportsContainer = (
      <Container className={`main${isMobile ? ' mobile' : ''}`}>
        <Grid divided='vertically' padded>
          <FiltersPanel />
          <TotalPanel />
          <ListPanel />
        </Grid>
      </Container>
    );
    return (
      <Switch>
        <Route
          exact
          path='/reports'
          component={() => ReportsContainer}
        />
        <Route
          path='/reports/:id'
          component={Bill}
        />
      </Switch>
    );
  }
}

Reports.propTypes = propTypes;
export default Reports;
