import PropTypes from 'prop-types';
import {
  connect,
} from 'react-redux';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import map from 'lodash/map';
import {
  withRouter,
} from 'react-router';
import {
  key,
} from '../profile/module/constants';
import {
  store,
} from '../Root';

export const roles = {
  ROOT: 'root',
  MANAGER: 'manager',
  DIRECTOR: 'director',
  ACCOUNTANT: 'accountant',
};

export const allRoles = map(roles);

export const permissionBool = (availableRoles = []) => {
  const userRole = get(store.getState()[key], 'account.user_role', '');
  return !(!isArray(availableRoles) || !availableRoles.includes(userRole));
};

const propTypes = {
  userRole: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  availableRoles: PropTypes.instanceOf(Array),
  rescrictedRoutes: PropTypes.instanceOf(Array),
};

const defaultProps = {
  availableRoles: [],
  rescrictedRoutes: [],
};

const PermissionWrapper = ({
                             children,
                             userRole,
                             history: {
    location: {
      //eslint-disable-next-line no-unused-vars
      pathname,
    },
  }, availableRoles = [],
                           }) => {
  if (!isArray(availableRoles) || !availableRoles.includes(userRole)) {
    return null;
  }
  return children;
};

PermissionWrapper.propTypes = propTypes;
PermissionWrapper.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  userRole: get(state[key], 'account.user_role', ''),
});

export default connect(mapStateToProps)(withRouter(PermissionWrapper));
