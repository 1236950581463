import React, {
  Component,
} from 'react';
import {
  Button, Form, Modal, Message,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {
  debounce,
} from 'lodash';
import {
  modalStyles,
} from '../../ui_parts/Confirm';
import './style.scss';
import {
  addDataForDropDowns,
  getDataForDropDowns,
  isFormValid,
  prepareQuery,
} from '../../utils';

const propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  item: PropTypes.instanceOf(Object).isRequired,

  getAllCurrency: PropTypes.func.isRequired,
  currencylist: PropTypes.instanceOf(Array).isRequired,
  getAllPayMethods: PropTypes.func.isRequired,
  payMethodslist: PropTypes.instanceOf(Array).isRequired,
  isMobile: PropTypes.bool.isRequired,
};

class BankAccModal extends Component {
  constructor() {
    super();
    this.state = {
      editableItem: {},
      errors: [],
    };
    this.fetchData = debounce((fn, param) => fn(param), 500, {
      leading: true,
    });
  }

  componentDidUpdate(prevProps) {
    const {
      isModalOpen,
      getAllCurrency,
      getAllPayMethods,
    } = this.props;
    if (!prevProps.isModalOpen && isModalOpen) {
      getAllCurrency();
      getAllPayMethods();
    }
  }

  handleSearchChange = (fnName, value = '') => {
    const params = prepareQuery({
      search: value,
    });
    // eslint-disable-next-line react/destructuring-assignment
    this.fetchData(this.props[fnName], params);
  };

  handleChange = (name, val) => {
    const {
      editableItem,
    } = this.state;
    editableItem[name] = val;
    this.setState({
      editableItem: editableItem,
      errors: [],
    });
  };

  submit = () => {
    const {
      handleSubmit,
      item,
    } = this.props;
    const {
      editableItem,
      errors,
    } = this.state;
    const newItem = {
      ...item,
      ...editableItem,
    };
    const fieldsForValidation = [
      'currency',
      'payment_method',
    ];
    const requiredFieldErrors = isFormValid(newItem, fieldsForValidation);
    if (requiredFieldErrors.length) {
      return this.setState({
        errors: [
          ...errors,
          requiredFieldErrors,
        ],
      });
    }
    if (typeof newItem.currency === 'object') {
      newItem.currency = newItem.currency.id;
    }
    if (typeof newItem.payment_method === 'object') {
      newItem.payment_method = newItem.payment_method.id;
    }
    handleSubmit(newItem);
  };

  close = () => {
    const {
      handleClose,
    } = this.props;
    handleClose();
    this.setState({
      editableItem: {},
    });
  };

  prepareErrorMessage = () => {
    const {
      errors,
    } = this.state;
    let errorMessage;
    if (errors.length) {
      errorMessage = (
        <Message
          error
          content='Please, fill all required fields'
        />
      );
    }
    return errorMessage;
  };

  render() {
    const {
      isModalOpen,
      item,
      isFetching,
      currencylist,
      payMethodslist,
      isMobile,
    } = this.props;
    const {
      editableItem,
    } = this.state;
    const mergedItem = {
      ...item,
      ...editableItem,
    };
    return (
      <Modal
        dimmer='blurring'
        closeOnDimmerClick={false}
        open={isModalOpen}
        style={modalStyles}
        size='small'
        className={`management-modal${isMobile ? ' mobile' : ''}`}
      >
        <Modal.Header>{mergedItem.id ? `${item.account}` : 'Create new Bank account'}</Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            <Form
              loading={isFetching}
              onSubmit={this.submit}
              id='the-bank-acc-form'
            >
              <Form.Input
                fluid
                label='Bank account'
                autoFocus
                required
                defaultValue={mergedItem.account}
                // pattern={INPUT_VALIDATION_PATTERN}
                placeholder='Bank account'
                onChange={(e, val) => this.handleChange('account', val.value)}
              />
              <Form.Select
                fluid
                required
                search
                label='Currency'
                onChange={(e, val) => this.handleChange('currency', val.value)}
                options={addDataForDropDowns(currencylist, mergedItem.currency)}
                placeholder='Select a currency'
                onSearchChange={(e, data) => this.handleSearchChange('getAllCurrency', data.searchQuery)}
                value={getDataForDropDowns(mergedItem.currency)}
                disabled={isFetching}
              />
              <Form.Select
                fluid
                required
                search
                label='Payment method'
                onChange={(e, val) => this.handleChange('payment_method', val.value)}
                options={addDataForDropDowns(payMethodslist, mergedItem.payment_method)}
                placeholder='Select a payment method'
                onSearchChange={(e, data) => this.handleSearchChange('getAllPayMethods', data.searchQuery)}
                value={getDataForDropDowns(mergedItem.payment_method)}
                disabled={isFetching}
              />
              <Form.TextArea
                label='Details'
                autoHeight
                required
                defaultValue={mergedItem.description}
                placeholder='Details'
                onChange={(e, val) => this.handleChange('description', val.value)}
              />
            </Form>
            {this.prepareErrorMessage()}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color='black'
            onClick={this.close}
            disabled={isFetching}
            content='Cancel'
            size={isMobile ? 'massive' : 'medium'}
          />
          <Button
            color='orange'
            inverted
            icon='checkmark'
            labelPosition='right'
            disabled={isFetching}
            type='submit'
            form='the-bank-acc-form'
            content={mergedItem.id ? 'Save' : 'Create'}
            size={isMobile ? 'massive' : 'medium'}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

BankAccModal.propTypes = propTypes;
export default BankAccModal;
