import constants from './constants';
import Http from '../../utils';

const getProfile = id => dispatch => {
  dispatch({
    type: constants.GET_PROFILE_REQUEST,
  });
  Http.get(`/user/${id}/`)
  .then(
    res => {
      dispatch({
        type: constants.GET_PROFILE_SUCCESS,
        payload: res,
      });
    },
    err => dispatch({
      type: constants.GET_PROFILE_FAILURE,
      payload: err,
    })
  );
};

export default getProfile;
