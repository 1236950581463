import React, {
  Component,
} from 'react';
import {
  Header,
  Icon,
  Grid,
  Container,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import PermissionWrapper, {
  roles,
} from '../ui_parts/PermissionWrapper';
import './style.scss';
import ManagementRoutes from './ManagementRoutes';
import AddItemButton from '../ui_parts/AddItemButton';

const getColums = width => {
  if (width < 440) return 3;
  if (width < 550) return 4;
  if (width < 650) return 5;
  if (width < 750) return 6;
  return 7;
};

const propTypes = {
  width: PropTypes.number.isRequired,
  isMobile: PropTypes.bool.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object),
};

class Management extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleClick = (url) => {
    const {
      history: {
        push,
      },
    } = this.props;
    push(url);
  };

  render() {
    const {
      location: {
        pathname,
      }, history: {
        push,
      },
      width,
      isMobile,
    } = this.props;
    return (
      <div className={`management${isMobile ? ' mobile' : ''}${pathname === '/management' ? ' root-menu' : ''}`}>
        <Container text>
          <Grid relaxed='very' columns={getColums(width)}>
            <PermissionWrapper
              availableRoles={[
                roles.ROOT,
                roles.DIRECTOR,
                roles.ACCOUNTANT,
              ]}
            >
              <Grid.Column onClick={() => this.handleClick('/management/users')}>
                <Header
                  as='h5'
                  icon
                  className={`isActive-${pathname === '/management/users'}`}
                >
                  <Icon name='users' />
                  Users
                </Header>
              </Grid.Column>
            </PermissionWrapper>
            <Grid.Column onClick={() => this.handleClick('/management/contractors')}>
              <Header
                as='h5'
                icon
                className={`isActive-${pathname === '/management/contractors'}`}
              >
                <Icon name='payment' />
                Contractors
              </Header>
            </Grid.Column>
            <Grid.Column onClick={() => this.handleClick('/management/customers')}>
              <Header
                as='h5'
                icon
                className={`isActive-${pathname === '/management/customers'}`}
              >
                <Icon name='address book' />
                Customers
              </Header>
            </Grid.Column>
            <Grid.Column onClick={() => this.handleClick('/management/projects')}>
              <Header
                as='h5'
                icon
                className={`isActive-${pathname === '/management/projects'}`}
              >
                <Icon name='object group' />
                Projects
              </Header>
            </Grid.Column>
            <Grid.Column onClick={() => this.handleClick('/management/accounts')}>
              <Header
                as='h5'
                icon
                className={`isActive-${pathname === '/management/accounts'}`}
              >
                <Icon name='dollar' />
                Bank accounts
              </Header>
            </Grid.Column>
            <Grid.Column onClick={() => this.handleClick('/management/currency')}>
              <Header
                as='h5'
                icon
                className={`isActive-${pathname === '/management/currency'}`}
              >
                <Icon name='money' />
                Currency
              </Header>
            </Grid.Column>
            <Grid.Column onClick={() => this.handleClick('/management/payments')}>
              <Header
                as='h5'
                icon
                className={`isActive-${pathname === '/management/payments'}`}
              >
                <Icon name='paypal card' />
                Payment methods
              </Header>
            </Grid.Column>
          </Grid>
        </Container>
        <div className='management-router-wrapper'>
          {isMobile ? (
            <div className='button-wrapper'>
              <AddItemButton
                onClick={() => push('/management')}
                icon='angle left'
                text='Back'
                inverted={false}
              />
            </div>
          ) : null
          }
          <ManagementRoutes />
        </div>
      </div>
    );
  }
}

Management.propTypes = propTypes;
Management.defaultProps = {
  location: {},
};
export default Management;
