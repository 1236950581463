import React, {
    Component,
} from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Form,
    Icon,
    Label,
    Segment,
} from 'semantic-ui-react';
import ServiceInfoComment from './ServiceInfoComment';


const propTypes = {
    serviceInfoItem: PropTypes.instanceOf(Object).isRequired,
    isMobile: PropTypes.bool.isRequired,
    currency: PropTypes.string.isRequired,
    descriptionLength: PropTypes.number.isRequired,
    onRemoveSingleService: PropTypes.func.isRequired,
    handleServicesChange: PropTypes.func.isRequired,
    handleAddServiceInfoComment: PropTypes.func.isRequired,
    handleRemoveServiceInfoComment: PropTypes.func.isRequired,
    handleCommentChange: PropTypes.func.isRequired,
};

const departmentItems = [
  'Web (backend)',
  'Web (frontend)',
  'iOS',
  'PM',
  'BA',
  'Android',
  'Design',
  'QA',
];



class ServiceInfo extends Component {
    constructor(props) {
    super(props);
    this.state = {
        isCommentsOpen: false,
    };
}

    toggleComments = () => {
        const {
            isCommentsOpen,
        } = this.state;

        this.setState({
            isCommentsOpen: !isCommentsOpen,
        });
    };


    render() {

        const {
            isCommentsOpen,
        } = this.state;

        const {
            serviceInfoItem,
            isMobile,
            currency,
            descriptionLength,
            onRemoveSingleService,
            handleServicesChange,
            handleAddServiceInfoComment,
            handleRemoveServiceInfoComment,
            handleCommentChange,
        } = this.props;

        return (
          <Segment
            raised
            className='service-info'
          >
            <div className='header'>
              <h3>Service info</h3>
              <Button
                type='button'
                icon
                inverted
                color='orange'
                onClick={() => onRemoveSingleService(serviceInfoItem.id)}
                disabled={descriptionLength < 2}
                size={isMobile ? 'massive' : 'tiny'}
              >
                <Icon name='trash' />
              </Button>
            </div>
            <Form.Input
              label='Description'
              autoFocus
              defaultValue={serviceInfoItem.description}
              placeholder='Description'
              onChange={(e, val) => handleServicesChange(serviceInfoItem.id, 'description', val.value)}
            />
            <Form.Group widths='equal'>
              <Form.Input
                label='Q-ty'
                placeholder='Q-ty'
                type='number'
                step={0.01}
                min={0}
                // eslint-disable-next-line no-useless-escape
                pattern={'\d*'}
                defaultValue={serviceInfoItem.qty}
                onChange={(e, val) => handleServicesChange(serviceInfoItem.id, 'qty', val.value)}
              />
              <Form.Input
                label='Price per hour'
                placeholder='Price per hour'
                type='number'
                step={0.01}
                min={0}
                // eslint-disable-next-line no-useless-escape
                pattern={'\d*'}
                defaultValue={serviceInfoItem.price_per_hour}
                onChange={(e, val) => handleServicesChange(serviceInfoItem.id, 'price_per_hour', val.value)}
              />
              <Form.Input
                required
                label='Amount'
                placeholder='Amount'
                labelPosition={`${currency ? 'right' : 'left'}`}
                type='number'
                step={0.01}
                // eslint-disable-next-line no-useless-escape
                pattern={'\d*'}
                value={serviceInfoItem.amount}
                onChange={(e, val) => handleServicesChange(serviceInfoItem.id, 'amount', val.value)}
              >
                <input />
                {currency ? <Label basic>{currency}</Label> : null}
              </Form.Input>
            </Form.Group>
            <Form.TextArea
              rows={1}
              label='Notes'
              placeholder='Notes'
              value={serviceInfoItem.note}
              onChange={(e, val) => handleServicesChange(serviceInfoItem.id, 'note', val.value)}
            />

            {(isCommentsOpen && serviceInfoItem.hidden_comment) && (
              serviceInfoItem.hidden_comment.map((comment) => (
                <ServiceInfoComment
                  key={comment.id}
                  department={comment}
                  isCommentsOpen={isCommentsOpen}
                  handleRemoveServiceInfoComment={handleRemoveServiceInfoComment}
                  serviceInfoItemId={serviceInfoItem.id}
                  departmentItems={departmentItems}
                  handleCommentChange={handleCommentChange}
                  commentsLength={serviceInfoItem.hidden_comment.length}
                  currency={currency}
                />
              ))
            )}

            <div className={isCommentsOpen ?
               'service-info__comments-buttons-space-between'
              :'service-info__comments-buttons-wrapper'}
            >
              {isCommentsOpen && (
              <Button
                type='button'
                color='orange'
                inverted
                icon='plus'
                content='Add department'
                size={isMobile ? 'massive' : 'medium'}
                onClick={() => handleAddServiceInfoComment(serviceInfoItem.id)}
              />
              )}


              <Button
                type='button'
                color='orange'
                inverted
                icon={isCommentsOpen ? 'angle up' : 'angle down'}
                content='More'
                size={isMobile ? 'massive' : 'medium'}
                onClick={this.toggleComments}
              />
            </div>
          </Segment>
        );
    }
}

ServiceInfo.propTypes = propTypes;

export default ServiceInfo;
