import {
 connect, 
} from 'react-redux';
import FiltersPanel from './FiltersPanel';

import {
 key as managementKey, 
} from '../../management/module/constants';
import {
 key, 
} from "../module/constants";
import {
 setInvoiceFilters, getInvoices as getAll, 
} from "../module/actions";
import {
  getAllPayMethods,
  getAllCurrency,
  getAllUsers,
  getAllProjects,
  getAllBankAcc,
  getAllCustomers,
} from '../../management/module/actions';

const mapStateToProps = (state) => ({
  isFetching: state[key].isFetching,
  filters: state[key].filters,
  currencyList: state[managementKey].currency,
  payMethodsList: state[managementKey].payMethods,
  userList: state[managementKey].users,
  projectsList: state[managementKey].projects,
  bankAccountList: state[managementKey].bankAccounts,
  customerList: state[managementKey].customers,
  isMobile: state.app.isMobile,
});

const mapDispatchToProps = {
  setInvoiceFilters,
  getAll,

  getAllPayMethods,
  getAllCurrency,
  getAllUsers,
  getAllProjects,
  getAllBankAcc,
  getAllCustomers,
};

export default connect(mapStateToProps, mapDispatchToProps)(FiltersPanel);
