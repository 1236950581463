import React, {
  Component,
} from 'react';
import {
  Table,
  Icon,
  Button,
  Container,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import CustomersModal from './Modal';
import './style.scss';
import {
  prepareQuery,
  preparePagination,
  setOffset,
} from '../../utils';
import Confirm from '../../ui_parts/Confirm';
import Pagination from '../../ui_parts/Pagination';
import AddItemButton from '../../ui_parts/AddItemButton';
import PermissionWrapper, {
  roles,
} from '../../ui_parts/PermissionWrapper';
import Search from '../../ui_parts/Search';

const propTypes = {
  list: PropTypes.instanceOf(Array).isRequired,
  count: PropTypes.number.isRequired,
  getAll: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  create: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

const defaultProps = {};

class Customers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order_by: 'name',
      order: 'desc',
      isModalOpen: false,
      isConfirmOpen: false,
      item: {},
      search: '',
      limit: 10,
      offset: 0,
    };
  }

  componentDidMount() {
    this.fetchList();
  }

  handleSearchChange = (e, {
    value,
  }) => {
    this.setState({
      search: value,
      offset: 0,
    }, () => this.fetchList());
  }

  handlePaginationChange = (e, {
    activePage,
  }) => {
    this.setState({
      offset: setOffset(activePage),
    }, () => this.fetchList());
  };

  handleToggleModal = (isModalOpen = false, item = {}) => {
    this.setState({
      item,
      isModalOpen,
    });
  };

  handleRemove = () => {
    const {
      getAll,
      remove,
    } = this.props;
    const {
      item,
    } = this.state;
    remove(item.id)
    .then(() => {
      getAll();
      this.handleToggleConfirm();
    });
  };

  handleToggleConfirm = (isConfirmOpen = false, item = {}) => {
    this.setState({
      item,
      isConfirmOpen,
    });
  };

  handleSubmit = (item) => {
    const {
      create,
      edit,
    } = this.props;
    const fn = item.id ? edit : create;
    fn(item, item.id)
    .then(() => {
      this.fetchList();
      this.handleToggleModal();
    });
  };

  fetchList = () => {
    const {
      getAll,
    } = this.props;
    return getAll(prepareQuery(this.state));
  }

  handleSort = (clickedColumn) => {
    const {
      order_by,
      order,
    } = this.state;
    if (order_by !== clickedColumn) {
      this.setState({
        order_by: clickedColumn,
        order: 'asc',
      }, () => this.fetchList());
      return;
    }
    const direction = order === 'asc' ? 'desc' : 'asc';
    this.setState({
      order: direction,
    }, () => this.fetchList());
  };

  render() {
    const {
      isFetching,
      list,
      count,
      isMobile,
    } = this.props;
    const {
      isModalOpen,
      item,
      isConfirmOpen,
      order_by,
      order,
      limit,
      offset,
    } = this.state;
    const direction = order === 'asc' ? 'ascending' : 'descending';
    const {
      totalPages, activePage,
    } = preparePagination(limit, offset, count);
    return (
      <div className={`customers${isMobile ? ' mobile' : ''}`}>
        <Container>
          <Table compact unstackable color='orange'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan='1'>
                  <AddItemButton
                    onClick={() => this.handleToggleModal(true)}
                    icon='address book'
                    text='Add new customer'
                  />
                </Table.HeaderCell>
                <Table.HeaderCell colSpan='2'>
                  <Search
                    isFetching={isFetching}
                    onSearchChange={this.handleSearchChange}
                  />
                </Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell
                  sorted={order_by === 'name' ? direction : null}
                  onClick={() => this.handleSort('name')}
                >
                  Customer Name
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={order_by === 'address' ? direction : null}
                  onClick={() => this.handleSort('address')}
                  className='limited-width'
                >
                  Customer Address
                </Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {list.map(val => (
                <Table.Row key={val.id}>
                  <Table.Cell data-label='Customer Name'>
                    {val.name}
                  </Table.Cell>
                  <Table.Cell data-label='Customer Address' className='limited-width'>
                    {val.address}
                  </Table.Cell>
                  <Table.Cell textAlign='right'>
                    <Button
                      icon
                      onClick={() => this.handleToggleModal(true, val)}
                    >
                      <Icon
                        name='edit'
                      />
                    </Button>
                    <PermissionWrapper
                      availableRoles={[
                        roles.ROOT,
                        roles.DIRECTOR,
                        roles.ACCOUNTANT,
                      ]}
                    >
                      <Button
                        icon
                        onClick={() => this.handleToggleConfirm(true, val)}
                      >
                        <Icon name='trash' />
                      </Button>
                    </PermissionWrapper>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            {totalPages > 1 && (
              <Table.Footer fullWidth>
                <Table.Row>
                  <Table.HeaderCell colSpan='2'>
                    <Pagination
                      handlePaginationChange={this.handlePaginationChange}
                      activePage={activePage}
                      totalPages={totalPages}
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            )}
          </Table>
        </Container>
        <Confirm
          open={isConfirmOpen}
          confirmButton='Delete'
          content='Are you sure you want to remove this customer?'
          onCancel={() => this.handleToggleConfirm()}
          onConfirm={this.handleRemove}
          disabled={isFetching}
        />
        <CustomersModal
          handleClose={() => this.handleToggleModal()}
          handleSubmit={this.handleSubmit}
          isModalOpen={isModalOpen}
          isFetching={isFetching}
          item={item}
          isMobile={isMobile}
        />
      </div>
    );
  }
}

Customers.propTypes = propTypes;
Customers.defaultProps = defaultProps;
export default Customers;
