import axios from 'axios';
import React from 'react';
import {
  bindActionCreators,
} from 'redux';
import jwtDecode from 'jwt-decode';
import queryString from 'query-string';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import isArray from 'lodash/isArray';
import isInteger from 'lodash/isInteger';
import isString from 'lodash/isString';
import isObject from 'lodash/isObject';
import has from 'lodash/has';
import differenceBy from 'lodash/differenceBy';
import {
  store,
} from './Root';
import {
  refreshToken,
  logOut,
  createNotification,
} from './app/module/actions';
import constants from './app/module/constants';


export const preparePagination = (limit = 1, offset = 0, count = 0) => ({
  totalPages: Math.ceil(count / limit),
  activePage: (offset / limit) + 1,
});

export const setOffset = (activePage, limit = 10) => (activePage - 1) * limit;

export const getDataForInvoice = (data = {}, list = []) => {
  if (isInteger(data)) {
    return list.find(el => el.id === data) || {};
  }
  return data;
};

/**
 * Retrieve an user who create / update invoice
 * @returns {string}
 */
export const getAuthorOfInvoice = (profile) => {
  let userName = '-';

  if (has(profile, 'updated_user') && (profile.updated_user !== null)) {
    userName = get(profile, 'updated_user.username');
  } else if (has(profile, 'created_user') && (profile.created_user !== null)) {
    userName = get(profile, 'created_user.username');
  }
  return userName;
};

export const isFormValid = (values = {}, validatedFields = []) => {
  const validationFieldItem = {
    name: '',
    message: 'is required',
    validationMethod: (item) => {
      let itemToCheck = item;
      if (typeof item === 'object') {
        itemToCheck = item.name;
      }
      if (typeof itemToCheck === 'string') {
        return !!itemToCheck.length;
      }
      return itemToCheck > 0;
    },
  };
  const arrayForValidation = validatedFields.map((field) => {
    if (typeof field === 'string') {
      return {
        ...validationFieldItem,
        name: field,
      };
    } else {
      return {
        ...validationFieldItem,
        ...field,
      };
    }
  });
  const fieldErrorsArray = arrayForValidation.reduce((prevField, currentField) => {
    if (!values[currentField.name]) {

      // prevField.push(`${currentField.name} ${currentField.message}`);
      prevField.push(`${currentField.name}`);
      return prevField;
    }
    if (!currentField.validationMethod(values[currentField.name])) {
      // prevField.push(`${currentField.name} ${currentField.message}`);
      prevField.push(`${currentField.name}`);
      return prevField;
    }
    return prevField;
  }, []);
  return fieldErrorsArray.length ? fieldErrorsArray : [];
};

export const getDataForDropDowns = data => (isInteger(data) ? data : get(data, 'id', null));

export const addDataForDropDowns = (list = [], data = 0, propName = 'name') => {
  const transformedList = list.map(el => ({
    id: el.id,
    text: isFunction(propName) ? propName(el) : el[propName],
    value: el.id,
  }));
  if (isInteger(data) || (isArray(data) && data.every(el => isInteger(el)))) {
    return transformedList;
  }
  let transformedData;
  if (isArray(data)) {
    transformedData = data.map(el => ({
      id: el.id,
      text: isFunction(propName) ? propName(el) : el[propName],
      value: el.id,
    }));
  } else if (isObject(data)) {
    transformedData = [{
      id: data.id,
      text: isFunction(propName) ? propName(data) : data[propName],
      value: data.id,
    }];
  } else {
    transformedData = [];
  }
  const absentElements = differenceBy(transformedData, list, 'id');
  return transformedList.concat(absentElements);
};

export const sortAlphabetical = (list) => {
  const sortedList = list.sort((a, b) => {
    const textA = a.name.toUpperCase();
    const textB = b.name.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });
  return sortedList;
};

export const getQuery = query => {
  const stringifyedQuery = queryString.stringify(query);
  if (stringifyedQuery) {
    return `?${stringifyedQuery}`;
  }
  return '';
};

export const convertObjectsToId = (item = {}, key = '') => {
  const newItem = {
    ...item,
  };
  if (isArray(key)) {
    key.forEach(e => {
      if (isArray(newItem[e])) {
        newItem[e] = newItem[e].map(el => (isObject(el) ? el.id : el));
      } else if (isObject(newItem[e])) {
        newItem[e] = newItem[e].id;
      }
    });
  }
  if (isString(key)) {
    if (isArray(newItem[key])) {
      newItem[key] = newItem[key].map(el => (isObject(el) ? el.id : el));
    } else if (isObject(newItem[key])) {
      newItem[key] = newItem[key].id;
    }
  }
  return newItem;
};

export const prepareMultiLineText = (text = '') =>
  (text && isString(text) ? text.split('\n').map((acc) => (
    <span
      key={`${acc}`}
    >
      {acc}
      <br />
    </span>
  )) : null);

export const prepareQuery = ({
                               order,
                               order_by,
                               search,
                               limit,
                               offset,
                               contractor,
                               customer,
                             }) => {
  const query = {};
  if (order) {
    query.order = order;
  }
  if (order_by) {
    query.order_by = order_by;
  }
  if (search) {
    query.search = search;
  }
  if (limit) {
    query.limit = limit;
  }
  if (offset) {
    query.offset = offset;
  }
  if (contractor) {
    query.contractor = contractor;
  }
  if (customer) {
    query.customer = customer;
  }
  return query;
};

export const prepareHistoryQuery = ({
                                      search,
                                      limit,
                                      offset,
                                    }) => {
  const query = {};
  if (search) {
    query.search = search;
  }
  if (limit) {
    query.limit = limit;
  }
  if (offset) {
    query.offset = offset;
  }
  return query;
};

export const prepareInvoiceQuery = (filters) => {
  const query = {};
  Object.keys(filters).forEach(f => {
    if (filters[f]) {
      if (f === 'dates' && isArray(filters[f])) {
        query.date = filters[f][0];
        query.tillDate = filters[f][1];
      } else {
        query[f] = filters[f];
      }
    }
  });
  return query;
};

const authActions = () => bindActionCreators({
  logOut,
  refreshToken,
  createNotification,
}, store.dispatch);

export const decodeJwtToken = token => jwtDecode(token);

export const transformRolesArrayToObject = (usersArray) => {
  return usersArray.reduce((accumulator, role) => {
    accumulator[role.toUpperCase()] =  role;
    return accumulator;
  }, {});
};

export const convertArrayToObjectForSelects = (list) => {
  return isArray(list)
      ? list.map((el, index) => ({
        id: index,
        text: el,
        value: el,
      }))
      : null;
};

const HTTP = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // baseURL: '/api',
});

const prepareAuth = token => `Bearer ${token}`;

const errorHandle = (error) => {
  if (error.response.status === 401) {
    return authActions().logOut();
  }
  return Promise.reject(error);
};

HTTP.interceptors.request.use(
  async (config) => {
    const appStore = store.getState().app;
    let token = appStore.access;
    const date = new Date();
    const tokenAccessExp = get(appStore, 'accessDecoded.exp', 0);

    if (config.url !== '/refresh-token/' && token) {
      // if (HTTP._queue) {
      //   console.log('add to queue', config)
      //   HTTP._queue.push(config)
      //   return
      // }

      if ((date.getTime()) >= (tokenAccessExp * 1000)) {
        HTTP._queue = [];
        token = await authActions().refreshToken(appStore.refresh)
        .then(res => (get(res, 'payload.response.status', 0) === 401 ?
          authActions().logOut() : store.getState().app.access));

        // while (HTTP._queue.length > 0) {
        //   const queueConfig = HTTP._queue.shift();
        //   console.log('request from queue', queueConfig.url);
        //   HTTP(queueConfig);
        // }
        // delete HTTP._queue

      }
      const authToken = prepareAuth(token);
      config.headers['Authorization'] = authToken;
    }
    return config;
  }
);

HTTP.interceptors.request.use(
  config => {
    if (
      config.method === 'post' ||
      config.method === 'put' ||
      config.method === 'patch'
    ) {
      config.headers['Content-Type'] = 'application/json';
      config.data = JSON.stringify(config.data);
    }
    return config;
  },
  (error) => errorHandle(error)
);

HTTP.interceptors.response.use(
  (response) => {
    const url = response.config.url;
    if (url.includes('token')) {
      const notifications = [];
      store.dispatch({
        type: constants.CREATE_NOTIFICATION,
        payload: notifications,
      });
    }
    return response;
  },
  (error) => {
    if (get(error, 'response.status', 0) >= 400) {
      for (const errorField in error.response.data) {
        const item = get(error, `response.data.${errorField}`, '');
        if(isArray(item)){
          item.map( errMessage => {
            const notification = {
              title: 'Oh no!!! Something went wrong!!!',
              msg: errMessage,
              type: 'negative',
            };
            authActions().createNotification(notification);
            return errMessage;
          });
        } else {
          const notification = {
            title: 'Oh no!!! Something went wrong!!!',
            msg: item,
            type: 'negative',
          };
          authActions().createNotification(notification);
        }
      }
    }
    return Promise.reject(error);
  }
);

// HTTP.interceptors.response.use(
//   response => response,
//   error => errorHandle(error)
// );


export default HTTP;
