import {
 connect,
} from 'react-redux';
import TotalPanel from './TotalPanel';
import {
 key,
} from '../module/constants';

const mapStateToProps = (state) => {
  return {
    listLength: state[key].reportsList.length,
    isFetching: state[key].isFetching,
    currencyList: state[key].currencyList,
    isMobile: state.app.isMobile,
  };
};

export default connect(mapStateToProps)(TotalPanel);
