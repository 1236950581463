import React, {
  Component,
} from 'react';
import {
  debounce,
} from 'lodash';
import {
  Search,
} from 'semantic-ui-react';
import PropTypes from "prop-types";

const propTypes = {
  onSearchChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  isFetching: PropTypes.bool,
};

const defaultProps = {
  value: '',
  isFetching: true,
};

class SearchComponent extends Component {
  constructor(props) {
    super(props);
    this.handleOnSearchChange = debounce(props.onSearchChange, 500, {
      leading: true,
    });
  }

  render() {
    const {
      value = '',
      isFetching = false,
    } = this.props;
    return (
      <Search
        aligned='right'
        showNoResults={false}
        loading={isFetching}
        onSearchChange={this.handleOnSearchChange}
        defaultValue={value}
      />
    );
  }
}

SearchComponent.propTypes = propTypes;
SearchComponent.defaultProps = defaultProps;

export default SearchComponent;
