import React, {
  Component,
} from 'react';
import PropTypes from 'prop-types';
import {
 Message,
} from 'semantic-ui-react';
import './style.scss';
import {
 NOTIFICATION_TIMEOUT,
} from '../../constants';
import {
prepareMultiLineText,
} from '../../utils';

export default class Notifications extends Component {
  static propTypes = {
    isMobile: PropTypes.bool.isRequired,
    notifications: PropTypes.arrayOf(PropTypes.object).isRequired,
    removeNotification: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.timers = {};
  }
  render() {
    const {
      isMobile,
      notifications,
      removeNotification,
    } = this.props;
    return (
      <div className={`notification-container${isMobile ? ' mobile' : ''}`}>
        {notifications.map((el => {
          const handleClearNotification = () => {
            clearTimeout(this.timers[el.id]);
            removeNotification(el);
          };
          this.timers[el.id] = setTimeout(handleClearNotification, NOTIFICATION_TIMEOUT);
          return (
            <Message
              color='red'
              key={el.id}
              onDismiss={handleClearNotification}
              content={prepareMultiLineText(el.msg)}
              negative={el.type === 'negative'}
            />
          );
        }))}
      </div>
    );
  }
}
