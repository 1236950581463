import React, {
  Component,
} from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Form,
  Button,
} from 'semantic-ui-react';
import {
  isEqual,
  isEmpty,
} from 'lodash';
import './style.scss';

const propTypes = {
  userId: PropTypes.number.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  account: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  editUser: PropTypes.func.isRequired,
  getProfile: PropTypes.func,
};

const defaultProps = {
  getProfile: () => {},
};

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: true,
      profile: {},
    };
  }

  handleChange = (name, val) => {
    const {
      profile,
    } = this.state;
    const currentAcc = profile;
    currentAcc[name] = val;
    this.setState({
      profile: currentAcc,
    });
  };

  doValidation = () => {
    const {
      account,
    } = this.props;
    const {
      profile,
    } = this.state;
    const newProfile = {
      ...account,
      ...profile,
    };
    if (!isEmpty(newProfile) &&
      (!newProfile.first_name.length ||
      !newProfile.last_name.length ||
      (newProfile.password && newProfile.password.length &&
        newProfile.password !== newProfile.confirm_password) ||
      isEqual(newProfile, account))
    ) {
      return true;
    }
    return false;
  };

  handleSubmit = () => {
    const {
      profile,
    } = this.state;
    const {
      account,
      editUser,
      getProfile,
      userId,
    } = this.props;
    const data = {
      ...account,
      ...profile,
    };
    delete data.confirm_password;
    editUser(data, userId)
    .then(() => {
      getProfile(userId);
      this.setState({
        // isEditing: false,
        profile: {},
      });
    });
  };

  render() {
    const {
      account,
      isFetching,
      history: {
        push,
      }, isMobile,
    } = this.props;
    const {
      isEditing,
      profile,
    } = this.state;
    const newProfile = {
      ...account, ...profile,
    };
    return (
      <Container text className={`profile${isMobile ? ' mobile' : ''}`}>
        <Form
          loading={isFetching}
          onSubmit={this.handleSubmit}
          id='the-profile-form'
        >
          <h2>{`${newProfile.username || ''} profile`}</h2>
          <Form.Input
            fluid
            label='First name'
            required
            value={newProfile.first_name || ''}
            placeholder='First name'
            readOnly={!isEditing}
            onChange={(e, val) => this.handleChange('first_name', val.value)}
          />
          <Form.Input
            fluid
            label='Last name'
            required
            value={newProfile.last_name || ''}
            placeholder='Last name'
            readOnly={!isEditing}
            onChange={(e, val) => this.handleChange('last_name', val.value)}
          />
          <Form.Input
            fluid
            icon='lock'
            iconPosition='left'
            placeholder={isEditing ? 'New password' : 'Password'}
            type='password'
            value={newProfile.password || ''}
            readOnly={!isEditing}
            onChange={(e, val) => this.handleChange('password', val.value)}
          />
          {isEditing && profile.password && (
            <Form.Input
              fluid
              icon='lock'
              iconPosition='left'
              placeholder='Confirm new password'
              type='password'
              value={newProfile.confirm_password || ''}
              required={!!(profile.password || '').length}
              readOnly={!isEditing}
              onChange={(e, val) => this.handleChange('confirm_password', val.value)}
            />
          )
          }
          {isEditing ? (
            <div className='button-wrapper'>
              <Button
                color='black'
                type='button'
                onClick={() => push('/main')}
                disabled={isFetching}
                content='Cancel'
                size={isMobile ? 'massive' : 'medium'}
              />
              <Button
                color='orange'
                inverted
                icon='checkmark'
                labelPosition='right'
                disabled={isFetching || this.doValidation()}
                type='submit'
                form='the-profile-form'
                content='Save'
                size={isMobile ? 'massive' : 'medium'}
              />
            </div>
          ) : (
            <Button
              color='blue'
              type='button'
              inverted
              icon='edit'
              labelPosition='right'
              content='Edit'
              onClick={() => this.setState({
                isEditing: true,
              })}
              size={isMobile ? 'massive' : 'medium'}
            />
          )}
        </Form>
      </Container>
    );
  }
}

Profile.propTypes = propTypes;
Profile.defaultProps = defaultProps;
export default Profile;
