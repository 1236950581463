import {
 connect, 
} from 'react-redux';
import BankAccounts from './BankAccounts';
import {
 key, 
} from '../module/constants';
import {
  getAllBankAcc as getAll,
  createBankAcc as create,
  editBankAcc as edit,
  removeBankAcc as remove,

  getAllCurrency,
  getAllPayMethods,
} from '../module/actions';

const mapStateToProps = (state) => ({
  list: state[key].bankAccounts,
  count: state[key].bankAccountsCount,
  currencylist: state[key].currency,
  payMethodslist: state[key].payMethods,
  isFetching: state[key].isFetching,
  isMobile: state.app.isMobile,
});

const mapDispatchToProps = {
  getAll,
  create,
  edit,
  remove,
  getAllCurrency,
  getAllPayMethods,
};

export default connect(mapStateToProps, mapDispatchToProps)(BankAccounts);
