import {
  connect,
} from 'react-redux';
import {
  withRouter,
} from 'react-router-dom';
import get from 'lodash/get';
import Navbar from './Navbar';
import {
  logOut,
} from '../../app/module/actions';
import getProfile from '../../profile/module/actions';

const mapStateToProps = (state) => ({
  userId: get(state, 'app.accessDecoded.user_id', 0),
  isFetching: state.app.isFetching,
  isMobile: state.app.isMobile,
});

const mapDispatchToProps = {
  logOut,
  getProfile,
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar));
