import {
 connect,
} from 'react-redux';
import {
  withRouter,
} from 'react-router';
import Main from './Main';
import {
  setInvoiceFilters,
} from './module/actions';

const mapStateToProps = (state) => ({
  isMobile: state.app.isMobile,
});
const mapDispatchToProps = {
  setInvoiceFilters,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));
