import {
  get,
} from 'lodash';
import constants from './constants';

export const initFilters = {
  limit: 10,
  offset: 0,
};

export const initialState = {
  historyList: [],
  filters: initFilters,
  count: 0,
  isFetching: false,
  error: {},
};

export const actionHandlers = {
  [constants.SET_FILTERS]: (state, action) => ({
    ...state,
    filters: action.payload,
  }),
  [constants.GET_HISTORY_REQUEST]: (state) => ({
    ...state,
    isFetching: true,
    error: null,
  }),
  [constants.GET_HISTORY_SUCCESS]: (state, action) => ({
      ...state,
      isFetching: false,
      historyList: get(action, 'payload.data.data', []),
      count: get(action, 'payload.data.total', 0),
    }),
  [constants.GET_HISTORY_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload,
  }),
};

const reducers = (state = initialState, action) => {
  const handler = actionHandlers[action.type];
  return handler ? handler(state, action) : state;
};

export default reducers;
