import React, {
  Component,
} from 'react';
import {
  Button,
  Form,
  Modal,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {
  debounce,
} from 'lodash';
import {
  modalStyles,
} from '../../ui_parts/Confirm';
import './style.scss';
import {
  getDataForDropDowns,
} from '../../utils';
import {
  INPUTS_VALIDATION_PATTERNS,
} from '../../constants';

const propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  getAvailableBankAcc: PropTypes.func.isRequired,
  item: PropTypes.instanceOf(Object).isRequired,
  bankAccList: PropTypes.instanceOf(Array).isRequired,
  isMobile: PropTypes.bool.isRequired,
};

class ContractorsModal extends Component {
  constructor() {
    super();
    this.state = {
      error: false,
      editableItem: {},
    };
    this.onSearchChange = debounce(this.handleSearch, 500, {
      leading: true,
    });
  }

  componentDidMount() {
    const {
      item,
    } = this.props;
    if (item && item.id) {
      return this.setState({
        editableItem: {
          bank_account: item.bank_account,
        },
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      isModalOpen,
      getAvailableBankAcc,
    } = this.props;
    if (!prevProps.isModalOpen && isModalOpen) {
      getAvailableBankAcc({
        freeAcc: 'show',
      });
    }
  }

  handleSearch = (e, val) => {
    const {
      getAvailableBankAcc,
    } = this.props;
    getAvailableBankAcc({
      search: val.searchQuery,
      freeAcc: 'show',
    });
  };

  handleChange = (name, val) => {
    const {
      editableItem,
    } = this.state;
    editableItem[name] = val;
    this.setState({
      editableItem,
      error: false,
    });
  };

  submit = () => {
    const {
      handleSubmit,
      item,
    } = this.props;
    const {
      editableItem,
    } = this.state;
    const newItem = {
      ...item,
      ...editableItem,
    };
    if (!newItem.bank_account || !newItem.bank_account.length) {
      return this.setState({
        error: true,
      });
    }
    handleSubmit(newItem);
  };

  close = () => {
    const {
      handleClose,
    } = this.props;
    handleClose();
    this.setState({
      editableItem: {},
    });
  };

  render() {
    const {
      isModalOpen,
      item,
      bankAccList,
      isFetching,
      isMobile,
    } = this.props;
    const {
      error,
      editableItem,
    } = this.state;
    const mergedItem = {
      ...item,
      ...editableItem,
    };
    return (
      <Modal
        dimmer='blurring'
        open={isModalOpen}
        closeOnDimmerClick={false}
        style={modalStyles}
        size='small'
        className={`management-modal${isMobile ? ' mobile' : ''}`}
      >
        <Modal.Header>{item.id ? `${item.name}` : 'Create new Contractor'}</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form
              loading={isFetching}
              onSubmit={this.submit}
              id='the-contractors-form'
            >
              <Form.Input
                fluid
                label='Contractor name'
                autoFocus
                required
                pattern={INPUTS_VALIDATION_PATTERNS.CONTRACTOR_NAME}
                defaultValue={mergedItem.name}
                placeholder='Contractor name'
                onChange={(e, val) => this.handleChange('name', val.value)}
              />
              <Form.Select
                fluid
                required
                search
                label='Bank account'
                multiple
                closeOnChange
                error={error}
                onChange={(e, val) => this.handleChange('bank_account', val.value)}
                options={bankAccList}
                placeholder='Select a bank account'
                onSearchChange={this.onSearchChange}
                value={(mergedItem.bank_account || []).map(e => getDataForDropDowns(e))}
              />
              <Form.TextArea
                autoHeight
                rows={1}
                label='Contractors address'
                defaultValue={mergedItem.address}
                placeholder='Contractors address'
                onChange={(e, val) => this.handleChange('address', val.value)}
              />
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color='black'
            onClick={this.close}
            disabled={isFetching}
            content='Cancel'
            size={isMobile ? 'massive' : 'medium'}
          />
          <Button
            color='orange'
            inverted
            icon='checkmark'
            labelPosition='right'
            disabled={isFetching}
            type='submit'
            form='the-contractors-form'
            content={item.id ? 'Save' : 'Create'}
            size={isMobile ? 'massive' : 'medium'}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

ContractorsModal.propTypes = propTypes;
export default ContractorsModal;
