import React, {
  Component,
} from 'react';
import {
  Button,
  Form,
  Message,
  Modal,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import {
  modalStyles,
} from '../../ui_parts/Confirm';
import './style.scss';

const propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  getUserRoles: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  usersRoles: PropTypes.instanceOf(Object).isRequired,
  isMobile: PropTypes.bool.isRequired,
  error: PropTypes.instanceOf(Object),
};

const defaultProps = {
  error: {},
};

class UsersModal extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    const {
      getUserRoles,
      user,
    } = this.props;
    getUserRoles();
    if (!user || !user.user_role) {
      return this.setState({
        user_role: 'manager',
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      isModalOpen,
      getUserRoles,
    } = this.props;
    if (!prevProps.isModalOpen && isModalOpen) {
      getUserRoles();
    }
  }

  handleChange = (name, val) => {
    this.setState({
      [name]: val,
    });
  };

  submit = () => {
    const {
      handleSubmit,
      user,
    } = this.props;
    const newUser = {
      ...user,
      ...this.state,
    };
    if (!newUser.user_role) {
      return;
    }
    handleSubmit(newUser);
  };

  close = () => {
    const {
      handleClose,
    } = this.props;
    handleClose();
  };

  render() {
    const {
      isModalOpen,
      user: {
        id,
        username,
        first_name,
        last_name,
        user_role,
      },
      isFetching,
      usersRoles,
      isMobile,
      error,
    } = this.props;

    return (
      <Modal
        dimmer='blurring'
        open={isModalOpen}
        closeOnDimmerClick={false}
        style={modalStyles}
        size='small'
        className={`management-modal${isMobile ? ' mobile' : ''}`}
      >
        <Modal.Header>
          {id ? `${username}` : 'Create new user'}
        </Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            <Form
              loading={isFetching}
              onSubmit={this.submit}
              id='the-user-form'
            >
              <Form.Input
                type='email'
                pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$'
                fluid
                label='Email'
                autoFocus
                required
                defaultValue={username}
                placeholder='Username'
                onChange={(e, val) => this.handleChange('username', val.value)}
              />
              <Form.Input
                fluid
                required
                pattern='[A-Za-z0-9]+'
                label='First name'
                defaultValue={first_name}
                placeholder='First name'
                onChange={(e, val) => this.handleChange('first_name', val.value)}
              />
              <Form.Input
                fluid
                required
                pattern='[A-Za-z0-9]+'
                label='Last name'
                placeholder='Last name'
                defaultValue={last_name}
                onChange={(e, val) => this.handleChange('last_name', val.value)}
              />
              <Form.Select
                fluid
                required
                label='User role'
                onChange={(e, val) => this.handleChange('user_role', val.value)}
                options={Object.keys(usersRoles).map((key, i) => ({
                  id: i,
                  text: usersRoles[key],
                  value: usersRoles[key],
                }))}
                placeholder='User role'
                defaultValue={isUndefined(user_role) ? usersRoles.MANAGER : user_role}
              />
              {!id && (
                <h4>
                  <i>Default password</i>
                  {' '}
                  !@#123Qwe
                </h4>
              )}
            </Form>
            {!isEmpty(error) && (
              <Message error>
                {error.response.statusText}
              </Message>
            )}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color='black'
            onClick={this.close}
            disabled={isFetching}
            content='Cancel'
            size={isMobile ? 'massive' : 'medium'}
          />
          <Button
            color='orange'
            inverted
            icon='checkmark'
            labelPosition='right'
            disabled={isFetching}
            type='submit'
            form='the-user-form'
            content={id ? 'Save' : 'Create'}
            size={isMobile ? 'massive' : 'medium'}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

UsersModal.propTypes = propTypes;
UsersModal.defaultProps = defaultProps;
export default UsersModal;
