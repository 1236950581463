import React, {
  Component,
} from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  Grid,
  Button,
  Icon,
} from 'semantic-ui-react';
import moment from 'moment';
import map from 'lodash/map';
import get from 'lodash/get';
import Search from '../../ui_parts/Search';
import './style.scss';
import {
  prepareQuery,
  prepareInvoiceQuery,
  getAuthorOfInvoice,
} from '../../utils';
import PermissionWrapper, {
  roles,
} from '../../ui_parts/PermissionWrapper';
import {
  DATE_FORMAT,
} from '../../constants';

const propTypes = {
  list: PropTypes.instanceOf(Array).isRequired,
  filters: PropTypes.instanceOf(Object).isRequired,
  getAll: PropTypes.func.isRequired,
  setReportFilters: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

class ListPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const {
      filters,
    } = this.props;
    this.fetchList(filters);
  }

  handleSearchChange = (e, {
    value,
  }) => {
    const {
      filters,
      setReportFilters,
    } = this.props;
    const newFilters = {
      ...filters,
    };
    if (value) {
      newFilters.search = value;
    } else {
      delete newFilters.search;
    }
    setReportFilters(newFilters);
    this.fetchList(newFilters);
  };

  fetchList = (filters = {}) => {
    const {
      getAll,
    } = this.props;
    return getAll({
      ...prepareQuery(filters), ...prepareInvoiceQuery(filters),
    });
  };

  handleSort = (clickedColumn) => {
    const {
      filters,
      setReportFilters,
    } = this.props;
    const newFilters = {
      ...filters,
    };
    if (filters.order_by !== clickedColumn) {
      newFilters.order_by = clickedColumn;
    }
    newFilters.order = filters.order === 'asc' ? 'desc' : 'asc';
    setReportFilters(newFilters);
    this.fetchList(newFilters);
  };

  handleEdit = (id) => {
    const {
      history: {
        push,
      },
    } = this.props;
    push(`/reports/${id}`);
  };

  render() {
    const {
      isFetching,
      filters: {
        order,
        search,
        order_by,
      },
      list,
      isMobile,
    } = this.props;
    const direction = order === 'asc' ? 'ascending' : 'descending';
    return (
      <Grid.Row className={`reports-list-panel${isMobile ? ' mobile' : ''}`}>
        <Table compact unstackable color='orange'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan='10'>
                <Search
                  value={search || ''}
                  isFetching={isFetching}
                  onSearchChange={this.handleSearchChange}
                />
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell
                sorted={order_by === 'number' ? direction : null}
                onClick={() => this.handleSort('number')}
              >
                Number
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={order_by === 'date' ? direction : null}
                onClick={() => this.handleSort('date')}
              >
                Date
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={order_by === 'till_date' ? direction : null}
                onClick={() => this.handleSort('till_date')}
              >
                Due date
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={order_by === 'customer' ? direction : null}
                onClick={() => this.handleSort('customer')}
              >
                Customer
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={order_by === 'contractor' ? direction : null}
                onClick={() => this.handleSort('contractor')}
              >
                Contractor
              </Table.HeaderCell>
              <Table.HeaderCell>
                User
              </Table.HeaderCell>
              <Table.HeaderCell>Total</Table.HeaderCell>
              <Table.HeaderCell>Paid</Table.HeaderCell>
              <Table.HeaderCell>Currency</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {map(list, item => {
              const isInvoiceOverdue = (item.invoice_state !== 'paid') && moment().isAfter(item.till_date);
              return (
                <Table.Row
                  key={item.id}
                  positive={item.invoice_state === 'paid'}
                  negative={item.invoice_state === 'unpaid' && isInvoiceOverdue}
                  warning={item.invoice_state === 'partially paid'}
                >
                  <Table.Cell data-label='Number'>{item.number || '-'}</Table.Cell>
                  <Table.Cell data-label='Date'>{moment(item.date).format(DATE_FORMAT)}</Table.Cell>
                  <Table.Cell data-label='Date'>{moment(item.till_date).format(DATE_FORMAT)}</Table.Cell>
                  <Table.Cell data-label='Customer'>{get(item, 'customer.name', '')}</Table.Cell>
                  <Table.Cell data-label='Contractor'>{get(item, 'contractor.name', '')}</Table.Cell>
                  <Table.Cell data-label='User'>{getAuthorOfInvoice(item)}</Table.Cell>
                  <Table.Cell data-label='Total'>{item.total}</Table.Cell>
                  <Table.Cell data-label='Paid'>{item.payed_part}</Table.Cell>
                  <Table.Cell data-label='Currency'>{get(item, 'bank_account.currency.name', '')}</Table.Cell>
                  <Table.Cell data-label=''>
                    <PermissionWrapper
                      availableRoles={[
                        roles.ROOT,
                        roles.ACCOUNTANT,
                        roles.DIRECTOR,
                      ]}
                    >
                      <Button
                        icon
                        onClick={() => this.handleEdit(item.id)}
                      >
                        <Icon
                          name='edit'
                        />
                      </Button>
                    </PermissionWrapper>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Grid.Row>
    );
  }
}

ListPanel.propTypes = propTypes;
export default ListPanel;
