import React, {
  Component,
} from 'react';
import {
  Button, Modal,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import {
  modalStyles,
} from '../../ui_parts/Confirm';
import InvoicePDF from './InvoicePdf/InvoicePDF';
import './style.scss';


const propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

const defaultProps = {};

class PreviewModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIsMobile: props.isMobile,
      processing: false,
    };
    this.generatePDF = this.generatePDF.bind(this);
    this.createPDF = this.createPDF.bind(this);
  }

  createPDF = () => {
    const {
      isMobile,
    } = this.state;
    if (isMobile) {
      this.setState({
        isMobile: false,
        processing: true,
      }, this.generatePDF);
    } else {
      this.setState({
        processing: true,
      }, this.generatePDF);
    }
  };

  generatePDF = () => {
    const {
      isMobile,
    } = this.props;
    const element = document.getElementById('invoicePDF');
    const width = element.scrollWidth;
    const height = element.scrollHeight;
    html2canvas(element, {
      logging: false,
      width: `${width}px`,
      heigth: `${height}px`,
    })
    .then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        unit: 'pt',
        format: [width, height],
      });
      pdf.addImage(imgData, 'JPEG', 0, 0, width, height);
      pdf.save('invoice.pdf');
      if (isMobile) {
        this.setState({
          isMobile: true,
          processing: false,
        });
      } else {
        this.setState({
          processing: false,
        });
      }
    });
  };

  render() {
    const {
      isModalOpen,
      handleClose,
      isFetching,
      isMobile,
      ...item
    } = this.props;
    const {
      processing,
      currentIsMobile,
    } = this.state;
    return (
      <Modal
        dimmer='blurring'
        open={isModalOpen}
        closeOnDimmerClick={false}
        style={{
          ...modalStyles,
          width: 'auto',
        }}
        className={`invoice-preview-modal${currentIsMobile ? ' mobile' : ''}`}
      >
        <Modal.Content className='invoice-modal-content'>
          <InvoicePDF
            {...item}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            color='black'
            onClick={handleClose}
            disabled={isFetching || processing}
            content='Close'
            size={isMobile ? 'massive' : 'medium'}
          />
          <Button
            color='orange'
            inverted
            icon='download'
            labelPosition='right'
            disabled={isFetching || processing}
            type='submit'
            form='the-customer-form'
            content='Download'
            onClick={this.createPDF}
            size={isMobile ? 'massive' : 'medium'}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

PreviewModal.propTypes = propTypes;
PreviewModal.defaultProps = defaultProps;
export default PreviewModal;
