export const key = 'app';
export const mobileDeviceList = [
  'iPhone',
  'BlackBerry',
  'HTC',
  'Nexus',
  'Dell',
  'Motorola',
  'Samsung',
  'LG',
  'Sony',
  'Asus',
  'NokiaLumia',
  'Micromax',
  'Palm',
  'Vertu',
  'Pantech',
  'Fly',
  'Wiko',
  'iMobile',
  'SimValley',
  'Wolfgang',
  'Alcatel',
  'Nintendo',
  'Amoi',
  'INQ',
  'GenericPhone',
  'UnknownPhone',
];
export default {
  SET_WIDTH: `${key}/SET_WIDTH`,
  SET_IS_MOBILE: `${key}/SET_IS_MOBILE`,
  LOGIN_REQUEST: `${key}/LOGIN_REQUEST`,
  LOGIN_SUCCESS: `${key}/LOGIN_SUCCESS`,
  LOGIN_FAILURE: `${key}/LOGIN_FAILURE`,
  LOGOUT: `${key}/LOGOUT`,
  REFRESH_TOKEN_REQUEST: `${key}/REFRESH_TOKEN_REQUEST`,
  REFRESH_TOKEN_SUCCESS: `${key}/REFRESH_TOKEN_SUCCESS`,
  REFRESH_TOKEN_FAILURE: `${key}/REFRESH_TOKEN_FAILURE`,

  CREATE_NOTIFICATION: `${key}/CREATE_NOTIFICATION`,
};
