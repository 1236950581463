import {
 connect, 
} from 'react-redux';
import Customers from './Customers';
import {
 key, 
} from '../module/constants';
import {
  getAllCustomers as getAll,
  createCustomer as create,
  editCustomer as edit,
  removeCustomer as remove,
} from '../module/actions';

const mapStateToProps = (state) => ({
  list: state[key].customers,
  count: state[key].customersCount,
  isFetching: state[key].isFetching,
  isMobile: state.app.isMobile,
});

const mapDispatchToProps = {
  getAll,
  create,
  edit,
  remove,
};

export default connect(mapStateToProps, mapDispatchToProps)(Customers);
