import React, {
  Component,
} from 'react';
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Segment,
  Message,
} from 'semantic-ui-react';
import {
  Redirect,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import img from '../assets/Lanars-logo.jpg';
import './style.scss';

const propTypes = {
  logIn: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool,
  error: PropTypes.instanceOf(Object),
  token: PropTypes.string,
};

const DefaultProps = {
  error: {},
  token: '',
  isMobile: false,
};

class LogIn extends Component {
  constructor() {
    super();
    this.state = {
      credential: {
        username: '',
        password: '',
      },
    };
  }

  handleSubmit = () => {
    const {
      credential: {
        username,
        password,
      },
    } = this.state;
    const {
      logIn,
    } = this.props;
    logIn({
      username,
      password,
    });
  };

  handleChange = (val, name) => {
    const {
      credential,
    } = this.state;
      const newCredential = {
      ...credential,
    };
    newCredential[name] = val.value;
    this.setState({
      credential: newCredential,
    });
  };

  render() {
    const {
      credential: {
        username,
        password,
      },
    } = this.state;
    const {
      isFetching,
      error,
      token,
      isMobile,
    } = this.props;
    return (token ? <Redirect to='/' /> : (
      <div className={`login-form${isMobile ? ' mobile' : ''}`}>
        <Grid
          textAlign='center'
          verticalAlign='middle'
        >
          <Grid.Column>
            <Header as='h2' textAlign='center'>
              <Image src={img} />
              {' '}
              <span>
                Log-in to your account
              </span>
            </Header>
            <Form
              size='large'
              onSubmit={this.handleSubmit}
            >
              <Segment stacked>
                <Form.Input
                  fluid
                  autoFocus
                  icon='user'
                  iconPosition='left'
                  placeholder='E-mail address'
                  value={username}
                  required
                  onChange={(e, v) => this.handleChange(v, 'username')}
                />
                <Form.Input
                  fluid
                  icon='lock'
                  iconPosition='left'
                  placeholder='Password'
                  type='password'
                  value={password}
                  required
                  onChange={(e, v) => this.handleChange(v, 'password')}
                />
                <Button
                  fluid
                  size='large'
                  loading={isFetching}
                >
                  Login
                </Button>
              </Segment>
            </Form>
            {(error && error.errorType === 'login_error') && (
              <Message error>
                Incorrect email or password!
              </Message>
            )}
          </Grid.Column>
        </Grid>
      </div>
      )
    );
  }
}

LogIn.propTypes = propTypes;
LogIn.defaultProps = DefaultProps;
export default LogIn;
