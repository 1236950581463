import React from 'react';
import propTypes from 'prop-types';
import {
  Button,
  Header,
  Icon,
  Modal,
} from 'semantic-ui-react';
import {
  connect,
} from 'react-redux';

export const modalStyles = {
  marginTop: '0px',
  marginLeft: 'auto',
  marginRight: 'auto',
};

const Confirm = (props) => {
  const {
    open,
    onCancel,
    onConfirm,
    content,
    header = '',
    icon,
    disabled,
    confirmButtonText,
    confirmButtonColor,
    confirmButtonIcon,
    cancelButton,
    cancelButtonText,
    cancelButtonColor,
    cancelButtonIcon,
    size,
    isMobile,
  } = props;
  return (
    <Modal
      open={open}
      basic
      dimmer='blurring'
      size={size}
      style={isMobile ? {
        ...modalStyles,
        'fontSize': '3rem',
      } : modalStyles}
      onClose={() => onCancel()}
    >
      {
        (icon || header) ? <Header icon={icon} content={header} /> : null
      }
      <Modal.Content>
        {content && <p>{content}</p>}
      </Modal.Content>
      <Modal.Actions>
        {cancelButton && (
          <Button
            basic
            color={cancelButtonColor}
            inverted
            disabled={disabled}
            onClick={onCancel}
            size={isMobile ? 'massive' : 'medium'}
          >
            <Icon name={cancelButtonIcon} />
            {cancelButtonText}
          </Button>
        )}
        <Button
          loading={disabled}
          color={confirmButtonColor}
          inverted={!isMobile}
          onClick={onConfirm}
          size={isMobile ? 'massive' : 'medium'}
        >
          <Icon name={confirmButtonIcon} />
          {confirmButtonText}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isMobile: state.app.isMobile,
});

const defaultProps = {
  open: false,
  onCancel: {},
  onConfirm: {},
  content: 'Are you sure?',
  header: '',
  icon: {},
  disabled: false,
  confirmButtonText: 'Ok',
  confirmButtonColor: 'orange',
  confirmButtonIcon: 'checkmark',
  cancelButton: true,
  cancelButtonText: 'Cancel',
  cancelButtonColor: 'red',
  cancelButtonIcon: 'remove',
  isMobile: false,
  size: 'small',
};

Confirm.propTypes = {
  open: propTypes.bool,
  onCancel: propTypes.instanceOf(Object),
  onConfirm: propTypes.instanceOf(Object),
  content: propTypes.string,
  header: propTypes.string,
  icon: propTypes.instanceOf(Object),
  disabled: propTypes.bool,
  confirmButtonText: propTypes.string,
  confirmButtonColor: propTypes.string,
  confirmButtonIcon: propTypes.string,
  cancelButton: propTypes.bool,
  cancelButtonText: propTypes.string,
  cancelButtonColor: propTypes.string,
  cancelButtonIcon: propTypes.string,
  size: propTypes.string,
  isMobile: propTypes.bool,
};
Confirm.defaultProps= defaultProps;

export default connect(mapStateToProps)(Confirm);
