import {
 connect,
} from 'react-redux';
import {
  withRouter,
} from 'react-router';
import Management from './Management';

const mapStateToProps = (state) => ({
  width: state.app.width,
  isMobile: state.app.isMobile,
});

export default withRouter(connect(mapStateToProps)(Management));
