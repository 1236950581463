import React, {
  Component,
} from 'react';
import {
  Button,
  Form,
  Modal,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {
  modalStyles,
} from '../../ui_parts/Confirm';
import './style.scss';
import {
  INPUTS_VALIDATION_PATTERNS,
} from '../../constants';

const propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  item: PropTypes.instanceOf(Object).isRequired,
  isMobile: PropTypes.bool.isRequired,
};

const defaultProps = {};

class CurrencyModal extends Component {
  constructor() {
    super();
    this.state = {
      editableItem: {},
    };
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
    this.close = this.close.bind(this);
  }

  handleChange = (name, val) => {
    const {
      editableItem,
    } = this.state;
    editableItem[name] = val;
    this.setState({
      editableItem,
    });
  }

  submit = () => {
    const {
      handleSubmit,
      item,
    } = this.props;
    const {
      editableItem,
    } = this.state;
    const newItem = {
      ...item,
      ...editableItem,
    };
    handleSubmit(newItem);
  };

  close = () => {
    const {
      handleClose,
    } = this.props;
    handleClose();
    this.setState({
      editableItem: {},
    });
  };

  render() {
    const {
      isModalOpen,
      item: {
        id,
        name,
      },
      isFetching,
      isMobile,
    } = this.props;
    return (
      <Modal
        dimmer='blurring'
        closeOnDimmerClick={false}
        open={isModalOpen}
        style={modalStyles}
        size='small'
        className={`management-modal${isMobile ? ' mobile' : ''}`}
      >
        <Modal.Header>{id ? name : 'Create new Payment method'}</Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            <Form
              loading={isFetching}
              onSubmit={this.submit}
              id='the-payment-method-form'
            >
              <Form.Input
                fluid
                label='Payment method name'
                autoFocus
                required
                pattern={INPUTS_VALIDATION_PATTERNS.PAYMENT_NAME}
                defaultValue={name}
                placeholder='Payment method name'
                onChange={(e, val) => this.handleChange('name', val.value)}
              />
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color='black'
            onClick={this.close}
            disabled={isFetching}
            content='Cancel'
            size={isMobile ? 'massive' : 'medium'}
          />
          <Button
            color='orange'
            inverted
            icon='checkmark'
            labelPosition='right'
            disabled={isFetching}
            type='submit'
            form='the-payment-method-form'
            content={id ? 'Save' : 'Create'}
            size={isMobile ? 'massive' : 'medium'}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

CurrencyModal.propTypes = propTypes;
CurrencyModal.defaultProps = defaultProps;
export default CurrencyModal;
