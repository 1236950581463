export const DATE_FORMAT = 'MM/DD/YY';
export const DATE_FORMAT_HISTORY = 'DD.MM.YYYY HH:mm:ss';

export const INPUTS_VALIDATION_PATTERNS = {
  // the same patterns using for different inputs to have possibility customize each pattern one by one in future
  INVOICE: '^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$',
  CONTRACTOR_NAME: '^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$',
  CUSTOMER_NAME: '^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$',
  PROJECT_NAME: '^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$',
  PAYMENT_NAME: '^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$',
};
export const NOTIFICATION_TIMEOUT = 15000;

export const DATA_LIMIT = 999;
