import {
 connect,
} from 'react-redux';
import {
  withRouter,
} from 'react-router';
import Users from './Users';
import {
 key,
} from '../module/constants';
import {
  getAllUsers,
  getUserRoles,
  removeUser,
  createUser,
  editUser,
} from '../module/actions';


const mapStateToProps = (state) => ({
  usersList: state[key].users,
  count: state[key].usersCount,
  usersRoles: state[key].roles,
  isFetching: state[key].isFetching,
  isMobile: state.app.isMobile,
  error: state[key].error,
});

const mapDispatchToProps = {
  getAllUsers,
  getUserRoles,
  removeUser,
  createUser,
  editUser,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Users));
