import {
 connect,
} from 'react-redux';
import {
 withRouter,
} from 'react-router-dom';
import ListPanel from './ListPanel';
import {
  getInvoices as getAll,
  removeInvoice as remove,
  setInvoiceFilters,
  setIsCopy,
} from '../module/actions';
import {
 key,
} from '../module/constants';

const mapStateToProps = (state) => ({
  list: state[key].invoiceList,
  count: state[key].count,
  isFetching: state[key].isFetching,
  filters: state[key].filters,
  isMobile: state.app.isMobile,
  userId: state.profile.account ? state.profile.account.id : 0,
});

const mapDispatchToProps = {
  getAll,
  remove,
  setInvoiceFilters,
  setIsCopy,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListPanel));
