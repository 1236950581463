import React from 'react';
import {
  connect,
} from 'react-redux';
import {
  Pagination,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import './pagination.scss';

const propTypes = {
  activePage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  handlePaginationChange: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

const Paginator = ({
                     handlePaginationChange,
                     activePage = 1,
                     totalPages = 0,
                     isMobile,
                   }) => (
                     <Pagination
                       className={`paginator${isMobile ? ' mobile' : ''}`}
                       floated='right'
                       activePage={activePage}
                       pointing={isMobile}
                       secondary={isMobile}
                       onPageChange={handlePaginationChange}
                       totalPages={totalPages}
                       siblingRange={isMobile ? 1 : 2}
                     />
);

Paginator.propTypes = propTypes;


const mapStateToProps = (state) => ({
  isMobile: state.app.isMobile,
});


export default connect(mapStateToProps)(Paginator);
