import get from 'lodash/get';
import map from 'lodash/map';
import moment from 'moment';
import constants from './constants';

export const getInitialDateFilters = () => [moment().startOf('month'), moment()];

export const initFilters = {
  order_by: 'date',
  order: 'desc',
  dates: map(getInitialDateFilters(), e => e.unix()),
};

export const initialState = {
  reportsList: [],
  currencyList: [],
  filters: initFilters,
  total_sum: 0,
  paid: 0,
  isFetching: false,
  error: null,
};

export const actionHandlers = {
  [constants.SET_FILTERS]: (state, action) => ({
    ...state,
    filters: action.payload,
  }),

  [constants.GET_REPORTS_REQUEST]: (state) => ({
    ...state,
    isFetching: true,
    error: null,
  }),
  [constants.GET_REPORTS_SUCCESS]: (state, action) => ({
    ...state,
    isFetching: false,
    reportsList: get(action, 'payload.data.data', []),
    currencyList: get(action, 'payload.data.currency', []),
  }),
  [constants.GET_REPORTS_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload,
  }),

};

export const reducer = (state = initialState, action) => {
  const handler = actionHandlers[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
