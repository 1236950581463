import React from 'react';
import propTypes from 'prop-types';
import {
  Button,
  Icon,
} from 'semantic-ui-react';
// import TotalPanel from "../reports/totalPanel/TotalPanel";
// import FiltersPanel from "../reports/filtersPanel/FiltersPanel";

const AddItemButton = (props) => {
  const {
    onClick = null,
    icon = 'user',
    text = 'Add new user',
    inverted = true,
  } = props;
  return (
    <Button
      floated='left'
      icon
      labelPosition='left'
      inverted={inverted}
      color='orange'
      size='small'
      onClick={onClick}
    >
      <Icon name={icon} />
      {text}
    </Button>
  );
};

AddItemButton.propTypes = {
  onClick: propTypes.instanceOf(Object),
  icon: propTypes.string,
  text: propTypes.string,
  inverted: propTypes.bool,
};

AddItemButton.defaultProps = {
  onClick: null,
  icon: 'user',
  text: 'Add new user',
  inverted: true,
};

export default AddItemButton;
