import {
 connect,
} from 'react-redux';
import LogIn from './LogIn';
import {
 logIn,
} from '../app/module/actions';

const mapStateToProps = (state) => ({
  isFetching: state.app.isFetching,
  token: state.app.access,
  error: state.app.error,
  isMobile: state.app.isMobile,
});

const initMapDispatchToProps = {
  logIn,
};

export default connect(mapStateToProps, initMapDispatchToProps)(LogIn);
