import React, {
  Component,
} from 'react';
import {
  Table,
  Icon,
  Button,
  Container,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {
  Redirect,
} from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import UserModal from './Modal';
import './style.scss';
import {
  prepareQuery,
  preparePagination,
  setOffset,
} from '../../utils';
import Confirm from '../../ui_parts/Confirm';
import Pagination from '../../ui_parts/Pagination';
import AddItemButton from '../../ui_parts/AddItemButton';
import Search from '../../ui_parts/Search';
import PermissionWrapper, {
  roles,
  permissionBool,
} from '../../ui_parts/PermissionWrapper';

const propTypes = {
  usersList: PropTypes.instanceOf(Array).isRequired,
  count: PropTypes.number.isRequired,
  usersRoles: PropTypes.instanceOf(Object).isRequired,
  getAllUsers: PropTypes.func.isRequired,
  getUserRoles: PropTypes.func.isRequired,
  removeUser: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  editUser: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  error: PropTypes.instanceOf(Object),
};

const defaultProps = {
  error: {},
};

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order_by: 'username',
      order: 'desc',
      isModalOpen: false,
      isConfirmOpen: false,
      user: {},
      search: '',
      limit: 10,
      offset: 0,
    };
  }

  componentDidMount() {
    this.fetchList();
  }

  handleSearchChange = (e, {
    value,
  }) => {
    this.setState({
      search: value,
      offset: 0,
    }, () => this.fetchList());
  };

  handlePaginationChange = (e, {
    activePage,
  }) => {
    this.setState({
      offset: setOffset(activePage),
    }, () => this.fetchList());
  };

  handleToggleModal = (isModalOpen = false, user = {}) => {
    this.setState({
      user,
      isModalOpen,
    });
  };

  handleRemove = () => {
    const {
      getAllUsers,
      removeUser,
    } = this.props;
    const {
      user,
    } = this.state;
    removeUser(user.id)
    .then(() => {
      getAllUsers();
      this.handleToggleConfirm();
    });
  };

  handleToggleConfirm = (isConfirmOpen = false, user = {}) => {
    this.setState({
      user,
      isConfirmOpen,
    });
  };

  handleSubmit = (user) => {
    const {
      createUser,
      editUser,
    } = this.props;
    const fn = user.id ? editUser : createUser;
    fn(user, user.id)
    .then(() => {
      const {
        error,
      } = this.props;
      if (isEmpty(error)) {
        this.fetchList();
        this.handleToggleModal();
      }
    });
  };

  fetchList = () => {
    const {
      getAllUsers,
    } = this.props;
    return getAllUsers(prepareQuery(this.state));
  };

  handleSort = (clickedColumn) => {
    const {
      order_by,
      order,
    } = this.state;
    if (order_by !== clickedColumn) {
      this.setState({
        order_by: clickedColumn,
        order: 'asc',
      }, () => this.fetchList());
      return;
    }
    const direction = order === 'asc' ? 'desc' : 'asc';
    this.setState({
      order: direction,
    }, () => this.fetchList());
  };

  render() {
    if (permissionBool([roles.MANAGER])) {
      return <Redirect to='/' />;
    }
    const {
      isFetching,
      usersList,
      usersRoles,
      getUserRoles,
      count,
      isMobile,
      error,
    } = this.props;

    const {
      isModalOpen,
      user,
      isConfirmOpen,
      order_by,
      order,
      limit,
      offset,
    } = this.state;
    const direction = order === 'asc' ? 'ascending' : 'descending';
    const {
      totalPages,
      activePage,
    } = preparePagination(limit, offset, count);
    return (
      <div className={`users${isMobile ? ' mobile' : ''}`}>
        <Container>
          <Table compact unstackable color='orange'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan='1'>
                  <PermissionWrapper availableRoles={[roles.ROOT, roles.DIRECTOR]}>
                    <AddItemButton
                      onClick={() => this.handleToggleModal(true)}
                      icon='user'
                      text='Add new user'
                    />
                  </PermissionWrapper>
                </Table.HeaderCell>
                <Table.HeaderCell colSpan='3'>
                  <Search
                    isFetching={isFetching}
                    onSearchChange={this.handleSearchChange}
                  />
                </Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell
                  sorted={order_by === 'username' ? direction : null}
                  onClick={() => this.handleSort('username')}
                >
                  User Name
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={order_by === 'first_name' ? direction : null}
                  onClick={() => this.handleSort('first_name')}
                >
                  First name
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={order_by === 'last_name' ? direction : null}
                  onClick={() => this.handleSort('last_name')}
                >
                  Last name
                </Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {usersList.map(user => (
                <Table.Row key={user.id}>
                  <Table.Cell data-label='User Name'>
                    {user.username}
                  </Table.Cell>
                  <Table.Cell data-label='First name'>
                    {user.first_name}
                  </Table.Cell>
                  <Table.Cell data-label='Last name'>
                    {user.last_name}
                  </Table.Cell>
                  <Table.Cell textAlign='right'>
                    <PermissionWrapper availableRoles={[roles.ROOT, roles.DIRECTOR]}>
                      <Button
                        icon
                        onClick={() => this.handleToggleModal(true, user)}
                      >
                        <Icon
                          name='edit'
                        />
                      </Button>
                      <Button
                        icon
                        onClick={() => this.handleToggleConfirm(true, user)}
                      >
                        <Icon name='trash' />
                      </Button>
                    </PermissionWrapper>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            {totalPages > 1 && (
              <Table.Footer fullWidth>
                <Table.Row>
                  <Table.HeaderCell colSpan='4'>
                    <Pagination
                      handlePaginationChange={this.handlePaginationChange}
                      activePage={activePage}
                      totalPages={totalPages}
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            )}
          </Table>
        </Container>
        <Confirm
          open={isConfirmOpen}
          confirmButton='Delete'
          content='Are you sure you want to remove this user?'
          onCancel={() => this.handleToggleConfirm()}
          onConfirm={this.handleRemove}
          disabled={isFetching}
        />
        <UserModal
          handleClose={() => this.handleToggleModal()}
          handleSubmit={this.handleSubmit}
          isModalOpen={isModalOpen}
          isFetching={isFetching}
          user={user}
          usersRoles={usersRoles}
          getUserRoles={getUserRoles}
          isMobile={isMobile}
          error={error}
        />
      </div>
    );
  }
}

Users.propTypes = propTypes;
Users.defaultProps = defaultProps;
export default Users;
