import React, {
  Component,
} from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  Grid,
} from 'semantic-ui-react';
import moment from 'moment';
import {
  map,
  debounce,
} from 'lodash';
import Search from '../../ui_parts/Search';
import Pagination from '../../ui_parts/Pagination';
import './style.scss';
import {
  prepareHistoryQuery,
  preparePagination,
  setOffset,
} from '../../utils';
import {
  DATE_FORMAT_HISTORY,
} from '../../constants';

const propTypes = {
  isMobile: PropTypes.bool.isRequired,
  list: PropTypes.instanceOf(Array).isRequired,
  filters: PropTypes.instanceOf(Object).isRequired,
  count: PropTypes.number.isRequired,
  getVisitingHistory: PropTypes.func.isRequired,
  setHistoryFilters: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

class ListPanel extends Component {

  handleSearchChange = debounce ((e, {
    value,
  }) => {
    const {
      filters, setHistoryFilters,
    } = this.props;
    const newFilters = {
      ...filters,
    };
    newFilters.offset = 0;
    if (value) {
      newFilters.search = value;
    } else {
      delete newFilters.search;
    }
    setHistoryFilters(newFilters);
    this.fetchList(newFilters);
  }, 1000, {
    leading: true,
  });

  componentDidMount() {
    const {
      filters,
    } = this.props;
    this.fetchList(filters);
  }


  handlePaginationChange = (e, {
    activePage,
  }) => {
    const {
      filters, setHistoryFilters,
    } = this.props;
    const newFilters = {
      ...filters,
    };
    newFilters.offset = setOffset(activePage);
    setHistoryFilters(newFilters);
    this.fetchList(newFilters);
  };

  fetchList = (filters = {}) => {
    const {
      getVisitingHistory,
    } = this.props;
    return getVisitingHistory({
      ...prepareHistoryQuery(filters),
    });
  };


  render() {
    const {
      isFetching,
      filters: {
        search,
        limit,
        offset,
      },
      list,
      count,
      isMobile,
    } = this.props;
    const {
      totalPages,
      activePage,
    } = preparePagination(limit, offset, count);
    return (
      <Grid.Row className={`list-panel-history${isMobile ? ' mobile' : ''}`}>
        <Table compact unstackable color='orange'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan='8'>
                <Search
                  value={search || ''}
                  isFetching={isFetching}
                  onSearchChange={this.handleSearchChange}
                />
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>
                Number
              </Table.HeaderCell>
              <Table.HeaderCell>
                User
              </Table.HeaderCell>
              <Table.HeaderCell>
                User role
              </Table.HeaderCell>
              <Table.HeaderCell>
                Date
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {map(list, (item, index) => {
              return (
                <Table.Row textAlign='center' key={item.id}>
                  <Table.Cell data-label='Number'>{index + 1}</Table.Cell>
                  <Table.Cell data-label='Customer'>{item.user.username}</Table.Cell>
                  <Table.Cell data-label='Contractor'>{item.user.user_role}</Table.Cell>
                  <Table.Cell data-label='Date'>{moment(item.login_datetime).format(DATE_FORMAT_HISTORY)}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
          {totalPages > 1 && (
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan='12'>
                  <Pagination
                    handlePaginationChange={this.handlePaginationChange}
                    activePage={activePage}
                    totalPages={totalPages}
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          )}
        </Table>
      </Grid.Row>
    );
  }
}

ListPanel.propTypes = propTypes;
export default ListPanel;
