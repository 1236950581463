import {
  connect,
} from 'react-redux';
import {
 removeNotification,
} from "../../app/module/actions";
import Notificatons from './Notifications';

const mapStateToProps = (state) => ({
  isMobile: state.app.isMobile,
  notifications: state.app.notifications,
});

const mapDispatchToProps = {
  removeNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notificatons);
