import constants from './constants';
import Http, {
  getQuery,
} from '../../utils';

export const getInvoices = (query) => dispatch => {
  dispatch({
    type: constants.GET_INVOICES_REQUEST,
  });
  return Http.get(`/invoice/${getQuery(query)}`)
  .then(
    res => dispatch({
      type: constants.GET_INVOICES_SUCCESS,
      payload: res,
    }),
    err => dispatch({
      type: constants.GET_INVOICES_FAILURE,
      payload: err,
    })
  );
};

export const getInvoiceById = (id) => dispatch => {
  dispatch({
    type: constants.GET_INVOICE_BY_ID_REQUEST,
  });
  return Http.get(`/invoice/${id}/`)
  .then(
    res => dispatch({
      type: constants.GET_INVOICE_BY_ID_SUCCESS,
      payload: res,
    }),
    err => dispatch({
      type: constants.GET_INVOICE_BY_ID_FAILURE,
      payload: err,
    })
  );
};

export const createInvoice = (data) => dispatch => {
  dispatch({
    type: constants.CREATE_INVOICES_REQUEST,
  });
  return Http.post('/invoice/', data)
  .then(
    res => dispatch({
      type: constants.CREATE_INVOICES_SUCCESS,
      payload: res,
    }),
    err => dispatch({
      type: constants.CREATE_INVOICES_FAILURE,
      payload: err,
    })
  );
};

export const editInvoice = (data, id) => dispatch => {
  dispatch({
    type: constants.EDIT_INVOICES_REQUEST,
  });
  return Http.put(`/invoice/${id}/`, data)
  .then(
    res => dispatch({
      type: constants.EDIT_INVOICES_SUCCESS,
      payload: res,
    }),
    err => dispatch({
      type: constants.EDIT_INVOICES_FAILURE,
      payload: err,
    })
  );
};


export const removeInvoice = (id) => dispatch => {
  dispatch({
    type: constants.REMOVE_INVOICES_REQUEST,
  });
  return Http.delete(`/invoice/${id}/`)
  .then(
    res => dispatch({
      type: constants.REMOVE_INVOICES_SUCCESS,
      payload: res,
    }),
    err => dispatch({
      type: constants.REMOVE_INVOICES_FAILURE,
      payload: err,
    })
  );
};

export const setInvoiceFilters = filters => dispatch => dispatch({
  type: constants.SET_FILTERS,
  payload: filters,
});

export const setInvoice = (invoice = {}) => dispatch => dispatch({
  type: constants.SET_INVOICE,
  payload: invoice,
});

export const setIsCopy = (status) => dispatch => dispatch({
  type: constants.SET_IS_COPY,
  payload: status,
});
