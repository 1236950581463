import React from 'react';
import PropTypes from 'prop-types';
import {
  Segment,
  Grid,
  Loader,
} from 'semantic-ui-react';
import CurrencyItem from './currencyItem';
import './style.scss';

const propTypes = {
  isFetching: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  currencyList: PropTypes.instanceOf(Array).isRequired,
};

const TotalPanel = ({
                      isFetching,
                      currencyList,
                      isMobile,
                    }) => {
  return (
    <Grid.Row className={`reports-total-panel${isMobile ? ' mobile' : ''}`}>
      <Segment>
        {isFetching ? <Loader active inline='centered' /> : (
          currencyList.map((currency) => {
            return (
              <CurrencyItem
                key={currency.currency_name}
                name={currency.currency_name}
                total={currency.total_sum}
                paid={currency.paid_sum}
                invoicesLength={currency.invoice_length}
                unpaid={currency.unpaid_sum}
                overpaid={currency.overpaid_sum}
              />
            );
          })
        )}
      </Segment>
    </Grid.Row>
  );
};

TotalPanel.propTypes = propTypes;
export default TotalPanel;
