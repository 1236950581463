import {
  get,
} from 'lodash';
import constants from './constants';

export const initialState = {
  account: null,
  isFetching: false,
  error: null,
};

export const actionHandlers = {
  [constants.GET_PROFILE_REQUEST]: (state) => ({
    ...state,
    isFetching: true,
    error: null,
  }),
  [constants.GET_PROFILE_SUCCESS]: (state, action) => ({
    ...state,
    isFetching: false,
    account: get(action, 'payload.data', null),
  }),
  [constants.GET_PROFILE_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload,
  }),
};

export const reducer = (state = initialState, action) => {
  const handler = actionHandlers[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
