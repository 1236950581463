import {
  get,
} from 'lodash';
import constants from './constants';

export const initFilters = {
  order_by: 'date',
  order: 'desc',
  limit: 10,
  offset: 0,
};

export const initialState = {
  invoiceList: [],
  count: 0,
  invoice: {},
  filters: initFilters,
  isFetching: false,
  isCopy: false,
  error: {},
};

export const actionHandlers = {
  [constants.SET_IS_COPY]: (state, action) => ({
    ...state,
    isCopy: action.payload,
  }),

  [constants.SET_FILTERS]: (state, action) => ({
    ...state,
    filters: action.payload,
  }),

  [constants.SET_INVOICE]: (state, action) => ({
    ...state,
    invoice: action.invoice,
  }),

  [constants.GET_INVOICES_REQUEST]: (state) => ({
    ...state,
    isFetching: true,
    error: null,
  }),
  [constants.GET_INVOICES_SUCCESS]: (state, action) => ({
    ...state,
    isFetching: false,
    invoiceList: get(action, 'payload.data.data', []),
    count: get(action, 'payload.data.counter', 0),
  }),
  [constants.GET_INVOICES_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload,
  }),

  [constants.GET_INVOICE_BY_ID_REQUEST]: (state) => ({
    ...state,
    isFetching: true,
    error: null,
  }),
  [constants.GET_INVOICE_BY_ID_SUCCESS]: (state, action) => ({
    ...state,
    isFetching: false,
    invoice: get(action, 'payload.data', {}),
  }),
  [constants.GET_INVOICE_BY_ID_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload,
  }),

  [constants.CREATE_INVOICES_REQUEST]: (state) => ({
    ...state,
    isFetching: true,
    error: null,
  }),
  [constants.CREATE_INVOICES_SUCCESS]: (state, action) => ({
    ...state,
    isFetching: false,
    invoice: get(action, 'payload.data', {}),
  }),
  [constants.CREATE_INVOICES_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload,
  }),

  [constants.EDIT_INVOICES_REQUEST]: (state) => ({
    ...state,
    isFetching: true,
    error: null,
  }),
  [constants.EDIT_INVOICES_SUCCESS]: (state, action) => ({
    ...state,
    isFetching: false,
    invoice: get(action, 'payload.data', {}),
  }),
  [constants.EDIT_INVOICES_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload,
  }),

  [constants.REMOVE_INVOICES_REQUEST]: (state) => ({
    ...state,
    isFetching: true,
    error: null,
  }),
  [constants.REMOVE_INVOICES_SUCCESS]: (state) => ({
    ...state,
    isFetching: false,
  }),
  [constants.REMOVE_INVOICES_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload,
  }),
};

const reducers = (state = initialState, action) => {
  const handler = actionHandlers[action.type];
  return handler ? handler(state, action) : state;
};

export default reducers;
