import React from 'react';
import {
  createStore,
  applyMiddleware,
  compose,
} from 'redux';
import {
  Provider,
} from 'react-redux';
import {
  routerMiddleware,
} from 'react-router-redux';
import thunkMiddleware from 'redux-thunk';
import {
  createBrowserHistory,
} from 'history';
import {
  Router,
} from 'react-router-dom';
import reducer from './reducer';
import App from './app';

export const history = createBrowserHistory();
const initialState = {};
const enhancers = [];
const middleware = [
    thunkMiddleware,
    routerMiddleware(history),
];

if (process.env.NODE_ENV !== 'production') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
  if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension());
    }
}

const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
);

export const store = createStore(
    reducer,
    initialState,
    composedEnhancers
);

export const Root = () => (
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>
);
