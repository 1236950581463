export const key = 'reports';
export const consts = {
  SET_FILTERS: `${key}/SET_FILTERS`,

  GET_REPORTS_REQUEST: `${key}/GET_REPORTS_REQUEST`,
  GET_REPORTS_SUCCESS: `${key}/GET_REPORTS_SUCCESS`,
  GET_REPORTS_FAILURE: `${key}/GET_REPORTS_FAILURE`,

};

export default consts;
