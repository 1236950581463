export const key = 'invoices';
export const consts = {
  SET_IS_COPY: `${key}/SET_IS_COPY`,

  SET_FILTERS: `${key}/SET_FILTERS`,

  SET_INVOICE: `${key}/SET_INVOICE`,

  GET_INVOICES_REQUEST: `${key}/GET_INVOICES_REQUEST`,
  GET_INVOICES_SUCCESS: `${key}/GET_INVOICES_SUCCESS`,
  GET_INVOICES_FAILURE: `${key}/GET_INVOICES_FAILURE`,

  GET_INVOICE_BY_ID_REQUEST: `${key}/GET_INVOICE_BY_ID_REQUEST`,
  GET_INVOICE_BY_ID_SUCCESS: `${key}/GET_INVOICE_BY_ID_SUCCESS`,
  GET_INVOICE_BY_ID_FAILURE: `${key}/GET_INVOICE_BY_ID_FAILURE`,

  CREATE_INVOICES_REQUEST: `${key}/CREATE_INVOICES_REQUEST`,
  CREATE_INVOICES_SUCCESS: `${key}/CREATE_INVOICES_SUCCESS`,
  CREATE_INVOICES_FAILURE: `${key}/CREATE_INVOICES_FAILURE`,

  EDIT_INVOICES_REQUEST: `${key}/EDIT_INVOICES_REQUEST`,
  EDIT_INVOICES_SUCCESS: `${key}/EDIT_INVOICES_SUCCESS`,
  EDIT_INVOICES_FAILURE: `${key}/EDIT_INVOICES_FAILURE`,

  REMOVE_INVOICES_REQUEST: `${key}/REMOVE_INVOICES_REQUEST`,
  REMOVE_INVOICES_SUCCESS: `${key}/REMOVE_INVOICES_SUCCESS`,
  REMOVE_INVOICES_FAILURE: `${key}/REMOVE_INVOICES_FAILURE`,
};

export default consts;
