import {
 connect, 
} from 'react-redux';
import Projects from './Projects';
import {
 key, 
} from '../module/constants';
import {
  getAllProjects as getAll,
  createProjects as create,
  editProjects as edit,
  removeProjects as remove,

  getAllCustomers,
} from '../module/actions';

const mapStateToProps = (state) => ({
  list: state[key].projects,
  count: state[key].projectsCount,
  customersList: state[key].customers,
  isFetching: state[key].isFetching,
  isMobile: state.app.isMobile,
});

const mapDispatchToProps = {
  getAll,
  create,
  edit,
  remove,
  getAllCustomers,
};

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
