import React, {
  // Suspense,
  // lazy,
} from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';
// import PageLoader from '../ui_parts/PageLoader';
import Users from './users';
import Customers from './customers';
import Projects from './projects';
import BankAcc from './bankAccounts';
import Contractors from './contractors';
import Currency from './currency';
import PaymentMethods from './paymentMethod';
//TODO: fix react lazy in future
//now we have random bug with duplicate render components, witch added with lazy function

// const Users = lazy(() => import('./users'));
// const Customers = lazy(() => import('./customers'));
// const Projects = lazy(() => import('./projects'));
// const BankAcc = lazy(() => import('./bankAccounts'));
// const Contractors = lazy(() => import('./contractors'));
// const Currency = lazy(() => import('./currency'));
// const PaymentMethods = lazy(() => import('./paymentMethod'));


const ManagementRoutes = () => {
  return (
    /* <Suspense
        fallback={<PageLoader />}
      >
        <Switch>
          <Route
            exact
            path='/management/users'
            component={() => <Users />}
          />
          <Route
            exact
            path='/management/customers'
            component={() => <Customers />}
          />
          <Route
            exact
            path='/management/projects'
            component={() => <Projects />}
          />
          <Route
            exact
            path='/management/accounts'
            component={() => <BankAcc />}
          />
          <Route
            exact
            path='/management/contractors'
            component={() => <Contractors />}
          />
          <Route
            exact
            path='/management/currency'
            component={() => <Currency />}
          />
          <Route
            exact
            path='/management/payments'
            component={() => <PaymentMethods />}
          />
        </Switch>
      </Suspense>*/
    <Switch>
      <Route
        exact
        path='/management/users'
        component={Users}
      />
      <Route
        exact
        path='/management/customers'
        component={Customers}
      />
      <Route
        exact
        path='/management/projects'
        component={Projects}
      />
      <Route
        exact
        path='/management/accounts'
        component={BankAcc}
      />
      <Route
        exact
        path='/management/contractors'
        component={Contractors}
      />
      <Route
        exact
        path='/management/currency'
        component={Currency}
      />
      <Route
        exact
        path='/management/payments'
        component={PaymentMethods}
      />
    </Switch>
  );
};

export default ManagementRoutes;
