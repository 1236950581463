import React, {
  Component,
} from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Grid,
} from 'semantic-ui-react';
import {
  Route,
  Switch,
} from 'react-router-dom';
import './style.scss';
import ListPanel from './listPanel';

const propTypes = {
  isMobile: PropTypes.bool.isRequired,
};

class History extends Component {

  prepareMainContainer = () => {
    const {
      isMobile,
    } = this.props;
    return (
      <Container className={`main${isMobile ? ' mobile' : ''}`}>
        <Grid padded>
          <ListPanel />
        </Grid>
      </Container>
    );
  };

  render() {
    return (
      <Switch>
        <Route
          exact
          path='/history'
          component={this.prepareMainContainer}
        />
      </Switch>
    );
  }
}

History.propTypes = propTypes;
export default History;
