import React from 'react';
import {
  Button,
  Icon,
  Form, Label,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {
  convertArrayToObjectForSelects,
} from '../../../../utils';
import '../../style.scss';

const propTypes = {
  handleRemoveServiceInfoComment: PropTypes.func.isRequired,
  serviceInfoItemId: PropTypes.number.isRequired,
  department: PropTypes.instanceOf(Object).isRequired,
  departmentItems: PropTypes.instanceOf(Array).isRequired,
  handleCommentChange: PropTypes.func.isRequired,
  commentsLength: PropTypes.number.isRequired,
  currency : PropTypes.string.isRequired,
};
// eslint-disable-next-line react/prefer-stateless-function
class ServiceInfoComment extends React.Component {

  render() {
    const {
      handleRemoveServiceInfoComment,
      serviceInfoItemId,
      department,
      departmentItems,
      handleCommentChange,
      commentsLength,
      currency,
    } = this.props;
    return (
      <div>
        <Form.Group widths='equal'>
          <Form.Select
            label='Department'
            options={convertArrayToObjectForSelects(departmentItems)}
            onChange={(e, {
              value,
            }) => handleCommentChange(serviceInfoItemId, department.id, 'department', value)}
            placeholder='Select a department'
            value={department.department}
          />
          <Form.Input
            fluid
            label='Q-ty'
            placeholder='Q-ty'
            type='number'
            step={0.01}
            min={0}
            defaultValue={department.qty}
            // eslint-disable-next-line no-useless-escape
            pattern={'\d*'}
            onChange={(e, {
              value,
            }) => handleCommentChange(serviceInfoItemId, department.id, 'qty', value)}
          />
          <Form.Input
            fluid
            label='Price per hour'
            placeholder='Price per hour'
            type='number'
            step={0.01}
            min={0}
            // eslint-disable-next-line no-useless-escape
            pattern={'\d*'}
            defaultValue={department.price_per_hour}
            onChange={(e, {
              value,
            }) => handleCommentChange(serviceInfoItemId, department.id, 'price_per_hour', value)}
          />
          <Form.Input
            fluid
            required
            label='Amount'
            placeholder='Amount'
            labelPosition={`${currency ? 'right' : 'left'}`}
            type='number'
            step={0.01}
            min={0}
            // eslint-disable-next-line no-useless-escape
            pattern={'\d*'}
            value={department.amount}
            onChange={(e, {
              value,
            }) => handleCommentChange(serviceInfoItemId, department.id, 'amount', value)}
            className='extension-field'
          >
            <input />
            {currency ? <Label basic>{currency}</Label> : null}
          </Form.Input>
          <Button
            className='service-info-comment__height-button'
            type='button'
            icon
            inverted
            color='orange'
            onClick={() => handleRemoveServiceInfoComment(serviceInfoItemId, department.id)}
            disabled={commentsLength < 2}
          >
            <Icon name='trash' />
          </Button>
        </Form.Group>
      </div>
    );
  }
}

ServiceInfoComment.propTypes = propTypes;

export default ServiceInfoComment;
