import constants from './constants';
import Http, {
  getQuery,
} from '../../utils';

export const getReports = (query) => dispatch => {
  dispatch({
    type: constants.GET_REPORTS_REQUEST,
  });
  return Http.get(`/invoice_total/${getQuery(query)}`)
  .then(
    res => dispatch({
      type: constants.GET_REPORTS_SUCCESS,
      payload: res,
    }),
    err => dispatch({
      type: constants.GET_REPORTS_FAILURE,
      payload: err,
    })
  );
};

export const setReportFilters = filters => dispatch => dispatch({
  type: constants.SET_FILTERS,
  payload: filters,
});
