import {
  connect,
} from 'react-redux';
import {
  withRouter,
} from 'react-router';
import History from './History';

const mapStateToProps = (state) => ({
  isMobile: state.app.isMobile,
});

export default withRouter(connect(mapStateToProps)(History));
