export const key = 'management';
export const consts = {
  // ############################# Users #############################
  GET_ALL_USERS_REQUEST: `${key}/GET_ALL_USERS_REQUEST`,
  GET_ALL_USERS_SUCCESS: `${key}/GET_ALL_USERS_SUCCESS`,
  GET_ALL_USERS_FAILURE: `${key}/GET_ALL_USERS_FAILURE`,

  GET_USER_ROLES_REQUEST: `${key}/GET_USER_ROLES_REQUEST`,
  GET_USER_ROLES_SUCCESS: `${key}/GET_USER_ROLES_SUCCESS`,
  GET_USER_ROLES_FAILURE: `${key}/GET_USER_ROLES_FAILURE`,

  CREATE_USER_REQUEST: `${key}/CREATE_USER_REQUEST`,
  CREATE_USER_SUCCESS: `${key}/CREATE_USER_SUCCESS`,
  CREATE_USER_FAILURE: `${key}/CREATE_USER_FAILURE`,

  EDIT_USER_REQUEST: `${key}/EDIT_USER_REQUEST`,
  EDIT_USER_SUCCESS: `${key}/EDIT_USER_SUCCESS`,
  EDIT_USER_FAILURE: `${key}/EDIT_USER_FAILURE`,

  REMOVE_USER_REQUEST: `${key}/REMOVE_USER_REQUEST`,
  REMOVE_USER_SUCCESS: `${key}/REMOVE_USER_SUCCESS`,
  REMOVE_USER_FAILURE: `${key}/REMOVE_USER_FAILURE`,

  // ############################# Customers #############################
  GET_ALL_CUSTOMERS_REQUEST: `${key}/GET_ALL_CUSTOMERS_REQUEST`,
  GET_ALL_CUSTOMERS_SUCCESS: `${key}/GET_ALL_CUSTOMERS_SUCCESS`,
  GET_ALL_CUSTOMERS_FAILURE: `${key}/GET_ALL_CUSTOMERS_FAILURE`,

  CREATE_CUSTOMER_REQUEST: `${key}/CREATE_CUSTOMER_REQUEST`,
  CREATE_CUSTOMER_SUCCESS: `${key}/CREATE_CUSTOMER_SUCCESS`,
  CREATE_CUSTOMER_FAILURE: `${key}/CREATE_CUSTOMER_FAILURE`,

  EDIT_CUSTOMER_REQUEST: `${key}/EDIT_CUSTOMER_REQUEST`,
  EDIT_CUSTOMER_SUCCESS: `${key}/EDIT_CUSTOMER_SUCCESS`,
  EDIT_CUSTOMER_FAILURE: `${key}/EDIT_CUSTOMER_FAILURE`,

  REMOVE_CUSTOMER_REQUEST: `${key}/REMOVE_CUSTOMER_REQUEST`,
  REMOVE_CUSTOMER_SUCCESS: `${key}/REMOVE_CUSTOMER_SUCCESS`,
  REMOVE_CUSTOMER_FAILURE: `${key}/REMOVE_CUSTOMER_FAILURE`,

  // ############################# Projects #############################
  GET_ALL_PROJECTS_REQUEST: `${key}/GET_ALL_PROJECTS_REQUEST`,
  GET_ALL_PROJECTS_SUCCESS: `${key}/GET_ALL_PROJECTS_SUCCESS`,
  GET_ALL_PROJECTS_FAILURE: `${key}/GET_ALL_PROJECTS_FAILURE`,

  CREATE_PROJECT_REQUEST: `${key}/CREATE_PROJECT_REQUEST`,
  CREATE_PROJECT_SUCCESS: `${key}/CREATE_PROJECT_SUCCESS`,
  CREATE_PROJECT_FAILURE: `${key}/CREATE_PROJECT_FAILURE`,

  EDIT_PROJECT_REQUEST: `${key}/EDIT_PROJECT_REQUEST`,
  EDIT_PROJECT_SUCCESS: `${key}/EDIT_PROJECT_SUCCESS`,
  EDIT_PROJECT_FAILURE: `${key}/EDIT_PROJECT_FAILURE`,

  REMOVE_PROJECT_REQUEST: `${key}/REMOVE_PROJECT_REQUEST`,
  REMOVE_PROJECT_SUCCESS: `${key}/REMOVE_PROJECT_SUCCESS`,
  REMOVE_PROJECT_FAILURE: `${key}/REMOVE_PROJECT_FAILURE`,

  // ############################# Bank accounts #############################
  GET_ALL_BANK_ACC_REQUEST: `${key}/GET_ALL_BANK_ACC_REQUEST`,
  GET_ALL_BANK_ACC_SUCCESS: `${key}/GET_ALL_BANK_ACC_SUCCESS`,
  GET_ALL_BANK_ACC_FAILURE: `${key}/GET_ALL_BANK_ACC_FAILURE`,

  GET_AVAILABLE_BANK_ACC_REQUEST: `${key}/GET_AVAILABLE_BANK_ACC_REQUEST`,
  GET_AVAILABLE_BANK_ACC_SUCCESS: `${key}/GET_AVAILABLE_BANK_ACC_SUCCESS`,
  GET_AVAILABLE_BANK_ACC_FAILURE: `${key}/GET_AVAILABLE_BANK_ACC_FAILURE`,

  CREATE_BANK_ACC_REQUEST: `${key}/CREATE_BANK_ACC_REQUEST`,
  CREATE_BANK_ACC_SUCCESS: `${key}/CREATE_BANK_ACC_SUCCESS`,
  CREATE_BANK_ACC_FAILURE: `${key}/CREATE_BANK_ACC_FAILURE`,

  EDIT_BANK_ACC_REQUEST: `${key}/EDIT_BANK_ACC_REQUEST`,
  EDIT_BANK_ACC_SUCCESS: `${key}/EDIT_BANK_ACC_SUCCESS`,
  EDIT_BANK_ACC_FAILURE: `${key}/EDIT_BANK_ACC_FAILURE`,

  REMOVE_BANK_ACC_REQUEST: `${key}/REMOVE_BANK_ACC_REQUEST`,
  REMOVE_BANK_ACC_SUCCESS: `${key}/REMOVE_BANK_ACC_SUCCESS`,
  REMOVE_BANK_ACC_FAILURE: `${key}/REMOVE_BANK_ACC_FAILURE`,

  // ############################# Contractors #############################
  GET_ALL_CONTRACTORS_REQUEST: `${key}/GET_ALL_CONTRACTORS_REQUEST`,
  GET_ALL_CONTRACTORS_SUCCESS: `${key}/GET_ALL_CONTRACTORS_SUCCESS`,
  GET_ALL_CONTRACTORS_FAILURE: `${key}/GET_ALL_CONTRACTORS_FAILURE`,

  CREATE_CONTRACTORS_REQUEST: `${key}/CREATE_CONTRACTORS_REQUEST`,
  CREATE_CONTRACTORS_SUCCESS: `${key}/CREATE_CONTRACTORS_SUCCESS`,
  CREATE_CONTRACTORS_FAILURE: `${key}/CREATE_CONTRACTORS_FAILURE`,

  EDIT_CONTRACTORS_REQUEST: `${key}/EDIT_CONTRACTORS_REQUEST`,
  EDIT_CONTRACTORS_SUCCESS: `${key}/EDIT_CONTRACTORS_SUCCESS`,
  EDIT_CONTRACTORS_FAILURE: `${key}/EDIT_CONTRACTORS_FAILURE`,

  REMOVE_CONTRACTORS_REQUEST: `${key}/REMOVE_CONTRACTORS_REQUEST`,
  REMOVE_CONTRACTORS_SUCCESS: `${key}/REMOVE_CONTRACTORS_SUCCESS`,
  REMOVE_CONTRACTORS_FAILURE: `${key}/REMOVE_CONTRACTORS_FAILURE`,

  // ############################# Currency #############################
  GET_ALL_CURRENCY_REQUEST: `${key}/GET_ALL_CURRENCY_REQUEST`,
  GET_ALL_CURRENCY_SUCCESS: `${key}/GET_ALL_CURRENCY_SUCCESS`,
  GET_ALL_CURRENCY_FAILURE: `${key}/GET_ALL_CURRENCY_FAILURE`,

  CREATE_CURRENCY_REQUEST: `${key}/CREATE_CURRENCY_REQUEST`,
  CREATE_CURRENCY_SUCCESS: `${key}/CREATE_CURRENCY_SUCCESS`,
  CREATE_CURRENCY_FAILURE: `${key}/CREATE_CURRENCY_FAILURE`,

  EDIT_CURRENCY_REQUEST: `${key}/EDIT_CURRENCY_REQUEST`,
  EDIT_CURRENCY_SUCCESS: `${key}/EDIT_CURRENCY_SUCCESS`,
  EDIT_CURRENCY_FAILURE: `${key}/EDIT_CURRENCY_FAILURE`,

  REMOVE_CURRENCY_REQUEST: `${key}/REMOVE_CURRENCY_REQUEST`,
  REMOVE_CURRENCY_SUCCESS: `${key}/REMOVE_CURRENCY_SUCCESS`,
  REMOVE_CURRENCY_FAILURE: `${key}/REMOVE_CURRENCY_FAILURE`,

  // ############################# Pay Methods #############################
  GET_ALL_PAY_METHOD_REQUEST: `${key}/GET_ALL_PAY_METHOD_REQUEST`,
  GET_ALL_PAY_METHOD_SUCCESS: `${key}/GET_ALL_PAY_METHOD_SUCCESS`,
  GET_ALL_PAY_METHOD_FAILURE: `${key}/GET_ALL_PAY_METHOD_FAILURE`,

  CREATE_PAY_METHOD_REQUEST: `${key}/CREATE_PAY_METHOD_REQUEST`,
  CREATE_PAY_METHOD_SUCCESS: `${key}/CREATE_PAY_METHOD_SUCCESS`,
  CREATE_PAY_METHOD_FAILURE: `${key}/CREATE_PAY_METHOD_FAILURE`,

  EDIT_PAY_METHOD_REQUEST: `${key}/EDIT_PAY_METHOD_REQUEST`,
  EDIT_PAY_METHOD_SUCCESS: `${key}/EDIT_PAY_METHOD_SUCCESS`,
  EDIT_PAY_METHOD_FAILURE: `${key}/EDIT_PAY_METHOD_FAILURE`,

  REMOVE_PAY_METHOD_REQUEST: `${key}/REMOVE_PAY_METHOD_REQUEST`,
  REMOVE_PAY_METHOD_SUCCESS: `${key}/REMOVE_PAY_METHOD_SUCCESS`,
  REMOVE_PAY_METHOD_FAILURE: `${key}/REMOVE_PAY_METHOD_FAILURE`,
};

export default consts;
