/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */

import React, {
  Component,
} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  Button,
  Form,
  Container,
  Segment,
  Label,
  Input,
  Message,
} from 'semantic-ui-react';
import includes from 'lodash/includes';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import './style.scss';
import PreviewModal from './Modal';
import Confirm from '../../ui_parts/Confirm';
import ServiceInfo from './ServiceInfo';
import {
  getDataForDropDowns,
  addDataForDropDowns,
  prepareQuery,
  convertObjectsToId,
  getDataForInvoice,
  isFormValid,
  sortAlphabetical,
} from '../../utils';
import {
  INPUTS_VALIDATION_PATTERNS,
  DATA_LIMIT,
} from '../../constants';
import PermissionWrapper, {
  roles,
  //TODO: rename permissionBool method to more useful name
  permissionBool,
} from '../../ui_parts/PermissionWrapper';

export const bankAccParser = ({
                                // id,
                                account,
                                currency,
                                payment_method,
                              }) =>
  `${account} (${get(payment_method, 'name', '')} - ${get(currency, 'name', '')})`;

//now total value getting from server
// const calcTotal = (description = []) => {
//   description.reduce((prev, next) => (prev + parseInt(next.amount, 10)), 0).toFixed(0);
// };

const getCurrency = (bankAcc = 0, list = []) => {
  return (typeof bankAcc === 'number' ? list.find(e => e.id === bankAcc) : bankAcc);
};

const REPORTS_URL = '/reports';

const propTypes = {
  getById: PropTypes.func.isRequired,
  getAllCustomers: PropTypes.func.isRequired,
  getAllContractors: PropTypes.func.isRequired,
  set: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isManagementListsFetching: PropTypes.bool.isRequired,
  invoice: PropTypes.instanceOf(Object),
  isCopy: PropTypes.bool.isRequired,
  customersList: PropTypes.instanceOf(Array).isRequired,
  contractorsList: PropTypes.instanceOf(Array).isRequired,
  projectsList: PropTypes.instanceOf(Array).isRequired,
  bankAccountList: PropTypes.instanceOf(Array).isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  create: PropTypes.instanceOf(Object).isRequired,
  edit: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object),
  setIsCopy: PropTypes.func.isRequired,
};

const defaultProps = {
  history: {},
  invoice: {},
};

const invoiceSumInfo = {
  qty: 'qty',
  amount: 'amount',
  pricePerHour: 'price_per_hour',
  paid: 'payed_part',
};
const hiddenCommentInfo = {
  qty: 'qty',
  amount: 'amount',
  pricePerHour: 'price_per_hour',
  department: 'department',
};

const FieldsForFilteringEnum = {
  project: 'project',
  bank: 'bank_account',
};

class Bill extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      item: {
        date: moment().format('YYYY-MM-DD'),
        // is_payed: false,
        till_date: moment().add(1, 'months').format('YYYY-MM-DD'),
        invoice_state: 'unpaid',
        description: [
          {
            id: 0,
            description: '',
            note: '',
            is_new: true,
            hidden_comment: [{
              qty: '',
              amount: '',
              price_per_hour: '',
              department: '',
              is_new: true,
            }],
          },
        ],
      },
      removingServiceInfoId: null,
      errors: [],
      isServiceInfoConfirmOpen: false,
    };
    this.fetchData = debounce((fn, param) => fn(param), 500, {
      leading: true,
    });
  }

  componentDidMount() {
    const {
      match: {
        params: {
          id,
        },
      },
      getById,
      getAllCustomers,
      getAllContractors,
      isCopy,
    } = this.props;

    if (id !== 'new') {
      getById(id);
    }
    getAllCustomers();
    getAllContractors();
    setTimeout(() => {
      if (isCopy) {
        this.handleChange('payed_part', '0.00');
        this.handleChange('invoice_state', 'unpaid');
      }
    }, 1000);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      invoice,
    } = nextProps;
    const {
      item,
    } = prevState;
    if (invoice.id !== item.id) {
      return {
        ...prevState,
        item: {
          ...invoice,
          date: moment(invoice.date).format('YYYY-MM-DD'),
          till_date: moment(invoice.till_date).format('YYYY-MM-DD'),
        },
      };
    }
    return null;
  }


  componentWillUnmount() {
    const {
      set,
    } = this.props;
    set();
  }

  handleSearch = (fnName, query) => {
    const params = prepareQuery(query);
    // eslint-disable-next-line react/destructuring-assignment
    this.fetchData(this.props[fnName], params);
  };

  handleChange = (name, val, name2, val2) => {
    const {
      item,
    } = this.state;
    this.isInvoiceEdited = true;
    const currentItem = item;
    currentItem[name] = val;
    if (name === invoiceSumInfo.paid) {
      currentItem[name] = parseFloat(val).toFixed(2);
    }
    if (name2) {
      currentItem[name2] = val2;
    }
    this.setState({
      errors: [],
      item: currentItem,
    });
  };

  handleServicesChange = (id, name, val) => {
    this.isInvoiceEdited = true;
    const {
      item,
    } = this.state;
    let elIndex;
    //eslint-disable-next-line array-callback-return
    const services = item.description.find((el, i) => {
      if (el.id === id) {
        elIndex = i;
        return true;
      }
    });
    if (name === invoiceSumInfo.pricePerHour || name === invoiceSumInfo.amount || name === invoiceSumInfo.qty) {
      val = parseFloat(val).toFixed(2);
    }
    if (name === invoiceSumInfo.qty || name === invoiceSumInfo.pricePerHour) {
      if ((name === invoiceSumInfo.qty && (val === '' || services.price_per_hour === '')) ||
        (name === invoiceSumInfo.pricePerHour && (val === '' || services.qty === ''))
      ) {
        services.amount = '0.00';
      } else {
        services.amount = name === invoiceSumInfo.qty ?
          (parseFloat(services.price_per_hour) * parseFloat(val)).toFixed(2) :
          (parseFloat(services.qty) * parseFloat(val)).toFixed(2);
      }
    }
    services[name] = val;
    item.description[elIndex] = services;
    const newItem = {
      ...item,
      description: item.description,
    };
    this.setState({
      errors: [],
      item: newItem,
    });
  };

  handleAddServiceInfo = () => {
    this.isInvoiceEdited = true;
    const {
      item,
    } = this.state;
    const newServices = item.description;
    newServices.push({
      id: Date.now(),
      description: '',
      hidden_comment: [{
        qty: '',
        amount: '',
        price_per_hour: '',
        department: '',
        is_new: true,
      }],
      note: '',
      is_new: true,
    });
    const newItem = {
      ...item,
      description: newServices,
    };
    this.setState({
      item: newItem,
    });
  };

  handleRemoveServiceInfo = () => {
    this.isInvoiceEdited = true;
    const {
      item,
      removingServiceInfoId,
    } = this.state;
    const newServices = item.description.filter(s => s.id !== removingServiceInfoId);
    const newItem = {
      ...item,
      description: newServices,
    };
    this.setState({
      item: newItem,
      isServiceInfoConfirmOpen: false,
    });
  };

  onRemoveSingleService = (removingServiceInfoId) => {
    return this.handleServiceInfoConfirmChange(removingServiceInfoId);
  };

  onCloseConfirm = () => {
    return this.handleServiceInfoConfirmChange();
  };

  onConfirmSuccess = () => {
    this.handleRemoveServiceInfo();
  };

  handleServiceInfoConfirmChange = (removingServiceInfoId) => {
    const {
      isServiceInfoConfirmOpen,
    } = this.state;
    return this.setState({
      isServiceInfoConfirmOpen: !isServiceInfoConfirmOpen,
      removingServiceInfoId,
    });
  };

  handleAddServiceInfoComment = (serviceInfoId) => {
    this.isInvoiceEdited = true;
    const {
      item,
    } = this.state;

    const newServices = item.description;
    const neededService = newServices.find((service) => service.id === serviceInfoId);


    neededService.hidden_comment.push({
      id: Date.now(),
      department: '',
      qty: 'text',
      price_per_hour: null,
      amount: '',
      is_new: true,

    });


    const newItem = {
      ...item,
      description: newServices,
    };
    this.setState({
      item: newItem,
    });
  };
  handleRemoveServiceInfoComment = (serviceInfoId, removingServiceInfoCommentId) => {
    this.isInvoiceEdited = true;
    const {
      item,
    } = this.state;

    const newServices = item.description;
    const neededService = newServices.find((service) => service.id === serviceInfoId);
    // neededService without removed comment
    neededService.hidden_comment = neededService.hidden_comment.filter((comment) =>
      comment.id !== removingServiceInfoCommentId);
    const newItem = {
      ...item,
      description: newServices.map((service) => (service.id === neededService.id ? neededService : service)),
    };
    this.setState({
      item: newItem,
      isServiceInfoConfirmOpen: false,
    });
  };

  handleCommentChange = (idServiceInfo, idHiddenComment, name, val) => {
    this.isInvoiceEdited = true;
    const {
      item,
    } = this.state;

    let elIndex;
    let commentIndex;

    const services = item.description.find((el, i) => {
      if (el.id === idServiceInfo) {
        elIndex = i;
        return true;
      }
    });

    const hiddenComment = services.hidden_comment.find((comment, i) => {
      if (comment.id === idHiddenComment) {
        commentIndex = i;
        return true;
      }
    });

    if (name === hiddenCommentInfo.pricePerHour ||
      name === hiddenCommentInfo.amount ||
      name === hiddenCommentInfo.qty
    ) {
      val = parseFloat(val).toFixed(2);
    }
    if (name === hiddenCommentInfo.qty ||
      name === hiddenCommentInfo.pricePerHour
    ) {
      if (
        (
          name === hiddenCommentInfo.qty &&
          (val === '' || hiddenComment.price_per_hour === '')
        ) ||
        (
          name === hiddenCommentInfo.pricePerHour &&
          (val === '' || hiddenComment.qty === '')
        )
      ) {
        hiddenComment.amount = '0.00';
      } else {
        hiddenComment.amount = name === hiddenCommentInfo.qty ?
          (parseFloat(hiddenComment.price_per_hour) * parseFloat(val)).toFixed(2) :
          (parseFloat(hiddenComment.qty) * parseFloat(val)).toFixed(2);
      }
    }
    hiddenComment[name] = val;
    item.description[elIndex].hidden_comment[commentIndex] = hiddenComment;
    const newItem = {
      ...item,
      description: item.description,
    };
    this.setState({
      errors: [],
      item: newItem,
    });
  };

  submit = () => {
    const {
      create,
      edit,
      invoice,
      isCopy,
      setIsCopy,
      history: {
        push,
      },
      getById,
    } = this.props;
    const {
      item,
      errors,
    } = this.state;
    let mergedItem = {
      ...invoice,
      ...item,
    };
    mergedItem = {
      ...mergedItem,
      description: mergedItem.description.map((elem) => {
        if (elem.is_new) {
          elem = omit(elem, ['id', 'is_new']);
          return elem;
        }
        return elem;
      }),
    };

    mergedItem = {
      ...mergedItem,
      description: mergedItem.description.map(serviceInfo => {
        const hidden_comment = serviceInfo.hidden_comment.map((item) =>
          (
            item.is_new
              ? omit(item, ['id', 'is_new'])
              : item
          )
        );
        return {
          ...serviceInfo,
          hidden_comment,
        };
      }),
    };

    const newItem = convertObjectsToId({
      ...mergedItem,
      date: moment(mergedItem.date, 'YYYY-MM-DD').startOf('day').format(),
      till_date: moment(mergedItem.till_date, 'YYYY-MM-DD').endOf('day').format(),
    }, ['customer', 'project', 'contractor', 'bank_account']);
    if (item.till_date < item.date) {
      return this.setState({
        errors: [
          ...errors,
          'date',
        ],
      });
    }
    if (isEmpty(item.date) || isEmpty(item.till_date)) {
      return this.setState({
        errors: [
          ...errors,
          'is required',
        ],
      });
    }
    const fieldsForValidation = [
      'number',
      'customer',
      'project',
      'contractor',
      'bank_account',
    ];
    const requiredFieldErrors = isFormValid(newItem, fieldsForValidation);
    if (requiredFieldErrors.length) {
      return this.setState({
        errors: [
          ...errors,
          requiredFieldErrors,
        ],
      });
    }
    // const total = calcTotal(newItem.description);
    // newItem.is_payed = newItem.payed_part >= invoice.total;
    let fn = edit;
    if (!invoice.id) {
      fn = create;
    } else if (isCopy) {
      fn = create;
      delete newItem.id;
      delete newItem.total;
      delete newItem.change_history;
      delete newItem.created_user;
      delete newItem.updated_user;
      newItem.description.forEach(item => delete item.id);
    }
    fn(newItem, invoice.id).then(() => {
      this.isInvoiceEdited = false;
      return this.setState({
        errors: [],
      }, () => {
        if (!invoice.id || isCopy) {
          setIsCopy(false);
          push(`/main/${get(this.props, 'invoice.id', 'new')}`);
        } else {
          getById(invoice.id);
        }
      });

    });
  };

  close = () => {
    const {
      setIsCopy,
      history: {
        push,
        location,
      },
    } = this.props;
    setIsCopy(false);
    let redirectUrl = '/main';
    if (includes(location.pathname, REPORTS_URL)) {
      redirectUrl = REPORTS_URL;
    }
    push(redirectUrl);
  };

  handleCloseModal = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  handleFilter = (list, id, name, additionalList) => {
    let idToFilter = id;

    if (typeof id === 'object') {
      idToFilter = id.id;
    }

    if (name === FieldsForFilteringEnum.project) {
      return list.filter(item => item.customer.id === idToFilter);
    }
    if (name === FieldsForFilteringEnum.bank) {
      const neededContractor = list.find(item => item.id === idToFilter);
      if (neededContractor !== undefined && neededContractor.bank_account !== undefined) {
        return [...neededContractor.bank_account];
      } else {
        return additionalList.filter(item => item.id === idToFilter);
      }
    }
    return [...list];
  };

  render() {
    const {
      item,
      isModalOpen,
      errors,
      isServiceInfoConfirmOpen,
    } = this.state;
    const {
      invoice,
      isFetching,
      customersList,
      contractorsList,
      projectsList,
      bankAccountList,
      isCopy,
      isMobile,
      isManagementListsFetching,
    } = this.props;
    const newInvoice = {
      ...invoice,
      ...item,
      total: invoice.total,
    };
    const {
      id,
      date,
      till_date,
      customer,
      project,
      contractor,
      bank_account,
      payed_part,
      description,
      number,
      note,
      invoice_state,
      total,
    } = newInvoice;
    const currency = get(getCurrency(bank_account, bankAccountList), 'currency.name', '');
    // const open = permissionBool([roles.ACCOUNTANT]) ? true : isModalOpen;

    const paidInvoiceStatusOptions = [
      {
        text: 'Unpaid',
        value: 'unpaid',
      },
      {
        text: 'Paid',
        value: 'paid',
      },
      {
        text: 'Partially paid',
        value: 'partially paid',
      },
      {
        text: 'Write off',
        value: 'write off',
      },
    ];
    // const total = calcTotal(description);

    let errorMessage;
    if (errors.length) {
      errorMessage = errors.some((elem) => elem === 'date') ?
        (
          <Message
            error
          >
            «Date» should be less, then «Overdue Date»
          </Message>
        ) :
        (
          <Message
            error
          >
            Please, fill all required fields
          </Message>
        )
      ;
    }
    return (
      <Container text className={`invoice${isMobile ? ' mobile' : ''}`}>
        <Form
          loading={isFetching}
          id='the-invoice-form'
          onSubmit={this.submit}
        >
          <h2>{isCopy ? 'Copy invoice' : id ? 'Edit invoice' : 'Create new invoice'}</h2>
          <PermissionWrapper
            availableRoles={[
              roles.ROOT,
              roles.DIRECTOR,
              roles.MANAGER,
              roles.ACCOUNTANT,
            ]}
          >
            <Segment raised>
              <h3>General info</h3>
              <Form.Input
                label='Number'
                autoFocus
                required
                pattern={INPUTS_VALIDATION_PATTERNS.INVOICE}
                value={number || ''}
                placeholder='Number'
                onChange={(e, val) => this.handleChange('number', val.value)}
              />
              <Form.Group widths='equal'>
                <Form.Field required>
                  <label htmlFor='date1'>
                    Date
                  </label>
                  <input
                    id='date1'
                    type='date'
                    value={date}
                    onChange={e => {
                      //eslint-disable-next-line max-len
                      this.handleChange('date', e.target.value);
                    }}
                  />

                </Form.Field>
                <Form.Field required>
                  <label htmlFor='date2'>
                    Overdue Date
                  </label>
                  <input
                    id='date2'
                    type='date'
                    value={till_date}
                    onChange={e => {
                      //eslint-disable-next-line max-len
                      this.handleChange('till_date', e.target.value);
                    }}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Select
                fluid
                required
                search
                label='Customer'
                onChange={(e, val) => this.handleChange('customer', val.value, 'project', null)}
                options={addDataForDropDowns(sortAlphabetical(customersList), customer)}
                placeholder='Select a customer'
                onOpen={() => this.handleSearch('getAllCustomers', {
                  limit: DATA_LIMIT,
                })}
                onSearchChange={(e, data) => this.handleSearch('getAllCustomers', {
                  search: data.searchQuery,
                  limit: DATA_LIMIT,
                })}
                value={getDataForDropDowns(customer)}
              />
              <Form.Select
                fluid
                required
                search
                disabled={!customer || isManagementListsFetching}
                label='Project'
                onChange={(e, val) => this.handleChange('project', val.value)}
                options={addDataForDropDowns(this.handleFilter(projectsList, customer, 'project'), project)}
                placeholder='Select a project'
                onOpen={() => this.handleSearch('getAllProjects', {
                  limit: DATA_LIMIT,
                  customer,
                })}
                onSearchChange={(e, data) => this.handleSearch('getAllProjects', {
                  search: data.searchQuery,
                  limit: DATA_LIMIT,
                  customer,
                })}
                value={getDataForDropDowns(project)}
              />
              <Form.Select
                fluid
                required
                search
                label='Contractors'
                onChange={(e, val) => this.handleChange('contractor', val.value, 'bank_account', null)}
                options={addDataForDropDowns(sortAlphabetical(contractorsList), contractor)}
                placeholder='Select a contractor'
                onOpen={() => this.handleSearch('getAllContractors', {
                  limit: DATA_LIMIT,
                })}
                onSearchChange={(e, data) => this.handleSearch('getAllContractors', {
                  search: data.searchQuery,
                  limit: DATA_LIMIT,
                })}
                value={getDataForDropDowns(contractor)}
              />
              <Form.Select
                fluid
                required
                search
                disabled={!contractor || isManagementListsFetching}
                label='Bank account'
                onChange={(e, val) => this.handleChange('bank_account', val.value)}
                options={addDataForDropDowns(
                  this.handleFilter(contractorsList,
                    contractor,
                    'bank_account',
                    bankAccountList),
                  bank_account, bankAccParser
                )}
                placeholder='Select a bank account'
                onOpen={() => this.handleSearch('getAllBankAcc', {
                  limit: DATA_LIMIT,
                  contractor,
                })}
                onSearchChange={(e, data) => this.handleSearch('getAllBankAcc', {
                  search: data.searchQuery,
                  limit: DATA_LIMIT,
                  contractor,
                })}
                value={getDataForDropDowns(bank_account)}
              />
              <Form.TextArea
                rows={1}
                label='Notes'
                placeholder='Notes'
                value={note || ''}
                onChange={(e, val) => this.handleChange('note', val.value)}
              />
            </Segment>

            {description && description.map((serviceInfoItem) => (
              <ServiceInfo
                key={serviceInfoItem.id}
                serviceInfoItem={serviceInfoItem}
                descriptionLength={item.description.length}
                currency={currency}
                handleServicesChange={this.handleServicesChange}
                onRemoveSingleService={this.onRemoveSingleService}
                handleAddServiceInfoComment={this.handleAddServiceInfoComment}
                handleRemoveServiceInfoComment={this.handleRemoveServiceInfoComment}
                isMobile={isMobile}
                handleCommentChange={this.handleCommentChange}
              />
            ))}
            <Confirm
              open={isServiceInfoConfirmOpen}
              onCancel={this.onCloseConfirm}
              content='Are you sure you want to remove this service info?'
              onConfirm={() => this.onConfirmSuccess()}
            />
          </PermissionWrapper>
          <div className='paid-button-wrapper'>
            <PermissionWrapper
              availableRoles={[
                roles.ROOT,
                roles.DIRECTOR,
                roles.MANAGER,
              ]}
            >
              <Button
                type='button'
                color='blue'
                icon='plus'
                content='Add service info'
                onClick={this.handleAddServiceInfo}
                className='add-service-info-button'
                size={isMobile ? 'massive' : 'medium'}
              />
            </PermissionWrapper>
            <PermissionWrapper
              availableRoles={[
                roles.ROOT,
                roles.ACCOUNTANT,
              ]}
            >
              <Form.Select
                className={classnames(
                  'paid_status',
                  {
                    'green': invoice_state === 'paid',
                  },
                  {
                    'red': invoice_state ? (invoice_state === 'unpaid') : true,
                  },
                  {
                    'orange': invoice_state === 'partially paid',
                  },
                )}
                value={invoice_state || 'unpaid'}
                options={paidInvoiceStatusOptions}
                onChange={(e, val) => this.handleChange('invoice_state', val.value)}
              />
            </PermissionWrapper>
            <Input
              labelPosition='right'
              type='number'
              placeholder='Paid'
              defaultValue={payed_part}
              step={0.01}
              min={0}
              className='paid-input'
              disabled={permissionBool([
                roles.DIRECTOR,
                roles.MANAGER,
              ])}
              onChange={(e, val) => this.handleChange('payed_part', val.value)}
            >
              <Label>{`Total: ${total} / Paid: ${isCopy ? '0.00' : invoice.payed_part || '0.00'}`}</Label>
              <input />
              {currency ? <Label basic>{currency}</Label> : null}
            </Input>
          </div>
          {
            errorMessage
          }
        </Form>
        <div className='control-buttons-wrapper'>
          <Button
            color='black'
            content='Close'
            onClick={this.close}
            size={isMobile ? 'massive' : 'medium'}
          />
          {this.isInvoiceEdited ? (
            <Button
              type='submit'
              form='the-invoice-form'
              color='orange'
              inverted
              icon={isCopy ? 'copy' : 'save'}
              content={isCopy ? 'Save copy' : 'Save'}
              size={isMobile ? 'massive' : 'medium'}
            />
          ) : (
            <Button
              color='orange'
              inverted
              icon='eye'
              content='Preview'
              size={isMobile ? 'massive' : 'medium'}
              onClick={() => this.setState({
                isModalOpen: true,
              })}
            />
          )}
        </div>
        <PreviewModal
          bankAcc={get(getDataForInvoice(bank_account, bankAccountList), 'description', '')}
          bankInfo={get(getDataForInvoice(bank_account, bankAccountList), 'account', '')}
          paymentMethod={get(getDataForInvoice(bank_account, bankAccountList), 'payment_method.name', '')}
          total={total}
          currency={currency}
          contractor={get(getDataForInvoice(contractor, contractorsList), 'name', '')}
          contractorsAddress={get(getDataForInvoice(contractor, contractorsList), 'address', '')}
          customer={get(getDataForInvoice(customer, customersList), 'name', '')}
          customersAddress={get(getDataForInvoice(customer, customersList), 'address', '')}
          date={moment(date).format('MMM DD, YYYY')}
          project={get(getDataForInvoice(project, projectsList), 'name', '')}
          number={number || ''}
          second_date={moment(till_date).format('MMM DD, YYYY')}
          serviceInfo={description}
          isModalOpen={isModalOpen}
          isFetching={isFetching}
          handleClose={this.handleCloseModal}
          isMobile={isMobile}
        />
      </Container>
    );
  }
}

Bill.propTypes = propTypes;
Bill.defaultProps = defaultProps;
export default Bill;
