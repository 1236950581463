import {
  get,
} from 'lodash';
import constants from './constants';
import {
  transformRolesArrayToObject,
} from '../../utils';

export const initialState = {
  users: [],
  usersCount: 0,
  roles: {},
  rolesCount: 0,
  customers: [],
  customersCount: 0,
  projects: [],
  projectsCount: 0,
  bankAccounts: [],
  bankAccountsCount: 0,
  contractors: [],
  contractorsCount: 0,
  currency: [],
  currencyCount: 0,
  payMethods: [],
  payMethodsCount: 0,
  isFetching: false,
  error: {},
};

export const actionHandlers = {
  // ############################# Users #############################
  [constants.GET_ALL_USERS_REQUEST]: (state) => ({
    ...state,
    isFetching: true,
    error: null,
  }),
  [constants.GET_ALL_USERS_SUCCESS]: (state, action) => ({
    ...state,
    isFetching: false,
    users: get(action, 'payload.data.data', []),
    usersCount: get(action, 'payload.data.counter', 0),
  }),
  [constants.GET_ALL_USERS_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload,
  }),

  [constants.GET_USER_ROLES_REQUEST]: (state) => ({
    ...state,
    isFetching: true,
    error: null,
  }),
  [constants.GET_USER_ROLES_SUCCESS]: (state, action) => ({
    ...state,
    isFetching: false,
    roles: transformRolesArrayToObject(get(action, 'payload.data', {})),
  }),
  [constants.GET_USER_ROLES_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload,
  }),

  [constants.CREATE_USER_REQUEST]: (state) => ({
    ...state,
    isFetching: true,
    error: null,
  }),
  [constants.CREATE_USER_SUCCESS]: (state) => ({
    ...state,
    isFetching: false,
  }),
  [constants.CREATE_USER_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload,
  }),

  [constants.EDIT_USER_REQUEST]: (state) => ({
    ...state,
    isFetching: true,
    error: null,
  }),
  [constants.EDIT_USER_SUCCESS]: (state) => ({
    ...state,
    isFetching: false,
  }),
  [constants.EDIT_USER_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload,
  }),

  [constants.REMOVE_USER_REQUEST]: (state) => ({
    ...state,
    isFetching: true,
    error: null,
  }),
  [constants.REMOVE_USER_SUCCESS]: (state) => ({
    ...state,
    isFetching: false,
  }),
  [constants.REMOVE_USER_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload,
  }),

  // ############################# Customers #############################
  [constants.GET_ALL_CUSTOMERS_REQUEST]: (state) => ({
    ...state,
    isFetching: true,
    error: null,
  }),
  [constants.GET_ALL_CUSTOMERS_SUCCESS]: (state, action) => ({
    ...state,
    isFetching: false,
    customers: get(action, 'payload.data.data', []),
    customersCount: get(action, 'payload.data.counter', 0),
  }),
  [constants.GET_ALL_CUSTOMERS_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload,
  }),

  [constants.CREATE_CUSTOMER_REQUEST]: (state) => ({
    ...state,
    isFetching: true,
    error: null,
  }),
  [constants.CREATE_CUSTOMER_SUCCESS]: (state) => ({
    ...state,
    isFetching: false,
  }),
  [constants.CREATE_CUSTOMER_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload,
  }),

  [constants.EDIT_CUSTOMER_REQUEST]: (state) => ({
    ...state,
    isFetching: true,
    error: null,
  }),
  [constants.EDIT_CUSTOMER_SUCCESS]: (state) => ({
    ...state,
    isFetching: false,
  }),
  [constants.EDIT_CUSTOMER_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload,
  }),

  [constants.REMOVE_CUSTOMER_REQUEST]: (state) => ({
    ...state,
    isFetching: true,
    error: null,
  }),
  [constants.REMOVE_CUSTOMER_SUCCESS]: (state) => ({
    ...state,
    isFetching: false,
  }),
  [constants.REMOVE_CUSTOMER_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload,
  }),

  // ############################# Projects #############################
  [constants.GET_ALL_PROJECTS_REQUEST]: (state) => ({
    ...state,
    isFetching: true,
    error: null,
  }),
  [constants.GET_ALL_PROJECTS_SUCCESS]: (state, action) => ({
    ...state,
    isFetching: false,
    projects: get(action, 'payload.data.data', []),
    projectsCount: get(action, 'payload.data.counter', 0),
  }),
  [constants.GET_ALL_PROJECTS_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload,
  }),

  [constants.CREATE_PROJECT_REQUEST]: (state) => ({
    ...state,
    isFetching: true,
    error: null,
  }),
  [constants.CREATE_PROJECT_SUCCESS]: (state) => ({
    ...state,
    isFetching: false,
  }),
  [constants.CREATE_PROJECT_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload,
  }),

  [constants.EDIT_PROJECT_REQUEST]: (state) => ({
    ...state,
    isFetching: true,
    error: null,
  }),
  [constants.EDIT_PROJECT_SUCCESS]: (state) => ({
    ...state,
    isFetching: false,
  }),
  [constants.EDIT_PROJECT_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload,
  }),

  [constants.REMOVE_PROJECT_REQUEST]: (state) => ({
    ...state,
    isFetching: true,
    error: null,
  }),
  [constants.REMOVE_PROJECT_SUCCESS]: (state) => ({
    ...state,
    isFetching: false,
  }),
  [constants.REMOVE_PROJECT_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload,
  }),

  // ############################# Bank accounts #############################
  [constants.GET_ALL_BANK_ACC_REQUEST]: (state) => ({
    ...state,
    isFetching: true,
    error: null,
  }),
  [constants.GET_ALL_BANK_ACC_SUCCESS]: (state, action) => ({
    ...state,
    isFetching: false,
    bankAccounts: get(action, 'payload.data.data', []),
    bankAccountsCount: get(action, 'payload.data.counter', 0),
  }),
  [constants.GET_ALL_BANK_ACC_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload,
  }),

  [constants.GET_AVAILABLE_BANK_ACC_REQUEST]: (state) => ({
    ...state,
    isFetching: true,
    error: null,
  }),
  [constants.GET_AVAILABLE_BANK_ACC_SUCCESS]: (state, action) => ({
    ...state,
    isFetching: false,
    bankAccounts: get(action, 'payload.data.data', []),
    bankAccountsCount: get(action, 'payload.data.counter', 0),
  }),
  [constants.GET_AVAILABLE_BANK_ACC_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload,
  }),

  [constants.CREATE_BANK_ACC_REQUEST]: (state) => ({
    ...state,
    isFetching: true,
    error: null,
  }),
  [constants.CREATE_BANK_ACC_SUCCESS]: (state) => ({
    ...state,
    isFetching: false,
  }),
  [constants.CREATE_BANK_ACC_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload,
  }),

  [constants.EDIT_BANK_ACC_REQUEST]: (state) => ({
    ...state,
    isFetching: true,
    error: null,
  }),
  [constants.EDIT_BANK_ACC_SUCCESS]: (state) => ({
    ...state,
    isFetching: false,
  }),
  [constants.EDIT_BANK_ACC_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload,
  }),

  [constants.REMOVE_BANK_ACC_REQUEST]: (state) => ({
    ...state,
    isFetching: true,
    error: null,
  }),
  [constants.REMOVE_BANK_ACC_SUCCESS]: (state) => ({
    ...state,
    isFetching: false,
  }),
  [constants.REMOVE_BANK_ACC_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload,
  }),

  // ############################# Contractors #############################
  [constants.GET_ALL_CONTRACTORS_REQUEST]: (state) => ({
    ...state,
    isFetching: true,
    error: null,
  }),
  [constants.GET_ALL_CONTRACTORS_SUCCESS]: (state, action) => ({
    ...state,
    isFetching: false,
    contractors: get(action, 'payload.data.data', []),
    contractorsCount: get(action, 'payload.data.counter', 0),
  }),
  [constants.GET_ALL_CONTRACTORS_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload,
  }),

  [constants.CREATE_CONTRACTORS_REQUEST]: (state) => ({
    ...state,
    isFetching: true,
    error: null,
  }),
  [constants.CREATE_CONTRACTORS_SUCCESS]: (state) => ({
    ...state,
    isFetching: false,
  }),
  [constants.CREATE_CONTRACTORS_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload,
  }),

  [constants.EDIT_CONTRACTORS_REQUEST]: (state) => ({
    ...state,
    isFetching: true,
    error: null,
  }),
  [constants.EDIT_CONTRACTORS_SUCCESS]: (state) => ({
    ...state,
    isFetching: false,
  }),
  [constants.EDIT_CONTRACTORS_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload,
  }),

  [constants.REMOVE_CONTRACTORS_REQUEST]: (state) => ({
    ...state,
    isFetching: true,
    error: null,
  }),
  [constants.REMOVE_CONTRACTORS_SUCCESS]: (state) => ({
    ...state,
    isFetching: false,
  }),
  [constants.REMOVE_CONTRACTORS_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload,
  }),

  // ############################# Currency #############################
  [constants.GET_ALL_CURRENCY_REQUEST]: (state) => ({
    ...state,
    isFetching: true,
    error: null,
  }),
  [constants.GET_ALL_CURRENCY_SUCCESS]: (state, action) => ({
    ...state,
    isFetching: false,
    currency: get(action, 'payload.data.data', []),
    currencyCount: get(action, 'payload.data.counter', 0),
  }),
  [constants.GET_ALL_CURRENCY_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload,
  }),

  [constants.CREATE_CURRENCY_REQUEST]: (state) => ({
    ...state,
    isFetching: true,
    error: null,
  }),
  [constants.CREATE_CURRENCY_SUCCESS]: (state) => ({
    ...state,
    isFetching: false,
  }),
  [constants.CREATE_CURRENCY_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload,
  }),

  [constants.EDIT_CURRENCY_REQUEST]: (state) => ({
    ...state,
    isFetching: true,
    error: null,
  }),
  [constants.EDIT_CURRENCY_SUCCESS]: (state) => ({
    ...state,
    isFetching: false,
  }),
  [constants.EDIT_CURRENCY_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload,
  }),

  [constants.REMOVE_CURRENCY_REQUEST]: (state) => ({
    ...state,
    isFetching: true,
    error: null,
  }),
  [constants.REMOVE_CURRENCY_SUCCESS]: (state) => ({
    ...state,
    isFetching: false,
  }),
  [constants.REMOVE_CURRENCY_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload,
  }),

  // ############################# Pay Methods #############################
  [constants.GET_ALL_PAY_METHOD_REQUEST]: (state) => ({
    ...state,
    isFetching: true,
    error: null,
  }),
  [constants.GET_ALL_PAY_METHOD_SUCCESS]: (state, action) => ({
    ...state,
    isFetching: false,
    payMethods: get(action, 'payload.data.data', []),
    payMethodsCount: get(action, 'payload.data.counter', 0),
  }),
  [constants.GET_ALL_PAY_METHOD_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload,
  }),

  [constants.CREATE_PAY_METHOD_REQUEST]: (state) => ({
    ...state,
    isFetching: true,
    error: null,
  }),
  [constants.CREATE_PAY_METHOD_SUCCESS]: (state) => ({
    ...state,
    isFetching: false,
  }),
  [constants.CREATE_PAY_METHOD_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload,
  }),

  [constants.EDIT_PAY_METHOD_REQUEST]: (state) => ({
    ...state,
    isFetching: true,
    error: null,
  }),
  [constants.EDIT_PAY_METHOD_SUCCESS]: (state) => ({
    ...state,
    isFetching: false,
  }),
  [constants.EDIT_PAY_METHOD_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload,
  }),

  [constants.REMOVE_PAY_METHOD_REQUEST]: (state) => ({
    ...state,
    isFetching: true,
    error: null,
  }),
  [constants.REMOVE_PAY_METHOD_SUCCESS]: (state) => ({
    ...state,
    isFetching: false,
  }),
  [constants.REMOVE_PAY_METHOD_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload,
  }),
};

export const reducer = (state = initialState, action) => {
  const handler = actionHandlers[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
