import {
 connect,
} from 'react-redux';
import {
  withRouter,
} from 'react-router';
import {
 key,
} from '../module/constants';
import {
 key as managementKey,
} from '../../management/module/constants';
import Bill from './Bill';

import {
  getInvoiceById as getById,
  createInvoice as create,
  editInvoice as edit,
  removeInvoice as remove,
  setInvoice as set,
  setIsCopy,
} from '../module/actions';

import {
  getAllCustomers,
  getAllProjects,
  getAllBankAcc,
  getAllContractors,
} from '../../management/module/actions';

const mapStateToProps = (state) => ({
  invoice: state[key].invoice,
  isCopy: state[key].isCopy,
  isFetching: state[key].isFetching,
  customersList: state[managementKey].customers,
  contractorsList: state[managementKey].contractors,
  projectsList: state[managementKey].projects,
  bankAccountList: state[managementKey].bankAccounts,
  isMobile: state.app.isMobile,
  isManagementListsFetching: state[managementKey].isFetching,
});

const mapDispatchToProps = {
  getById,
  create,
  edit,
  remove,
  set,
  getAllCustomers,
  getAllProjects,
  getAllBankAcc,
  getAllContractors,
  setIsCopy,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Bill));
