import React from 'react';
import {
  Grid,
  Image,
  Container,
} from 'semantic-ui-react';
import {
  Redirect,
} from 'react-router-dom';
import './style.scss';
import logo from '../assets/Lanars-logo.jpg';

const propTypes = {};

const defaultProps = {};

function Dashboard() {
  return (
    <Container
      className='dashboard'
    >
      <Redirect
        to='/main'
      />
      <Grid
        columns={1}
        relaxed
      >
        <Grid.Row>
          <Grid.Column
            textAlign='center'
            className='image-wrapper'
          >
            <Image
              src={logo}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
}

Dashboard.propTypes = propTypes;
Dashboard.defaultProps = defaultProps;
export default Dashboard;
