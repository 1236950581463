import {
  connect,
} from 'react-redux';
import Layout from './Layout';

const mapStateToProps = (state) => ({
  token: state.app.access,
  isMobile: state.app.isMobile,
});

export default connect(mapStateToProps)(Layout);
