import React, {
  Component,
} from 'react';
import {
  Button,
  Form,
  Modal,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {
  modalStyles,
} from '../../ui_parts/Confirm';
import './style.scss';

const propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  item: PropTypes.instanceOf(Object).isRequired,
  isMobile: PropTypes.bool.isRequired,
};

const defaultProps = {};

class CurrencyModal extends Component {
  constructor() {
    super();
    this.state = {
      editableItem: {},
    };
  }

  handleChange = (name, val) => {
    const {
      editableItem,
    } = this.state;
    if (val.length <= 3) {
      editableItem[name] = val.toUpperCase();
      return this.setState({
        editableItem,
      });
    }
  };

  submit = () => {
    const {
      handleSubmit,
      item,
    } = this.props;
    const {
      editableItem,
    } = this.state;
    const newItem = {
      ...item,
      ...editableItem,
    };
    handleSubmit(newItem);
    this.setState({
      editableItem: {},
    });
  };

  close = () => {
    const {
      handleClose,
    } = this.props;
    handleClose();
    this.setState({
      editableItem: {},
    });
  };

  render() {
    const {
      isModalOpen,
      item/*: {
        id,
        name,
      }*/,
      isFetching,
      isMobile,
    } = this.props;

    const {
      editableItem,
    } = this.state;

    // const newEditableItem = {
    //   ...item,
    //   ...editableItem,
    // };
    // const {
    //   id,
    //   name,
    // } = newEditableItem;
    return (
      <Modal
        dimmer='blurring'
        closeOnDimmerClick={false}
        open={isModalOpen}
        style={modalStyles}
        size='small'
        className={`management-modal${isMobile ? ' mobile' : ''}`}
      >
        <Modal.Header>{item.id ? item.name : 'Create new Currency'}</Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            <Form
              loading={isFetching}
              onSubmit={this.submit}
              id='the-customer-form'
            >
              <Form.Input
                fluid
                label='Currency name'
                autoFocus
                required
                pattern='[A-Z]+'
                value={editableItem.name}
                defaultValue={item.name}
                placeholder='Currency name'
                onChange={(e, val) => this.handleChange('name', val.value)}
              />
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color='black'
            onClick={this.close}
            disabled={isFetching}
            content='Cancel'
            size={isMobile ? 'massive' : 'medium'}
          />
          <Button
            color='orange'
            inverted
            icon='checkmark'
            labelPosition='right'
            disabled={isFetching}
            type='submit'
            form='the-customer-form'
            content={item.id ? 'Save' : 'Create'}
            size={isMobile ? 'massive' : 'medium'}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

CurrencyModal.propTypes = propTypes;
CurrencyModal.defaultProps = defaultProps;
export default CurrencyModal;
