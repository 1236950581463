import React from 'react';
import PropTypes from 'prop-types';
import {
  Redirect,
} from 'react-router-dom';
import Navbar from './navbar';
import LayoutRoutes from './LayoutRoutes';
import Notifications from './notification';
import './style.scss';

const DefaultProps = {
  token: '',
};

const propTypes = {
  token: PropTypes.string,
  isMobile: PropTypes.bool.isRequired,
};

function Layout (props) {
  const {
    token,
    isMobile,
  } = props;

  return (
    !token ? <Redirect to='/login' /> : (
      <div className={`layout${isMobile ? ' mobile' : ''}`}>
        <Notifications />
        <Navbar />
        <LayoutRoutes />
      </div>
    )
  );
}

Layout.propTypes = propTypes;
Layout.defaultProps = DefaultProps;
export default Layout;
