import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';
import Layout from '../layout';
import LogIn from '../login';
import './style.scss';

export default function App() {
  return (
    <Switch>
      <Route
        exact
        path='/login'
        component={LogIn}
      />
      <Route
        path='/'
        component={Layout}
      />
    </Switch>
  );
}
