import React, {
  Component,
} from 'react';
import PropTypes from 'prop-types';
import {
  NavLink,
} from 'react-router-dom';
import {
  Container,
  Divider,
  Dropdown,
  Image,
  Menu,
  Icon,
} from 'semantic-ui-react';
import img from '../../assets/Lanars-logo.jpg';
import './styles.scss';

const propTypes = {
  userId: PropTypes.number.isRequired,
  logOut: PropTypes.func.isRequired,
  getProfile: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

class Navbar extends Component {

  componentDidMount() {
    const {
      userId,
      getProfile,
    } = this.props;
    if (userId) {
      getProfile(userId);
    }
  }

  handleOption = href => {
    const {
      history: {
        push,
      },
    } = this.props;
    push(href);
  };

  render() {
    const {
      logOut,
      isFetching,
      isMobile,
    } = this.props;
    return (
      <Menu
        fixed='top'
        borderless
        inverted
        className={`top-navbar${isMobile ? ' mobile' : ''}`}
      >
        <Container>
          <Menu.Item
            header
            href='//lanars.com'
            target='_blank'
          >
            <Image
              size='mini'
              src={img}
            />
            <span className='logo-description'>LANARS</span>
          </Menu.Item>
          {/*<Menu.Item>*/}
          {/*  <NavLink to='/' className='navigation-button' exact activeClassName='active'>*/}
          {/*    <Icon name='dashboard' />*/}
          {/*    <span className='description'>Dashboard</span>*/}
          {/*  </NavLink>*/}
          {/*</Menu.Item>*/}
          <Menu.Item>
            <NavLink to='/main' className='navigation-button' exact activeClassName='active'>
              <Icon name='money' />
              <span className='description'>Main</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item>
            <NavLink to='/reports' className='navigation-button' exact activeClassName='active'>
              <Icon name='dollar' />
              <span className='description'>Reports</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item>
            <NavLink to='/management' className='navigation-button' activeClassName='active'>
              <Icon name='book' />
              <span className='description'>Management</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item>
            <NavLink to='/history' className='navigation-button' activeClassName='active'>
              <Icon name='list' />
              <span className='description'>History</span>
            </NavLink>
          </Menu.Item>
          <Divider hidden />
          <Dropdown
            pointing
            icon='user secret'
            loading={isFetching}
            disabled={isFetching}
            className='link item'
            closeOnChange
            defaultOpen={false}
          >
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => this.handleOption('/profile')}>
                Profile
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item
                className='logout-button'
                onClick={logOut}
              >
                Log out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Container>
      </Menu>
    );
  }
}

Navbar.propTypes = propTypes;
export default Navbar;
