import ls from 'local-storage';
import get from 'lodash/get';
import filter from 'lodash/filter';
import random from 'lodash/random';
import concat from 'lodash/concat';
import constants from './constants';
import Http from '../../utils';


const setCredentialsToLocalStorage = res => {
  const access = get(res, 'data.access', null);
  const refresh = get(res, 'data.refresh', null);
  if (access && refresh) {
    ls.set('access', access);
    ls.set('refresh', refresh);
  }
};

export const setWidth = width => dispatch => dispatch({
  type: constants.SET_WIDTH,
  width,
});

export const setIsMobile = isMobile => dispatch => dispatch({
  type: constants.SET_IS_MOBILE,
  isMobile,
});

export const logIn = acount => dispatch => {
  dispatch({
    type: constants.LOGIN_REQUEST,
  });
  Http.post('/token/', acount)
    .then(
      res => {
        setCredentialsToLocalStorage(res);
        dispatch({
          type: constants.LOGIN_SUCCESS,
          payload: res,
        });
      },
      err => dispatch({
        type: constants.LOGIN_FAILURE,
        payload: err,
      })
    );
};

export const refreshToken = (token) => dispatch => {
  dispatch({
    type: constants.REFRESH_TOKEN_REQUEST,
  });
  return Http.post('/refresh-token/', {
    refresh: token,
  })
    .then(
      res => {
        setCredentialsToLocalStorage(res);
        dispatch({
          type: constants.REFRESH_TOKEN_SUCCESS,
          payload: res,
        });
      },
      err => dispatch({
        type: constants.REFRESH_TOKEN_FAILURE,
        payload: err,
      })
    );
};

export const logOut = () => dispatch => {
  ls.remove('access');
  ls.remove('refresh');
  dispatch({
    type: constants.LOGOUT,
  });
};

export const createNotification = (notification) => (dispatch, getState) => {
  const currentNotifications = getState().app.notifications;
  const newNotification = {
    id: `${random(0, 99999)}-${Date.now()}`,
    ...notification,
  };
  dispatch({
    type: constants.CREATE_NOTIFICATION,
    payload: concat(currentNotifications, [newNotification]),
  });
};

export const removeNotification = (notification) => (dispatch, getState) => {
  const currentNotifications = getState().app.notifications;
  dispatch({
    type: constants.CREATE_NOTIFICATION,
    payload: filter(currentNotifications, el => el.id !== notification.id),
  });
};
