import {
  connect,
} from 'react-redux';
import {
  withRouter,
} from 'react-router-dom';
import {
  get,
} from 'lodash';
import Profile from './Profile';
import {
  key,
} from './module/constants';
import {
  editUser,
} from '../management/module/actions';
import * as getProfile from './module/actions';

const mapStateToProps = (state) => ({
  userId: get(state, 'app.accessDecoded.user_id', 0),
  account: state[key].account || {},
  isFetching: state[key].isFetching,
  isMobile: state.app.isMobile,
});

const mapDispatchToProps = {
  editUser,
  getProfile,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile));
