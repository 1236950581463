export const key = 'history';
export const consts = {
  SET_FILTERS: `${key}/SET_FILTERS`,

  GET_HISTORY_REQUEST: `${key}/GET_HISTORY_REQUEST`,
  GET_HISTORY_SUCCESS: `${key}/GET_HISTORY_SUCCESS`,
  GET_HISTORY_FAILURE: `${key}/GET_HISTORY_FAILURE`,
};

export default consts;
