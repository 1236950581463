import {
 connect,
} from 'react-redux';
import Reports from './Reports';

import {
  setReportFilters,
} from './module/actions';

const mapStateToProps = (state) => ({
  isMobile: state.app.isMobile,
});
const mapDispatchToProps = {
  setReportFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
