import {
 connect,
} from 'react-redux';
import Contractors from './Contractors';
import {
 key,
} from '../module/constants';
import {
  getAllContractors as getAll,
  createContractor as create,
  editContractor as edit,
  removeContractor as remove,
  getAvailableBankAcc,
} from '../module/actions';

const mapStateToProps = (state) => ({
  list: state[key].contractors,
  count: state[key].contractorsCount,
  bankAccList: state[key].bankAccounts,
  isFetching: state[key].isFetching,
  isMobile: state.app.isMobile,
});

const mapDispatchToProps = {
  getAll,
  create,
  edit,
  remove,
  getAvailableBankAcc,
};

export default connect(mapStateToProps, mapDispatchToProps)(Contractors);
