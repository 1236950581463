import React, {
  Component,
} from 'react';
import {
  Button,
  Form,
  Modal,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {
  debounce,
} from 'lodash';
import {
  modalStyles,
} from '../../ui_parts/Confirm';
import './style.scss';
import {
  addDataForDropDowns,
  getDataForDropDowns,
  prepareQuery,
  sortAlphabetical,
} from '../../utils';
import {
  INPUTS_VALIDATION_PATTERNS,
  DATA_LIMIT,
} from '../../constants';

const propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  getAllCustomers: PropTypes.func.isRequired,
  item: PropTypes.instanceOf(Object).isRequired,
  customersList: PropTypes.instanceOf(Array).isRequired,
  isMobile: PropTypes.bool.isRequired,
};

const defaultProps = {};

class ProjectsModal extends Component {
  constructor() {
    super();
    this.state = {
      editableItem: {},
    };
    this.fetchData = debounce((fn, param) => fn(param), 500, {
      leading: true,
    });
  }

  componentDidUpdate(prevProps) {
    const {
      isModalOpen, getAllCustomers,
    } = this.props;
    if (!prevProps.isModalOpen && isModalOpen) {
      getAllCustomers();
    }
  }

  handleSearchChange = (fnName, query) => {
    const params = prepareQuery(query);
    // eslint-disable-next-line react/destructuring-assignment
    this.fetchData(this.props[fnName], params);
  };

  handleChange = (name, val) => {
    const {
      editableItem,
    } = this.state;
    editableItem[name] = val;
    this.setState({
      editableItem,
    });
  };

  submit = () => {
    const {
      handleSubmit,
      item,
    } = this.props;
    const {
      editableItem,
    } = this.state;
    const newItem = {
      ...item,
      ...editableItem,
    };
    if (!newItem.customer) {
      return;
    }
    if (typeof newItem.customer === 'object') {
      newItem.customer = newItem.customer.id;
    }
    handleSubmit(newItem);
  };

  close = () => {
    const {
      handleClose,
    } = this.props;
    handleClose();
    this.setState({
      editableItem: {},
    });
  };

  render() {
    const {
      isModalOpen,
      item,
      customersList,
      isFetching,
      isMobile,
    } = this.props;
    const {
      editableItem,
    } = this.state;
    const mergedItem = {
      ...item,
      ...editableItem,
    };
    return (
      <Modal
        dimmer='blurring'
        closeOnDimmerClick={false}
        open={isModalOpen}
        style={modalStyles}
        size='small'
        className={`management-modal${isMobile ? ' mobile' : ''}`}
      >
        <Modal.Header>{mergedItem.id ? `${item.name}` : 'Create new Project'}</Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            <Form
              loading={isFetching}
              onSubmit={this.submit}
              id='the-project-form'
            >
              <Form.Input
                fluid
                label='Project name'
                autoFocus
                required
                pattern={INPUTS_VALIDATION_PATTERNS.PROJECT_NAME}
                defaultValue={mergedItem.name}
                placeholder='Project name'
                onChange={(e, val) => this.handleChange('name', val.value)}
              />
              <Form.Select
                fluid
                required
                search
                label='Customer'
                onChange={(e, val) => this.handleChange('customer', val.value)}
                options={addDataForDropDowns(sortAlphabetical(customersList), mergedItem.customer)}
                placeholder='Select a customer'
                onOpen={() => this.handleSearchChange('getAllCustomers', {
                    search: '',
                    limit: DATA_LIMIT,
                  }
                )}
                onSearchChange={(e, data) => this.handleSearchChange('getAllCustomers', {
                  search: data.searchQuery,
                  limit: DATA_LIMIT,
                })}
                value={getDataForDropDowns(mergedItem.customer)}
                disabled={isFetching}
              />
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color='black'
            onClick={this.close}
            disabled={isFetching}
            content='Cancel'
            size={isMobile ? 'massive' : 'medium'}
          />
          <Button
            color='orange'
            inverted
            icon='checkmark'
            labelPosition='right'
            disabled={isFetching}
            type='submit'
            form='the-project-form'
            content={mergedItem.id ? 'Save' : 'Create'}
            size={isMobile ? 'massive' : 'medium'}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

ProjectsModal.propTypes = propTypes;
ProjectsModal.defaultProps = defaultProps;
export default ProjectsModal;
