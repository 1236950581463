import get from 'lodash/get';
import ls from 'local-storage';
import constants from './constants';
import {
  decodeJwtToken,
} from '../../utils';

const access = ls.get('access');
const refresh = ls.get('refresh');

export const initialState = {
  isMobile: false,
  width: 0,
  access: access || null,
  refresh: refresh || null,
  accessDecoded: access ? decodeJwtToken(access) : null,
  isFetching: false,
  error: null,
  notifications: [],
};

export const actionHandlers = {
  [constants.SET_WIDTH]: (state, action) => ({
    ...state,
    width: action.width,
  }),

  [constants.SET_IS_MOBILE]: (state, action) => ({
    ...state,
    isMobile: action.isMobile,
  }),

  [constants.LOGIN_REQUEST]: (state) => ({
    ...state,
    isFetching: true,
    error: null,
  }),
  [constants.LOGIN_SUCCESS]: (state, action) => {
    const {
      access = null,
      refresh = null,
    } = get(action, 'payload.data', {});
    const accessDecoded = decodeJwtToken(access);
    return {
      ...state,
      access,
      refresh,
      accessDecoded,
      isFetching: false,
    };
  },
  [constants.LOGIN_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    error: {
      ...action.payload,
      errorType: 'login_error',
    },
  }),

  [constants.LOGOUT]: (state) => ({
    ...state,
    access: null,
    refresh: null,
    error: null,
  }),

  [constants.REFRESH_TOKEN_REQUEST]: (state) => ({
    ...state,
    isFetching: true,
    error: null,
  }),
  [constants.REFRESH_TOKEN_SUCCESS]: (state, action) => {
    const {
      access = null,
      refresh = null,
    } = get(action, 'payload.data', {});
    const accessDecoded = decodeJwtToken(access);
    return {
      ...state,
      access,
      refresh,
      accessDecoded,
      isFetching: false,
    };
  },
  [constants.REFRESH_TOKEN_FAILURE]: (state, action) => ({
    ...state,
    isFetching: false,
    access: null,
    refresh: null,
    error: action.payload,
  }),
  [constants.CREATE_NOTIFICATION]: (state, action) => ({
    ...state,
    notifications: action.payload,
  }),
};

const reducers = (state = initialState, action) => {
  const handler = actionHandlers[action.type];
  return handler ? handler(state, action) : state;
};

export default reducers;
