import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import img from '../../../assets/lanarss.png';
import {
  prepareMultiLineText,
} from '../../../utils';

const propTypes = {
  bankAcc: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  contractor: PropTypes.string.isRequired,
  contractorsAddress: PropTypes.string.isRequired,
  customer: PropTypes.string.isRequired,
  customersAddress: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  project: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  paymentMethod: PropTypes.string.isRequired,
  second_date: PropTypes.string.isRequired,
  serviceInfo: PropTypes.instanceOf(Array),
  total: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

const defaultProps = {
  total: 0,
  serviceInfo: [],
};

const InvoicePDF = ({
                      date,
                      second_date,
                      customer,
                      contractor,
                      number,
                      project,
                      bankAcc,
                      currency,
                      total,
                      paymentMethod,
                      serviceInfo,
                      contractorsAddress,
                      customersAddress,
                    }) => (
                      <div
                        id='invoicePDF'
                      >
                        <div className='row-1'>
                          <div className='logo'>
                            <img
                              alt='lanars.png'
                              src={img}
                            />
                          </div>
                          <div className='date-wrapper'>
                            <div className='invoice-date'>
                              <span className='date-label'>Date:</span>
                              <span className='date'>{date}</span>
                            </div>
                            <div className='invoice-date'>
                              <span className='date-label'>Due date:</span>
                              <span className='date'>{second_date}</span>
                            </div>
                          </div>
                        </div>
                        <div className='row-2'>
                          <h1>{`Invoice #${number}`}</h1>
                          <h3>for development services rendered</h3>
                        </div>
                        <div className='row-3'>
                          <div className='left-part'>
                            <span className='invoice-label'>
                              Contractor:
                            </span>
                            <span className='invoice-data'>
                              {contractor}
                            </span>
                          </div>
                          <div className='right-part'>
                            <span className='invoice-label'>
                              Customer:
                            </span>
                            <span className='invoice-data'>
                              {customer}
                            </span>
                          </div>
                        </div>
                        <div className='row-4'>
                          <div className='left-part'>
                            {prepareMultiLineText(contractorsAddress)}
                          </div>
                          <div className='right-part'>
                            {prepareMultiLineText(customersAddress)}
                          </div>
                        </div>
                        <div className='row-5'>
                          <span className='invoice-label'>
                            Project:
                          </span>
                          <span className='invoice-data'>
                            {project}
                          </span>
                        </div>
                        <div className='row-6'>
                          <table>
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Description</th>
                                <th>Q-ty, (h)</th>
                                {
                                  serviceInfo.some(el => el.price_per_hour) ?
                                    <th>Price/h</th> :
                                    <th />
                                }
                                <th>{`Amount, (${currency})`}</th>
                                <th>Notes</th>
                              </tr>
                            </thead>
                            <tbody>
                              {serviceInfo.map((el, i) => (
                                <tr key={el.id || i}>
                                  <td className='text-centered'>{i + 1}</td>
                                  <td>{el.description}</td>
                                  <td className='text-centered'>{el.qty}</td>
                                  <td className='text-centered'>
                                    {
                                      el.price_per_hour ?
                                        el.price_per_hour :
                                        null
                                    }
                                  </td>
                                  <td className='text-centered'>{el.amount}</td>
                                  <td>{prepareMultiLineText(el.note)}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        <div className='row-7'>
                          <span className='invoice-label'>
                            To be paid:
                          </span>
                          <span className='invoice-data'>
                            {total}
                            {' '}
                            {currency}
                          </span>
                        </div>
                        <div className='row-8'>
                          <span className='invoice-label'>
                            Payment method:
                          </span>
                          <span className='invoice-data'>
                            {paymentMethod}
                          </span>
                        </div>
                        <div className='row-9'>
                          <span className='invoice-label'>
                            Details:
                          </span>
                          <span className='invoice-data'>
                            {prepareMultiLineText(bankAcc)}
                          </span>
                        </div>
                      </div>
);

InvoicePDF.propTypes = propTypes;
InvoicePDF.defaultProps = defaultProps;
export default InvoicePDF;
