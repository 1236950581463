import {
 connect, 
} from 'react-redux';
import {
 withRouter, 
} from 'react-router-dom';
import ListPanel from './ListPanel';
import {
  getReports as getAll,
  setReportFilters,
} from '../module/actions';
import {
 key, 
} from '../module/constants';

const mapStateToProps = (state) => ({
  list: state[key].reportsList,
  isFetching: state[key].isFetching,
  filters: state[key].filters,
  isMobile: state.app.isMobile,
});

const mapDispatchToProps = {
  getAll,
  setReportFilters,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListPanel));
