import React, {
  Component,
} from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  Grid,
  Button,
  Icon,
  Popup,
} from 'semantic-ui-react';
import moment from 'moment';
import map from 'lodash/map';
import get from 'lodash/get';
import Search from '../../ui_parts/Search';
import AddItemButton from '../../ui_parts/AddItemButton';
import Pagination from '../../ui_parts/Pagination';
import Confirm from '../../ui_parts/Confirm';
import PermissionWrapper, {
  roles,
} from '../../ui_parts/PermissionWrapper';
import './style.scss';
import {
  prepareQuery,
  prepareInvoiceQuery,
  preparePagination,
  setOffset,
  prepareMultiLineText,
  getAuthorOfInvoice,
} from '../../utils';
import {
  DATE_FORMAT,
} from '../../constants';

const propTypes = {
  isMobile: PropTypes.bool.isRequired,
  list: PropTypes.instanceOf(Array).isRequired,
  count: PropTypes.number.isRequired,
  filters: PropTypes.instanceOf(Object).isRequired,
  getAll: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  setIsCopy: PropTypes.func.isRequired,
  setInvoiceFilters: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  userId: PropTypes.number.isRequired,
};

class ListPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: {},
      isConfirmOpen: false,
    };
  }

  componentDidMount() {
    const {
      filters,
    } = this.props;
    this.fetchList(filters);
  }

  handleEdit = (id) => {
    const {
      history: {
        push,
      },
    } = this.props;
    push(`/main/${id}`);
  };

  handleCopy = (id) => {
    const {
      setIsCopy,
      history: {
        push,
      },
    } = this.props;
    push(`/main/${id}`);
    setIsCopy(true);
  };

  handleSearchChange = (e, {
    value,
  }) => {
    const {
      filters, setInvoiceFilters,
    } = this.props;
    const newFilters = {
      ...filters,
    };
    newFilters.offset = 0;
    if (value) {
      newFilters.search = value;
    } else {
      delete newFilters.search;
    }
    setInvoiceFilters(newFilters);
    this.fetchList(newFilters);
  };

  handlePaginationChange = (e, {
    activePage,
  }) => {
    const {
      filters, setInvoiceFilters,
    } = this.props;
    const newFilters = {
      ...filters,
    };
    newFilters.offset = setOffset(activePage);
    setInvoiceFilters(newFilters);
    this.fetchList(newFilters);
  };

  handleRemove = () => {
    const {
      getAll,
      remove,
      filters,
    } = this.props;
    const {
      item,
    } = this.state;
    remove(item.id)
    .then(() => {
      getAll({
        ...prepareQuery(filters), ...prepareInvoiceQuery(filters),
      });
      this.handleToggleConfirm();
    });
  };

  handleToggleConfirm = (isConfirmOpen = false, item = {}) => {
    this.setState({
      item,
      isConfirmOpen,
    });
  };

  fetchList = (filters = {}) => {
    const {
      getAll,
    } = this.props;
    return getAll({
      ...prepareQuery(filters), ...prepareInvoiceQuery(filters),
    });
  };

  handleSort = (clickedColumn) => {
    const {
      filters, setInvoiceFilters,
    } = this.props;
    const newFilters = {
      ...filters,
    };
    if (filters.order_by !== clickedColumn) {
      newFilters.order_by = clickedColumn;
    }
    newFilters.order = filters.order === 'asc' ? 'desc' : 'asc';
    setInvoiceFilters(newFilters);
    this.fetchList(newFilters);
  };

  renderIcons = (invoice_state, till_date) => {
    let icon = {
      name: 'x',
      color: 'grey',
    };

    switch (invoice_state) {
      case 'paid': {
        icon = {
          name: 'check circle',
          color: 'green',
        };
        break;
      }
      case 'partially paid': {
        icon = {
          name: 'check circle',
          color: 'orange',
        };
        break;
      }
      case 'write off': {
        icon = {
          name: '',
          color: '',
        };
      }
      break;
    }

    const isInvoiceOverdue = (invoice_state !== 'paid') && moment().isAfter(till_date);
    if (isInvoiceOverdue) {
      icon.color = 'red';
    }

    return icon;
  }

  render() {
    const {
      isFetching,
      filters: {
        order,
        search,
        order_by,
        limit,
        offset,
      },
      list,
      count,
      isMobile,
      userId,
    } = this.props;
    const {
      isConfirmOpen,
    } = this.state;
    const direction = order === 'asc' ? 'ascending' : 'descending';
    const {
      totalPages,
      activePage,
    } = preparePagination(limit, offset, count);
    return (
      <Grid.Row className={`list-panel${isMobile ? ' mobile' : ''}`}>
        <Table compact unstackable color='orange'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan='3'>
                <PermissionWrapper availableRoles={[roles.ROOT, roles.DIRECTOR, roles.MANAGER]}>
                  <AddItemButton
                    onClick={() => this.handleEdit('new')}
                    icon='plus'
                    text='Add new invoice'
                  />
                </PermissionWrapper>
              </Table.HeaderCell>
              <Table.HeaderCell colSpan='8'>
                <Search
                  value={search || ''}
                  isFetching={isFetching}
                  onSearchChange={this.handleSearchChange}
                />
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell
                sorted={order_by === 'number' ? direction : null}
                onClick={() => this.handleSort('number')}
              >
                Number
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={order_by === 'date' ? direction : null}
                onClick={() => this.handleSort('date')}
              >
                Date
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={order_by === 'till_date' ? direction : null}
                onClick={() => this.handleSort('till_date')}
              >
                Due date
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={order_by === 'customer' ? direction : null}
                onClick={() => this.handleSort('customer')}
              >
                Customer
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={order_by === 'contractor' ? direction : null}
                onClick={() => this.handleSort('contractor')}
              >
                Contractor
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={order_by === 'project' ? direction : null}
                onClick={() => this.handleSort('project')}
              >
                Project
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={order_by === 'user' ? direction : null}
                onClick={() => this.handleSort('user')}
              >
                User
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={order_by === 'bank_account' ? direction : null}
                onClick={() => this.handleSort('bank_account')}
              >
                Bank account
              </Table.HeaderCell>
              <Table.HeaderCell>
                Info
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={order_by === 'invoice_state' ? direction : null}
                onClick={() => this.handleSort('invoice_state')}
              >
                Paid
              </Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {map(list, item => {
              const bankAcc = item.bank_account || {};
              const icon = this.renderIcons(item.invoice_state, item.till_date);

              return (
                <Table.Row key={item.id}>
                  <Table.Cell data-label='Number'>{item.number || '-'}</Table.Cell>
                  <Table.Cell data-label='Date'>{moment(item.date).format(DATE_FORMAT)}</Table.Cell>
                  <Table.Cell data-label='Date'>{moment(item.till_date).format(DATE_FORMAT)}</Table.Cell>
                  <Table.Cell data-label='Customer'>{get(item, 'customer.name', '')}</Table.Cell>
                  <Table.Cell data-label='Contractor'>{get(item, 'contractor.name', '')}</Table.Cell>
                  <Table.Cell data-label='Project'>{get(item, 'project.name', '')}</Table.Cell>
                  <Table.Cell data-label='User'>{getAuthorOfInvoice(item)}</Table.Cell>
                  <Table.Cell data-label='Bank account'>
                    {`${bankAcc.account}
                    (${get(bankAcc, 'payment_method.name', '')} - ${get(bankAcc, 'currency.name', '')})`}
                  </Table.Cell>
                  <Table.Cell data-label='Info'>
                    {item.note ? (
                      <Popup
                        trigger={<Icon circular name='info' />}
                        content={prepareMultiLineText(item.note)}
                        inverted
                        position='top right'
                      />
                    ) : <span className='tech-divider' />
                    }
                  </Table.Cell>
                  <Table.Cell data-label='Paid'>
                    <Icon
                      name={icon.name}
                      color={icon.color}
                    />
                  </Table.Cell>
                  <Table.Cell data-label=''>
                    <PermissionWrapper availableRoles={[roles.ROOT, roles.DIRECTOR, roles.MANAGER]}>
                      <Button
                        icon
                        onClick={() => this.handleCopy(item.id)}
                      >
                        <Icon
                          name='copy'
                        />
                      </Button>
                    </PermissionWrapper>
                    <Button
                      icon
                      onClick={() => this.handleEdit(item.id)}
                    >
                      <Icon
                        name='edit'
                      />
                    </Button>
                    <PermissionWrapper
                      availableRoles={[
                        roles.ROOT,
                        roles.ACCOUNTANT,
                        item.invoice_state === 'unpaid' ? roles.DIRECTOR : '',
                        ((item.invoice_state === 'unpaid')
                          && (get(item, 'created_user.id') === userId))
                          ? roles.MANAGER : '',
                      ]}
                    >
                      <Button
                        icon
                        onClick={() => this.handleToggleConfirm(true, item)}
                      >
                        <Icon name='trash' />
                      </Button>
                    </PermissionWrapper>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
          {totalPages > 1 && (
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan='12'>
                  <Pagination
                    handlePaginationChange={this.handlePaginationChange}
                    activePage={activePage}
                    totalPages={totalPages}
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          )}
        </Table>
        <Confirm
          open={isConfirmOpen}
          confirmButton='Delete'
          content='Are you sure you want to remove this invoice?'
          onCancel={() => this.handleToggleConfirm()}
          onConfirm={this.handleRemove}
        />
      </Grid.Row>
    );
  }
}

ListPanel.propTypes = propTypes;
export default ListPanel;
