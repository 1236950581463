import React from 'react';
import {
  render,
} from 'react-dom';
import {
  Root,
} from './Root';
import * as serviceWorker from './serviceWorker';

render(
  <Root />,
  document.getElementById('root')
);

serviceWorker.unregister();
