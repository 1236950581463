import constants from './constants';
import Http, {
  getQuery,
} from '../../utils';


// ############################# Users #############################
export const getAllUsers = (query) => dispatch => {
  dispatch({
    type: constants.GET_ALL_USERS_REQUEST,
  });
  return Http.get(`/user/${getQuery(query)}`)
  .then(
    res => {
      dispatch({
        type: constants.GET_ALL_USERS_SUCCESS,
        payload: res,
      });
    },
    err => dispatch({
      type: constants.GET_ALL_USERS_FAILURE,
      payload: err,
    })
  );
};

export const getUserRoles = () => dispatch => {
  dispatch({
    type: constants.GET_USER_ROLES_REQUEST,
  });
  return Http.get('/get-roles/')
  .then(
    res => {
      dispatch({
        type: constants.GET_USER_ROLES_SUCCESS,
        payload: res,
      });
    },
    err => dispatch({
      type: constants.GET_USER_ROLES_FAILURE,
      payload: err,
    })
  );
};

export const createUser = (data) => dispatch => {
  dispatch({
    type: constants.CREATE_USER_REQUEST,
  });
  return Http.post('/user/', data)
  .then(
    res => {
      dispatch({
        type: constants.CREATE_USER_SUCCESS,
        payload: res,
      });
    },
    err => dispatch({
      type: constants.CREATE_USER_FAILURE,
      payload: err,
    })
  );
};

export const editUser = (data, id) => dispatch => {
  dispatch({
    type: constants.EDIT_USER_REQUEST,
  });
  return Http.put(`/user/${id}/`, data)
  .then(
    res => {
      dispatch({
        type: constants.EDIT_USER_SUCCESS,
        payload: res,
      });
    },
    err => dispatch({
      type: constants.EDIT_USER_FAILURE,
      payload: err,
    })
  );
};


export const removeUser = (id) => dispatch => {
  dispatch({
    type: constants.REMOVE_USER_REQUEST,
  });
  return Http.delete(`/user/${id}/`)
  .then(
    res => {
      dispatch({
        type: constants.REMOVE_USER_SUCCESS,
        payload: res,
      });
    },
    err => dispatch({
      type: constants.REMOVE_USER_FAILURE,
      payload: err,
    })
  );
};


// ############################# Customers #############################
export const getAllCustomers = (query) => dispatch => {
  dispatch({
    type: constants.GET_ALL_CUSTOMERS_REQUEST,
  });
  return Http.get(`/customer/${getQuery(query)}`)
  .then(
    res => {
      dispatch({
        type: constants.GET_ALL_CUSTOMERS_SUCCESS,
        payload: res,
      });
    },
    err => dispatch({
      type: constants.GET_ALL_CUSTOMERS_FAILURE,
      payload: err,
    })
  );
};

export const createCustomer = (data) => dispatch => {
  dispatch({
    type: constants.CREATE_CUSTOMER_REQUEST,
  });
  return Http.post('/customer/', data)
  .then(
    res => {
      dispatch({
        type: constants.CREATE_CUSTOMER_SUCCESS,
        payload: res,
      });
    },
    err => dispatch({
      type: constants.CREATE_CUSTOMER_FAILURE,
      payload: err,
    })
  );
};

export const editCustomer = (data, id) => dispatch => {
  dispatch({
    type: constants.EDIT_CUSTOMER_REQUEST,
  });
  return Http.put(`/customer/${id}/`, data)
  .then(
    res => {
      dispatch({
        type: constants.EDIT_CUSTOMER_SUCCESS,
        payload: res,
      });
    },
    err => dispatch({
      type: constants.EDIT_CUSTOMER_FAILURE,
      payload: err,
    })
  );
};


export const removeCustomer = (id) => dispatch => {
  dispatch({
    type: constants.REMOVE_CUSTOMER_REQUEST,
  });
  return Http.delete(`/customer/${id}/`)
  .then(
    res => {
      dispatch({
        type: constants.REMOVE_CUSTOMER_SUCCESS,
        payload: res,
      });
    },
    err => dispatch({
      type: constants.REMOVE_CUSTOMER_FAILURE,
      payload: err,
    })
  );
};

// ############################# Projects #############################
export const getAllProjects = (query) => dispatch => {
  dispatch({
    type: constants.GET_ALL_PROJECTS_REQUEST,
  });
  return Http.get(`/project/${getQuery(query)}`)
  .then(
    res => {
      dispatch({
        type: constants.GET_ALL_PROJECTS_SUCCESS,
        payload: res,
      });
    },
    err => dispatch({
      type: constants.GET_ALL_PROJECTS_FAILURE,
      payload: err,
    })
  );
};

export const createProjects = (data) => dispatch => {
  dispatch({
    type: constants.CREATE_PROJECT_REQUEST,
  });
  return Http.post('/project/', data)
  .then(
    res => {
      dispatch({
        type: constants.CREATE_PROJECT_SUCCESS,
        payload: res,
      });
    },
    err => dispatch({
      type: constants.CREATE_PROJECT_FAILURE,
      payload: err,
    })
  );
};

export const editProjects = (data, id) => dispatch => {
  dispatch({
    type: constants.EDIT_PROJECT_REQUEST,
  });
  return Http.put(`/project/${id}/`, data)
  .then(
    res => {
      dispatch({
        type: constants.EDIT_PROJECT_SUCCESS,
        payload: res,
      });
    },
    err => dispatch({
      type: constants.EDIT_PROJECT_FAILURE,
      payload: err,
    })
  );
};


export const removeProjects = (id) => dispatch => {
  dispatch({
    type: constants.REMOVE_PROJECT_REQUEST,
  });
  return Http.delete(`/project/${id}/`)
  .then(
    res => {
      dispatch({
        type: constants.REMOVE_PROJECT_SUCCESS,
        payload: res,
      });
    },
    err => dispatch({
      type: constants.REMOVE_PROJECT_FAILURE,
      payload: err,
    })
  );
};

// ############################# Bank accounts #############################
export const getAllBankAcc = (query) => dispatch => {
  dispatch({
    type: constants.GET_ALL_BANK_ACC_REQUEST,
  });
  return Http.get(`/bank-account/${getQuery(query)}`)
  .then(
    res => {
      dispatch({
        type: constants.GET_ALL_BANK_ACC_SUCCESS,
        payload: res,
      });
    },
    err => dispatch({
      type: constants.GET_ALL_BANK_ACC_FAILURE,
      payload: err,
    })
  );
};

export const getAvailableBankAcc = (query) => dispatch => {
  dispatch({
    type: constants.GET_AVAILABLE_BANK_ACC_REQUEST,
  });
  return Http.get(`/bank-account/${getQuery(query)}`)
  .then(
    res => {
      dispatch({
        type: constants.GET_AVAILABLE_BANK_ACC_SUCCESS,
        payload: res,
      });
    },
    err => dispatch({
      type: constants.GET_AVAILABLE_BANK_ACC_FAILURE,
      payload: err,
    })
  );
};

export const createBankAcc = (data) => dispatch => {
  dispatch({
    type: constants.CREATE_BANK_ACC_REQUEST,
  });
  return Http.post('/bank-account/', data)
  .then(
    res => {
      dispatch({
        type: constants.CREATE_BANK_ACC_SUCCESS,
        payload: res,
      });
    },
    err => dispatch({
      type: constants.CREATE_BANK_ACC_FAILURE,
      payload: err,
    })
  );
};

export const editBankAcc = (data, id) => dispatch => {
  dispatch({
    type: constants.EDIT_BANK_ACC_REQUEST,
  });
  return Http.put(`/bank-account/${id}/`, data)
  .then(
    res => {
      dispatch({
        type: constants.EDIT_BANK_ACC_SUCCESS,
        payload: res,
      });
    },
    err => dispatch({
      type: constants.EDIT_BANK_ACC_FAILURE,
      payload: err,
    })
  );
};


export const removeBankAcc = (id) => dispatch => {
  dispatch({
    type: constants.REMOVE_BANK_ACC_REQUEST,
  });
  return Http.delete(`/bank-account/${id}/`)
  .then(
    res => {
      dispatch({
        type: constants.REMOVE_BANK_ACC_SUCCESS,
        payload: res,
      });
    },
    err => dispatch({
      type: constants.REMOVE_BANK_ACC_FAILURE,
      payload: err,
    })
  );
};

// ############################# Contractors #############################
export const getAllContractors = (query) => dispatch => {
  dispatch({
    type: constants.GET_ALL_CONTRACTORS_REQUEST,
  });
  return Http.get(`/contractor/${getQuery(query)}`)
  .then(
    res => {
      dispatch({
        type: constants.GET_ALL_CONTRACTORS_SUCCESS,
        payload: res,
      });
    },
    err => dispatch({
      type: constants.GET_ALL_CONTRACTORS_FAILURE,
      payload: err,
    })
  );
};

export const createContractor = (data) => dispatch => {
  dispatch({
    type: constants.CREATE_CONTRACTORS_REQUEST,
  });
  return Http.post('/contractor/', data)
  .then(
    res => {
      dispatch({
        type: constants.CREATE_CONTRACTORS_SUCCESS,
        payload: res,
      });
    },
    err => dispatch({
      type: constants.CREATE_CONTRACTORS_FAILURE,
      payload: err,
    })
  );
};

export const editContractor = (data, id) => dispatch => {
  dispatch({
    type: constants.EDIT_CONTRACTORS_REQUEST,
  });
  return Http.put(`/contractor/${id}/`, data)
  .then(
    res => {
      dispatch({
        type: constants.EDIT_CONTRACTORS_SUCCESS,
        payload: res,
      });
    },
    err => dispatch({
      type: constants.EDIT_CONTRACTORS_FAILURE,
      payload: err,
    })
  );
};


export const removeContractor = (id) => dispatch => {
  dispatch({
    type: constants.REMOVE_CONTRACTORS_REQUEST,
  });
  return Http.delete(`/contractor/${id}/`)
  .then(
    res => {
      dispatch({
        type: constants.REMOVE_CONTRACTORS_SUCCESS,
        payload: res,
      });
    },
    err => dispatch({
      type: constants.REMOVE_CONTRACTORS_FAILURE,
      payload: err,
    })
  );
};

// ############################# Currency #############################
export const getAllCurrency = (query) => dispatch => {
  dispatch({
    type: constants.GET_ALL_CURRENCY_REQUEST,
  });
  return Http.get(`/currency/${getQuery(query)}`)
  .then(
    res => {
      dispatch({
        type: constants.GET_ALL_CURRENCY_SUCCESS,
        payload: res,
      });
    },
    err => dispatch({
      type: constants.GET_ALL_CURRENCY_FAILURE,
      payload: err,
    })
  );
};

export const createCurrency = (data) => dispatch => {
  dispatch({
    type: constants.CREATE_CURRENCY_REQUEST,
  });
  return Http.post('/currency/', data)
  .then(
    res => {
      dispatch({
        type: constants.CREATE_CURRENCY_SUCCESS,
        payload: res,
      });
    },
    err => dispatch({
      type: constants.CREATE_CURRENCY_FAILURE,
      payload: err,
    })
  );
};

export const editCurrency = (data, id) => dispatch => {
  dispatch({
    type: constants.EDIT_CURRENCY_REQUEST,
  });
  return Http.put(`/currency/${id}/`, data)
  .then(
    res => {
      dispatch({
        type: constants.EDIT_CURRENCY_SUCCESS,
        payload: res,
      });
    },
    err => dispatch({
      type: constants.EDIT_CURRENCY_FAILURE,
      payload: err,
    })
  );
};


export const removeCurrency = (id) => dispatch => {
  dispatch({
    type: constants.REMOVE_CURRENCY_REQUEST,
  });
  return Http.delete(`/currency/${id}/`)
  .then(
    res => {
      dispatch({
        type: constants.REMOVE_CURRENCY_SUCCESS,
        payload: res,
      });
    },
    err => dispatch({
      type: constants.REMOVE_CURRENCY_FAILURE,
      payload: err,
    })
  );
};

// ############################# Payment method #############################
export const getAllPayMethods = (query) => dispatch => {
  dispatch({
    type: constants.GET_ALL_PAY_METHOD_REQUEST,
  });
  return Http.get(`/payment-method/${getQuery(query)}`)
  .then(
    res => {
      dispatch({
        type: constants.GET_ALL_PAY_METHOD_SUCCESS,
        payload: res,
      });
    },
    err => dispatch({
      type: constants.GET_ALL_PAY_METHOD_FAILURE,
      payload: err,
    })
  );
};

export const createPayMethod = (data) => dispatch => {
  dispatch({
    type: constants.CREATE_PAY_METHOD_REQUEST,
  });
  return Http.post('/payment-method/', data)
  .then(
    res => {
      dispatch({
        type: constants.CREATE_PAY_METHOD_SUCCESS,
        payload: res,
      });
    },
    err => dispatch({
      type: constants.CREATE_PAY_METHOD_FAILURE,
      payload: err,
    })
  );
};

export const editPayMethod = (data, id) => dispatch => {
  dispatch({
    type: constants.EDIT_PAY_METHOD_REQUEST,
  });
  return Http.put(`/payment-method/${id}/`, data)
  .then(
    res => {
      dispatch({
        type: constants.EDIT_PAY_METHOD_SUCCESS,
        payload: res,
      });
    },
    err => dispatch({
      type: constants.EDIT_PAY_METHOD_FAILURE,
      payload: err,
    })
  );
};


export const removePayMethod = (id) => dispatch => {
  dispatch({
    type: constants.REMOVE_PAY_METHOD_REQUEST,
  });
  return Http.delete(`/payment-method/${id}/`)
  .then(
    res => {
      dispatch({
        type: constants.REMOVE_PAY_METHOD_SUCCESS,
        payload: res,
      });
    },
    err => dispatch({
      type: constants.REMOVE_PAY_METHOD_FAILURE,
      payload: err,
    })
  );
};
