import {
  connect,
} from 'react-redux';
import {
  withRouter,
} from 'react-router-dom';
import {
  setWidth,
  setIsMobile,
} from './module/actions';
import SizeWrap from './SizeWrap';
import {
 key,
} from './module/constants';

const mapDispatchToProps = {
  setWidth,
  setIsMobile,
};

const mapStateToProps = (state) => ({
  isMobile: state[key].isMobile,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SizeWrap));
