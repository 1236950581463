import React, {
  // Suspense,
  // lazy,
} from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';
// import PageLoader from '../ui_parts/PageLoader';
//TODO: fix react lazy in future
//now we have random bug with duplicate render components, witch added with lazy function
// const Dashboard = lazy(() => import('../dashboard'));
// const Main = lazy(() => import('../main'));
// const Reports = lazy(() => import('../reports'));
// const Management = lazy(() => import('../management'));
// const Profile = lazy(() => import('../profile'));
// const Bill = lazy(() => import('../main/bill'));
// const NotFound = lazy(() => import('../not_found'));

import Dashboard from '../dashboard';
import Main from '../main';
import Reports from '../reports';
import Management from '../management';
import History from '../history';
import Profile from '../profile';
import NotFound from '../not_found';

const LayoutRoutes = () => {
  return (
    /*<Suspense
          fallback={<PageLoader />}
        >
          <Switch>
            <Route
              exact
              path='/'
              component={() => <Dashboard />}
            />
            <Route
              exact
              path='/main'
              component={() => <Main />}
            />
            <Route
              exact
              path='/reports'
              component={() => <Reports />}
            />
            <Route
              path='/main/:id'
              component={() => <Bill />}
            />
            <Route
              path='/management'
              component={() => <Management />}
            />
            <Route
              exact
              path='/profile'
              component={() => <Profile />}
            />
            <Route
              component={() => <NotFound />}
              status={404}
            />
          </Switch>
        </Suspense>*/
    <Switch>
      <Route
        exact
        path='/'
        component={Dashboard}
      />
      <Route
        path='/main'
        component={Main}
      />
      <Route
        path='/reports'
        component={Reports}
      />
      <Route
        path='/management'
        component={Management}
      />
      <Route
        path='/history'
        component={History}
      />
      <Route
        exact
        path='/profile'
        component={Profile}
      />
      <Route
        component={NotFound}
        status={404}
      />
    </Switch>
  );
};
export default LayoutRoutes;
