import {
 connect,
} from 'react-redux';
import {
 withRouter,
} from 'react-router-dom';
import ListPanel from './ListPanel';
import {
  setHistoryFilters,
  getVisitingHistory,
} from '../module/actions';
import {
 key,
} from '../module/constants';

const mapStateToProps = (state) => ({
  list: state[key].historyList,
  filters: state[key].filters,
  count: state[key].count,
  isFetching: state[key].isFetching,
  isMobile: state.app.isMobile,
});

const mapDispatchToProps = {
  getVisitingHistory,
  setHistoryFilters,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListPanel));
