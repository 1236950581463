import React from 'react';

import PropTypes from 'prop-types';
/*eslint-disable*/

const propTypes = {
  name: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  paid: PropTypes.number.isRequired,
  invoicesLength: PropTypes.number.isRequired,
};

const CurrencyItem = ({
                        total,
                        paid,
                        name,
                        invoicesLength,
                        unpaid,
                        overpaid,
                      }) => {
  return (
    <div className="reports-statistic-wrapper">
      <div>
        <span>{`${invoicesLength} invoice${invoicesLength > 1 ? 's' : ''}`}</span>
      </div>
      <div>
        <span>{`Total: ${total.toFixed(2)}`}</span>
      </div>
      <div>
        <span>{`Paid: ${paid.toFixed(2)}`}</span>
      </div>
      <div>
        <span>{`Unpaid: ${unpaid.toFixed(2)}`}</span>
      </div>
      <div>
        <span>{`Overpaid: ${overpaid.toFixed(2)}`}</span>
      </div>
      <div>
            <span>
              Currency: {name}
            </span>
      </div>
    </div>
  );
};

CurrencyItem.propTypes = propTypes;
CurrencyItem.defaultProps = {};

export default CurrencyItem;
