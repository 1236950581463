import React, {
  Component,
} from 'react';
import sizeMe from 'react-sizeme';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import MobileDetect from 'mobile-detect';
import {
  mobileDeviceList,
} from './module/constants';
import App from './App';


const propTypes = {
  setWidth: PropTypes.func.isRequired,
  setIsMobile: PropTypes.func.isRequired,
  size: PropTypes.instanceOf(Object).isRequired,
  isMobile: PropTypes.bool.isRequired,
};

class SizeWrap extends Component {

  constructor(props) {
    super(props);
    this.changeWidth = debounce(width => props.setWidth(width), 200);
  }

  componentDidMount() {
    const {
      setWidth,
      size: {
        width,
      },
      setIsMobile,
      isMobile,
    } = this.props;
    setWidth(width);
    const md = new MobileDetect(get(window, 'navigator.userAgent', ''));
    const isUsingMobile = mobileDeviceList.includes(md.mobile());
    if (isUsingMobile !== isMobile) {
      setIsMobile();
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      size: {
        width,
      },
    } = this.props;
    if (nextProps.size.width !== width) {
      this.changeWidth(nextProps.size.width);
    }
  }

  render() {
    return (
      <div className='app'>
        <App />
      </div>
    );
  }
}

SizeWrap.propTypes = propTypes;
export default sizeMe()(SizeWrap);
