import {
 combineReducers,
} from 'redux';
import app from './app/module/reducers';
import invoices from './main/module/reducers';
import reports from './reports/module/reducers';
import profile from './profile/module/reducers';
import management from './management/module/reducers';
import history from './history/module/reducers';

const reducer = combineReducers({
  app,
  invoices,
  profile,
  management,
  reports,
  history,
});

export default reducer;
